/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router components
import { Navigate, Route, Routes } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 React themes
import themeLight from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

// Images
import { AuthProvider, useAuth } from "context/AuthContext";
import SignIn from "layouts/authentication/sign-in";
import AppLayout from "layouts/AppLayout";
import ProtectedRoute from "components/ProtectedRoute";
import { RequestProvider } from "context/RequestContext";

// third party library for querying and mutating data
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { NotificationProvider } from "context/NotificationContext";
import { useMemo } from "react";
import { ModalProvider } from "./context/ModalContext";
import NotificationDetail from "layouts/notification/NotificationDetail";
import useCanAccess from "./hooks/useCanAccess";
import Page403 from "./layouts/errors/Page403";
import Page404 from "./layouts/errors/Page404";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60, // 1 minute
      retryOnMount: false,
    },
  },
});
const getRoutes = (allRoutes, canOneOf, isAuthenticated) => {
  return allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }
    if (route.permissions) {
      if (canOneOf(route.permissions)) {
        if (route.route) {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
        return null;
      }
      // TODO : find can access route
      return (
        <Route
          exact
          path={route.route}
          element={<Navigate to="/403" replace={true} />}
          key={route.key}
        />
      );
    }

    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });
};

const HOCApp = (WrapperComponent) => {
  return (props) => {
    const [controller] = useMaterialUIController();
    const { layout, darkMode } = controller;
    const theme = useMemo(() => {
      if (darkMode) {
        return themeDark;
      }
      return themeLight;
    }, [darkMode]);
    return (
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ModalProvider>
            <RequestProvider>
              <NotificationProvider>
                <ThemeProvider theme={theme}>
                  <WrapperComponent {...props} layout={layout} />
                  <ReactQueryDevtools initialIsOpen={false} />
                </ThemeProvider>
              </NotificationProvider>
            </RequestProvider>
          </ModalProvider>
        </AuthProvider>
      </QueryClientProvider>
    );
  };
};

function App({ layout }) {
  const { canOneOf } = useCanAccess();
  const { user, isAuthenticated } = useAuth();
  const routesGenerated = useMemo(() => {
    // if (!user || !isAuthenticated) return null;
    return getRoutes(routes, canOneOf, isAuthenticated);
  }, [user, isAuthenticated]);
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <AppLayout layout={layout} />
          </ProtectedRoute>
        }
      >
        {/* DEFAULT NAVIGATE */}
        <Route exact path="/" element={<Navigate to="/notification" />} />
        <Route exact path="notification/:id" element={<NotificationDetail />} />

        {routesGenerated}
      </Route>
      <Route path="/authentication/sign-in" element={<SignIn />} />
      {/*  403*/}
      <Route path="/403" element={<Page403 />} />
      {/*  404 */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default HOCApp(App);
