import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import DeleteForm from "components/DeleteForm";
import { Chip } from "@mui/material";
import { bankActions, bankingTypeActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import { DisplayContent, getTextFromHtmlTag } from "../../libs/display-content";
import NestedList from "../../components/NestedList";
import { displayShortContent } from "../banking/bankingsData";
import ModalPreviewContent from "../../components/ModalPreviewContent";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

function getRows({ bankingType, filters }) {
  try {
    let rows = bankingType;
    // filter by name
    if (filters.name) {
      rows = rows.filter((row) => row.name.toLowerCase().includes(filters.name.toLowerCase()));
    }
    rows = rows.map((bankingType) => {
      const {
        name,
        icon,
        isDeleted,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
        departments,
        groups,
        note,
      } = bankingType || [];

      return {
        bankingType: <StyledNameComponent img={ASSET_URL + icon} name={name} />,
        departments: departments.map((department, index) => (
          <Chip
            key={`${department.name}-${index}`}
            label={department.name || "Department Name"}
            variant="outlined"
            sx={{ color: "text.main", m: [1, 0.5] }}
          />
        )),
        groups: groups.map((group, index) => (
          <Chip
            key={`${group.name}-${index}`}
            label={group.name || "Group Name"}
            variant="outlined"
            sx={{ color: "text.main", m: [1, 0.5] }}
          />
        )),
        note: <ModalPreviewContent content={note} />,

        isDeleted: (
          <MDBadge
            badgeContent={String(isDeleted)}
            color={isDeleted ? "error" : "success"}
            variant="gradient"
            size="sm"
          />
        ),
        createdTime: createdTime ? <Time time={createdTime} /> : null,
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={createdBy.fullName} />
        ) : null,
        updatedTime: updatedTime ? <Time time={updatedTime} /> : null,
        updatedBy: updatedBy ? (
          <StyledNameComponent img={ASSET_URL + updatedBy.avatar} name={updatedBy.fullName} />
        ) : null,
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={bankingTypeActions.updateBankingType}>
              <Modal>
                <UpdateForm bankingType={bankingType} />
              </Modal>
            </Can>
            <Can permissions={bankingTypeActions.updateBankingType}>
              <Modal action="delete">
                <DeleteForm context="bankingType" id={bankingType._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "kiểu giao dịch", accessor: "bankingType", width: "25%", align: "left" },
    { Header: "tổ", accessor: "groups", align: "center" },
    { Header: "bộ phận", accessor: "departments", align: "center" },
    { Header: "ghi chú", accessor: "note", align: "left" },

    { Header: "trạng thái xoá", accessor: "isDeleted", align: "center" },
    { Header: "tạo lúc", accessor: "createdTime", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },
    { Header: "cập nhật bởi", accessor: "updatedBy", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
