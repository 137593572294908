import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useNotificationId from "./useNotificationId";
import Avatar from "@mui/material/Avatar";
import MDTypography from "components/MDTypography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import { useMaterialUIController } from "context";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import MDButton from "components/MDButton";
import { DisplayContent } from "libs/display-content";
import useReadNotification from "./useReadNotification";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Divider } from "@mui/material";

function NotificationDetail() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DetailView />
    </DashboardLayout>
  );
}

function DetailView() {
  const { id, notificationDetail, isLoading } = useNotificationId();
  const [searchParams] = useSearchParams();
  const { readNoti } = useReadNotification();

  const [controller] = useMaterialUIController();
  const { darkMode, sidenavColor } = useMemo(() => controller, [controller]);
  const navigate = useNavigate();

  const { palette } = darkMode ? theme : themeDark;

  const { createdBy, title, content } = notificationDetail || [];

  useEffect(() => {
    if (searchParams.get("unread") === "false") return;
    readNoti(id);
  }, [readNoti, id]);

  if (isLoading) return <SpinnerFullPage />;

  return (
    <MDBox
      bgColor={palette.background.default}
      borderRadius="10px"
      color={palette.text.main}
      my={3}
      p={3}
    >
      <MDButton
        color={sidenavColor}
        size="medium"
        variant="contained"
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIcon />
      </MDButton>
      <MDBox sx={{ color: "inherit", display: "flex", alignItems: "center", gap: 1, p: 2, mt: 4 }}>
        <Avatar alt={createdBy?.fullName} src={createdBy?.avatar} />
        <span>{createdBy?.fullName}</span>
      </MDBox>
      <MDTypography variant="h2" sx={{ color: "inherit" }} px={2}>
        {title}
      </MDTypography>
      <Divider style={{ border: `1px solid ${darkMode ? "black" : "white"}` }} />
      <MDTypography px={2} pb={2} variant="body1" sx={{ bgColor: "inherit", color: "inherit" }}>
        {DisplayContent({ content })}
      </MDTypography>
    </MDBox>
  );
}

export default NotificationDetail;
