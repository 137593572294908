import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { API_URL, method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/users/userData";
import useCanAccess from "./useCanAccess";
import { userActions } from "../constants/actions";
export const userQueryKey = "user";

export const useUser = ({ getAll = false } = {}) => {
  const { makeRequest } = useRequest();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [status, setStatus] = useState(3); //0: deleted // 1: active, 2: locked // 3: all
  const [searchValue, setSearchValue] = useState("");
  const { can } = useCanAccess();

  // const [rows, setRows] = useState([]);
  let _transformPagination = {
    ...pagination,
    pageIndex: pagination.pageIndex + 1,
    fullName: searchValue,
    status: status,
  };
  const { data, isLoading, error } = useQuery({
    queryKey: [
      userQueryKey,
      _transformPagination.pageIndex + 1,
      _transformPagination.pageSize,
      searchValue,
      status,
      getAll,
    ],
    queryFn: async () => {
      if (getAll) {
        // TODO: get all user
        _transformPagination = {
          ...pagination,
          pageIndex: 1,
          pageSize: Infinity,
        };
      }
      if (status === 3) {
        //   remove status
        delete _transformPagination.status;
      }
      let request = {
        method: method.get,
        endpoint: `/${userQueryKey}/getPaging?${new URLSearchParams(
          _transformPagination
        ).toString()}`,
      };

      return await makeRequest(request);
    },
    enabled: can(userActions.getPagingUsers),
    select: (data) => {
      return data;
    },
    placeholderData: (previousData, previousQuery) => previousData,
  });

  const dataPagination = useMemo(() => {
    // filter status
    let user = data?.data;
    const rows = getRows({ user });

    const pagination = {
      pageIndex: data?.pageIndex - 1 <= 0 ? 0 : data?.pageIndex - 1,
      pageSize: data?.pageSize,
      totalPages: data?.totalPages,
    };
    return {
      rows,
      pagination,
    };
  }, [data]);
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handlePaginationChange = (pageIndex, pageSize) => {
    setPagination({ pageIndex, pageSize });
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  return {
    data,
    dataPagination,
    isLoading,
    error,
    handlePaginationChange,
    pagination,
    handleSearchChange,
    status,
    handleStatusChange,
  };
};
