import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MDBadge from "./MDBadge";

const STATUS = {
  user: [
    {
      content: "deleted",
      color: "error",
    },
    {
      content: "active",
      color: "success",
    },
    {
      content: "locked",
      color: "warning",
    },
  ],
  userFilter: [
    {
      content: "deleted",
      color: "error",
    },
    {
      content: "active",
      color: "success",
    },
    {
      content: "locked",
      color: "warning",
    },
    {
      content: "All Status",
      color: "info",
    },
  ],
  role: [
    {
      content: "false",
      color: "error",
    },
    {
      content: "true",
      color: "success",
    },
  ],
  request: [
    {
      content: "pending",
      color: "warning",
    },
    {
      content: "approved",
      color: "success",
    },
    {
      content: "reject",
      color: "error",
    },
  ],
};

export default function StatusSelect({ title, context, status, onChange = () => {}, ...rest }) {
  return (
    <div>
      <FormControl sx={{ marginTop: "20px", minWidth: 80, ...rest?.sx }}>
        {title && (
          <InputLabel id="status-select-label" sx={{ left: "0.5" }}>
            {title}
          </InputLabel>
        )}
        <Select
          labelId="status-select-label"
          id="status-select"
          value={Number(status)}
          onChange={onChange}
          label={title}
          sx={{ padding: "15px", ...rest?.selectSx }}
        >
          {STATUS[context].map((status, index) => (
            <MenuItem value={index} key={index}>
              <MDBadge
                badgeContent={status.content}
                color={status.color}
                variant="gradient"
                size="sm"
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
