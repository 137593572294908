import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useRequest } from "context/RequestContext";
import React, { useState } from "react";
import { useMaterialUIController } from "context";
import MultipleSelectChip from "components/MultipleSelectChip";
import { useQueryClient } from "@tanstack/react-query";
import { groupQueryKey } from "../../hooks/useGroup";
import { useDepartment } from "../../hooks/useDepartments";
import QuillInputField from "../../components/QuillInputField";

const uploadStyle = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
};

const context = "group";

function UpdateForm({ group, create = false }) {
  const queryClient = useQueryClient();

  const { name: groupName, departments: groupDepartments } = group || { name: "", departments: [] };

  const [name, setName] = useState(groupName);
  const [icon, setIcon] = useState("");
  const [note, setNote] = useState(group?.note || "");

  const [departmentSelected, setDepartmentSelected] = useState([
    ...groupDepartments.map((record) => record._id),
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const { updateRequest, createRequest } = useRequest();
  const { data: department } = useDepartment();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const handleDeparmentChange = (event) => {
    setDepartmentSelected(event);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const body = { name, icon, departments: departmentSelected, note };

      if (!create) {
        await updateRequest({ context, id: group._id, body });
        queryClient.invalidateQueries({
          queryKey: [groupQueryKey],
        });
      } else {
        await createRequest({ context, body });
        queryClient.invalidateQueries({
          queryKey: [groupQueryKey],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <MDBox component="form" onSubmit={handleSubmit} width={500}>
      <MDInput
        fullWidth
        id="name"
        label="Tổ"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <QuillInputField initialValue={note} label="ghi chú" onChange={(value) => setNote(value)} />

      <MDButton
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        color={sidenavColor}
        sx={{ marginTop: "20px" }}
      >
        Nhập Icon
        <MDInput
          type="file"
          sx={uploadStyle}
          onChange={(e) => {
            setIcon(e.target.files[0]);
          }}
        />
      </MDButton>
      <MultipleSelectChip
        data={department || []}
        dataSelected={departmentSelected}
        onChange={handleDeparmentChange}
      />
      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
