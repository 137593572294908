import { useRequest } from "context/RequestContext";
import { useState } from "react";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import QuillInputField from "../../components/QuillInputField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SelectRenderProp from "components/Select";
import MenuItem from "@mui/material/MenuItem";
import MDBadge from "components/MDBadge";

const inputStyle = {
  marginTop: "20px",
};

const context = "notification";

function UpdateForm({ notification, create = false }) {
  const [title, setTitle] = useState(notification?.title || "");
  const [content, setContent] = useState(notification?.content || "");
  const [priority, setPriotity] = useState(notification?.priority || 1);

  const { updateRequest, createRequest } = useRequest();
  const [{ sidenavColor }] = useMaterialUIController();
  const queryClient = useQueryClient();

  function handleChangeContent(value) {
    setContent(value);
  }

  function handleChangePriority(value) {
    setPriotity(value);
  }

  const { mutate } = useMutation({
    mutationFn: handleSubmit,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notification"],
      });
    },
    onError: (err) => {
      throw new Error(err);
    },
  });

  async function handleSubmit(e) {
    e.preventDefault();
    const body = { title, content, priority };

    if (!create) {
      await updateRequest({ context, id: notification._id, body });
    } else {
      await createRequest({ context, body });
    }
  }

  return (
    <MDBox component="form" onSubmit={mutate}>
      <MDInput
        fullWidth
        id="title"
        label="Tiêu Đề"
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        sx={inputStyle}
        required
      />
      <SelectRenderProp
        title="Độ Ưu Tiên"
        defaultValue={priority}
        data={[
          { value: 1, title: "Mức Độ 1", color: "error" },
          { value: 2, title: "Mức Độ 2", color: "info" },
          { value: 3, title: "Mức Độ 3", color: "success" },
        ]}
        render={(data) => {
          return (
            <MenuItem value={data.value}>
              <MDBadge badgeContent={data.title} color={data.color} variant="gradient" size="sm" />
            </MenuItem>
          );
        }}
        onChange={handleChangePriority}
        sx={{ mt: 3 }}
      />
      <QuillInputField initialValue={content} onChange={handleChangeContent} />
      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
