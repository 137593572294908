/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, useCallback, isValidElement, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes, { func } from "prop-types";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useRowSelect,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import Modal from "components/Modal";
import { debounce } from "lodash/function";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import Spinner from "../../../components/Spinner/Spinner";

import MDButton from "../../../components/MDButton";

import { ExportModal } from "./ExportModal";
import LinearProgress from "@mui/material/LinearProgress";
import { useAuth } from "../../../context/AuthContext";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MDBadge from "../../../components/MDBadge";
import { useSearchParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

function ServerPaginateDataTable({
  entriesPerPage,
  canSearch,
  canCreate = false,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  pageCount,
  manualPagination = false,
  onPaginationChange,
  pageIndex,
  pageSize,
  onSearchChange,
  extraSearch: ExtraSearch,
  canExport = false,
  refreshButton = false,
  advancedSearch = null,
  compact = false,
  importModal = null,
  totalItems = undefined,
  rowSelection = undefined,
}) {
  const isFetching = useIsFetching({
    fetchStatus: "fetching",
  });
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryClient = useQueryClient();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [isDeleted, setIsDeleted] = useState(() => searchParams.get("isDeletedRecords") === "true");
  const tableContainerRef = useRef(null);
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;

  /**
   * ["5", "10", "15", "20", "25"] => og values
   */
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "20", "50", "100"];

  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, pageSize },
      pageCount,
      manualPagination,
      getRowId: (row, index) => {
        return row.id || index?.toString();
      },
      autoResetSelectedRows: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (rowSelection) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => {
              return <Checkbox {...getToggleAllRowsSelectedProps()} />;
            },
            Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ]);
      }
    }
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex: tablePageIndex, pageSize: tablePageSize, selectedRowIds },
    toggleAllRowsSelected,
  } = tableInstance;
  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  useEffect(() => {
    // if (tablePageIndex !== 0 && !manualPagination) {
    // }
    // scroll to top of the table when the page index changes
    tableContainerRef.current?.scrollTo(0, 0);
    if (tablePageIndex !== pageIndex) {
      onPaginationChange(tablePageIndex, tablePageSize);
    }
    if (tablePageSize !== pageSize) {
      onPaginationChange(tablePageIndex, tablePageSize);
    }
    // reset the selected row ids when the page index changes
    // setSelectedRowIds({});
    toggleAllRowsSelected(false);
  }, [tablePageIndex, tablePageSize]);

  useEffect(() => {
    toggleAllRowsSelected(false);
  }, [data]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={tablePageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState("");

  // Debounce the search operation
  // const debouncedSearch = useCallback(
  //   debounce((searchValue) => {
  //     onSearchChange && onSearchChange(searchValue);
  //     // Place your search logic here
  //   }, 400),
  //   [] // Dependencies array. Empty means the debounced function is created only once
  // );
  // // Effect for search input changes
  // useEffect(() => {
  //   if (canSearch) {
  //     debouncedSearch(search);
  //   }
  //
  //   // Cleanup function
  //   return () => {
  //     debouncedSearch.cancel();
  //   };
  // }, [search, debouncedSearch]);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart =
    tablePageIndex === 0 ? tablePageIndex + 1 : tablePageIndex * tablePageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (tablePageIndex === 0) {
    entriesEnd = tablePageSize;
  } else if (tablePageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = tablePageSize * (tablePageIndex + 1);
  }
  const renderExport = () => {
    if (!canExport) return null;

    if (isValidElement(canExport)) {
      return canExport;
    } else if (typeof canExport === "string") {
      return <ExportModal context={canExport} />;
    }
  };
  const handleRefresh = () => {
    queryClient.invalidateQueries({
      type: "active",
    });
  };
  const selectedRows = Object.keys(selectedRowIds).map((el) => {
    return el;
  });
  return (
    <>
      {entriesPerPage || canSearch || canCreate || ExtraSearch ? (
        <>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            {entriesPerPage && (
              <MDBox width="50%" display="flex" alignItems="center" gap={0.5} flexShrink={0}>
                <Autocomplete
                  disableClearable
                  value={tablePageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                {ExtraSearch && ExtraSearch}
                {canSearch && (
                  <>
                    <MDInput
                      placeholder="Tìm Kiếm..."
                      value={search}
                      size="small"
                      fullWidth
                      onChange={({ currentTarget }) => {
                        setSearch(currentTarget.value);
                      }}
                      onKeyPress={({ key }) => {
                        if (key === "Enter") {
                          onSearchChange && onSearchChange(search);
                        }
                      }}
                    />
                    <MDButton
                      sx={{ flexShrink: 0 }}
                      onClick={() => {
                        onSearchChange && onSearchChange(search);
                      }}
                    >
                      Tìm Kiếm
                    </MDButton>
                  </>
                )}
                {!!advancedSearch && (
                  <MDButton
                    sx={{ flexShrink: 0 }}
                    onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                  >
                    <Icon>filter_alt</Icon>
                  </MDButton>
                )}
              </MDBox>
            )}
            <MDBox ml="auto" sx={{ display: "flex", gap: 0.5 }}>
              {/*isDeleted Status select*/}
              {user?.role?.name?.toLowerCase() === "superadmin" && (
                <FormControl>
                  <InputLabel id="demo-simple-select-label" size="small">
                    Trạng Thái xóa
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={isDeleted}
                    sx={{
                      height: "100%",
                      width: "80px",
                    }}
                    onChange={(event) => {
                      setIsDeleted(event?.target?.value);
                      setSearchParams({ isDeletedRecords: event?.target?.value });
                    }}
                  >
                    <MenuItem value={false}>
                      <MDBadge
                        badgeContent="False"
                        color={"success"}
                        variant="gradient"
                        size="sm"
                      />
                    </MenuItem>
                    <MenuItem value={true}>
                      <MDBadge badgeContent="True" color={"error"} variant="gradient" size="sm" />
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              {canCreate && <Modal action="create">{canCreate}</Modal>}
              {importModal && <Modal action="import">{importModal}</Modal>}
              {/*Refresh button*/}
              {refreshButton && (
                <MDButton
                  sx={{
                    flexShrink: 0,
                  }}
                  onClick={handleRefresh}
                  disabled={isFetching > 0}
                >
                  <Icon>refresh</Icon>
                  Tải Lại
                </MDButton>
              )}
              {/* EXPORT */}
              {renderExport()}
            </MDBox>
          </MDBox>
          {showAdvancedSearch && (
            <MDBox
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              p={3}
              gap="20px"
              width="100%"
              border="1px solid"
              flexWrap="wrap"
            >
              {advancedSearch}
            </MDBox>
          )}
        </>
      ) : null}
      {/*Row selected*/}
      {rowSelection && selectedRows?.length > 0 && (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDTypography variant="button" color="secondary" fontWeight="regular">
            Đã chọn {selectedRows?.length} kết quả
          </MDTypography>
          {/*Custom action with render props*/}

          {rowSelection?.render && (
            <MDBox display="flex" gap={2}>
              {rowSelection.render(selectedRows)}
            </MDBox>
          )}
        </MDBox>
      )}
      <TableContainer sx={{ boxShadow: "none", height: "75vh" }} ref={tableContainerRef}>
        {/*LOADING SPINNER*/}
        {isFetching > 0 && <LinearProgress />}
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => {
                  return (
                    <DataTableHeadCell
                      key={idx}
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                      compact={compact}
                      sticky={column?.id === "action" || column.fixed}
                      selection={column?.id === "selection"}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  );
                })}
              </TableRow>
            ))}
          </MDBox>

          <TableBody
            {...getTableBodyProps()}
            sx={{
              position: "relative",
            }}
          >
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow key={key} {...row.getRowProps()}>
                  {row.cells.map((cell, idx) => (
                    <DataTableBodyCell
                      key={idx}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                      compact={compact}
                      sticky={cell?.column?.id === "action" || cell.column.fixed}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Hiển thị {entriesStart} đến {entriesEnd} của {totalItems || rows.length} kết quả
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[tablePageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </>
  );
}

// Setting default values for the props of DataTable
ServerPaginateDataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
ServerPaginateDataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default ServerPaginateDataTable;
