import { useAuth } from "context/AuthContext";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(
    function () {
      if (!isAuthenticated) {
        navigate("/authentication/sign-in");
      }
    },
    [isAuthenticated, navigate]
  );

  return isAuthenticated ? children : <Navigate to={"/authentication/sign-in"} />;
}

export default ProtectedRoute;
