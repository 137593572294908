import React, { useState } from "react";
import { Select, MenuItem, ListItemIcon } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DEFAULT_SORT } from "../../../utils/constants";

const DEFAULT_SORT_OPTIONS = [
  { value: "-createdTime", label: "Thời gian tạo giảm dần", Icon: ArrowDownwardIcon },
  { value: "createdTime", label: "Thời gian tạo tăng dần", Icon: ArrowUpwardIcon },
  { value: "-updatedTime", label: "Thời gian cập nhật giảm dần", Icon: ArrowDownwardIcon },
  { value: "updatedTime", label: "Thời gian cập nhật tăng dần", Icon: ArrowUpwardIcon },
];
const SortButton = ({ sortOptions = DEFAULT_SORT_OPTIONS }) => {
  const [searchParams] = useSearchParams();
  const [sortOption, setSortOption] = useState(searchParams.get("sort") || DEFAULT_SORT);
  const navigate = useNavigate();

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    navigate(`?sort=${event.target.value}`);
  };

  return (
    <Select
      sx={{ padding: "8px" }}
      value={sortOption}
      onChange={handleSortChange}
      renderValue={() => "Sắp xếp"}
    >
      {sortOptions.map(({ value, label, Icon }, index) => (
        <MenuItem value={value} key={index}>
          <ListItemIcon sx={{ color: "text.main" }}>
            <Icon fontSize="small" />
          </ListItemIcon>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SortButton;
