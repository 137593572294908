export const actionActions = {
  updateAction: "updateAction",
  getAllActions: "getAllActions",
};

export const adsAccountActions = {
  getAdsAccount: "getAdsAccount",
  getPagingAdsAccount: "getPagingAdsAccount",
  insertAdsAccount: "insertAdsAccount",
  updateAdsAccount: "updateAdsAccount",
  deleteAdsAccount: "deleteAdsAccount",
};

export const requestActions = {
  getRequest: "getRequest",
  getPagingRequest: "getPagingRequest",
  insertRequest: "insertRequest",
  updateRequest: "updateRequest",
  deleteRequest: "deleteRequest",
};

export const actionGroupActions = {
  inserActionGroup: "inserActionGroup",
  updateActionGroup: "updateActionGroup",
  deleteActionGroup: "deleteActionGroup",
  getPagingActionGroups: "getPagingActionGroups",
  getAllActionGroups: "getAllActionGroups",
  getActionGroupById: "getActionGroupById",
};

export const roleActions = {
  insertRole: "insertRole",
  updateRole: "updateRole",
  deleteRole: "deleteRole",
  getPagingRoles: "getPagingRoles",
  getRoleById: "getRoleById",
  getAllRoles: "getAllRoles",
};

export const userActions = {
  insertUser: "insertUser",
  getPagingUsers: "getPagingUsers",
  getUserById: "getUserById",
  deleteUser: "deleteUser",
  updateUser: "updateUser",
};

export const uploadActions = {
  uploadImage: "uploadImage",
  uploadVideo: "uploadVideo",
  uploadDocument: "uploadDocument",
  deleteImage: "deleteImage",
  deleteVideo: "deleteVideo",
  deleteDocument: "deleteDocument",
};

export const departmentActions = {
  insertDepartment: "insertDepartment",
  updateDepartment: "updateDepartment",
  deleteDepartment: "deleteDepartment",
  getPagingDepartments: "getPagingDepartments",
  getDepartmentById: "getDepartmentById",
  getAllDepartments: "getAllDepartments",
};

export const groupActions = {
  insertGroup: "insertGroup",
  updateGroup: "updateGroup",
  deleteGroup: "deleteGroup",
  getPagingGroups: "getPagingGroups",
  getGroupById: "getGroupById",
  getAllGroups: "getAllGroups",
};

export const memberActions = {
  insertMember: "insertMember",
  updateMember: "updateMember",
  deleteMember: "deleteMember",
  getPagingMembers: "getPagingMembers",
  getMemberById: "getMemberById",
  getAllMembers: "getAllMembers",
};

export const punishmentActions = {
  insertPunishment: "insertPunishment",
  updatePunishment: "updatePunishment",
  deletePunishment: "deletePunishment",
  getPagingPunishments: "getPagingPunishments",
  getPunishmentById: "getPunishmentById",
  getAllPunishments: "getAllPunishments",
};

export const cardCategoryActions = {
  insertCardCategory: "insertCardCategory",
  updateCardCategory: "updateCardCategory",
  deleteCardCategory: "deleteCardCategory",
  getPagingCardCategories: "getPagingCardCategories",
  getCardCategoryById: "getCardCategoryById",
  getAllCardCategories: "getAllCardCategories",
};

export const bankActions = {
  insertBank: "insertBank",
  updateBank: "updateBank",
  deleteBank: "deleteBank",
  getPagingBanks: "getPagingBanks",
  getBankById: "getBankById",
  getAllBanks: "getAllBanks",
};

export const cardActions = {
  insertCard: "insertCard",
  updateCard: "updateCard",
  deleteCard: "deleteCard",
  getPagingCards: "getPagingCards",
  getCardById: "getCardById",
  getAllCards: "getAllCards",
};

export const bankingTypeActions = {
  insertBankingType: "insertBankingType",
  updateBankingType: "updateBankingType",
  deleteBankingType: "deleteBankingType",
  getPagingBankingTypes: "getPagingBankingTypes",
  getBankingTypeById: "getBankingTypeById",
  getAllBankingTypes: "getAllBankingTypes",
};

export const bankingActions = {
  insertBanking: "insertBanking",
  inserManyBankings: "inserManyBankings",
  updateBanking: "updateBanking",
  deleteBanking: "deleteBanking",
  getPagingBankings: "getPagingBankings",
  getBankingById: "getBankingById",
  getAllBankings: "getAllBankings",
  bankingStatistics: "bankingStatistics",
};

export const notificationActions = {
  insertNotification: "insertNotification",
  updateNotification: "updateNotification",
  readNotification: "readNotification",
  deleteNotification: "deleteNotification",
  getPagingNotifications: "getPagingNotifications",
  getNotificationById: "getNotificationById",
  getAllNotifications: "getAllNotifications",
  getNotificationsByToken: "getNotificationsByToken",
};

export const simActions = {
  insertSim: "insertSim",
  updateSim: "updateSim",
  deleteSim: "deleteSim",
  getPagingSims: "getPagingSims",
  getSimById: "getSimById",
  getAllSims: "getAllSims",
};

export const cardCheckActions = {
  insertCardCheck: "insertCardCheck",
  updateCardCheck: "updateCardCheck",
  deleteCardCheck: "deleteCardCheck",
  getPagingCardChecks: "getPagingCardChecks",
  getCardCheckById: "getCardCheckById",
  getAllCardChecks: "getAllCardChecks",
};
