import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import { useState } from "react";
import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import { useRequest } from "../../context/RequestContext";
import { bankingQueryKey } from "../../hooks/useBanking";
import { useQueryClient } from "@tanstack/react-query";
import { bankingStatusOptions } from "../../utils/constants";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.default",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  paddingTop: "40px",
  paddingBottom: "55px",
};
const context = "banking";

export default function UpdateStatusModal({ item }) {
  const [open, setOpen] = useState(false);
  const { isLoading, updateRequest } = useRequest();
  const queryClient = useQueryClient();
  const [status, setStatus] = useState(
    bankingStatusOptions.find((option) => option.value === item?.status)
  );
  const option = bankingStatusOptions.find((option) => option.value === item?.status);

  const handleOpen = (e) => {
    e.stopPropagation();
    // // check status
    // if (status === 1) {
    //   return;
    // }
    setOpen(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleUpdateStatus = async () => {
    const body = {
      status: status?.value,
    };
    await updateRequest({ context, id: item._id, body });
    await queryClient.invalidateQueries([bankingQueryKey]);
    setOpen(false);
  };

  return (
    <div>
      <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
        <MDBadge
          badgeContent={option?.label}
          color={option?.color}
          variant="gradient"
          size="sm"
          onDoubleClick={handleOpen}
          sx={{
            cursor: "pointer",
          }}
        />
      </MDBox>
      <Modal
        closeAfterTransition={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <MDBox sx={style}>
          <Autocomplete
            renderInput={(params) => <MDInput {...params} label="Trạng thái" />}
            options={bankingStatusOptions}
            value={status}
            onChange={(event, newValue) => {
              setStatus(newValue);
            }}
          />
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <MDButton
              variant="contained"
              color="success"
              size="small"
              onClick={handleUpdateStatus}
              disabled={isLoading}
            >
              <Icon>done</Icon>&nbsp;{isLoading ? "Đang Cập nhật..." : "Cập nhật"}
            </MDButton>
          </MDBox>
          <MDButton
            variant="contained"
            color="error"
            iconOnly
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              top: 10,
              right: 5,
            }}
          >
            <Icon>clear</Icon>
          </MDButton>
        </MDBox>
      </Modal>
    </div>
  );
}
