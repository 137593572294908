import React from "react";
import { useGroup } from "../../hooks/useGroup";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import useFuse from "../../hooks/useFuse";
import { autocompleteStyles } from "../../assets/styles/autocomplete";

const MemberSearch = ({ onChange, members }) => {
  const { data, isLoading, error } = useGroup();

  const options = {
    keys: ["group.name", "name"],
    includeMatches: true,
  };
  const { result, search, reset } = useFuse({
    data: members,
    options,
  });
  const handleChange = (e, type) => {
    const value = e?._id;
    if (value) {
      onChange && onChange([value], type);
    } else {
      onChange && onChange([], type);
    }
  };
  if (isLoading) return "Loading...";

  if (error) return null;

  return (
    <>
      <Autocomplete
        disablePortal
        options={data}
        getOptionLabel={(option) => option?.name}
        onChange={(e, newValue) => {
          handleChange(newValue, "group");
        }}
        sx={{
          // ...autocompleteStyles,
          width: 200,
          flexShrink: 0,
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder="Tổ"
            size="small"
            fullWidth
            sx={{ paddingTop: "8px", paddingBottom: "6px", transform: "translateY(-2px)" }}
          />
        )}
      />
      <Autocomplete
        disablePortal
        options={members || []}
        sx={{ width: 400, flexShrink: 0 }}
        getOptionLabel={(option) => `${option?.name} - ${option?.group?.name}`}
        filterOptions={(options) => {
          return result;
        }}
        onChange={(e, newValue) => {
          handleChange(newValue, "member");
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder="Tìm kiếm(Tên người dùng, Tên Tổ)"
            size="small"
            fullWidth
            sx={{ paddingTop: "8px", paddingBottom: "6px", transform: "translateY(-2px)" }}
            onBlur={() => {
              reset();
            }}
            onChange={(e) => {
              search(e.target.value);
            }}
          />
        )}
      />
    </>
  );
};

export default MemberSearch;
