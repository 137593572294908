import { getColumns } from "./groupsData";
import TableLayout from "layouts/TableLayout";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { groupQueryKey, useGroup } from "../../hooks/useGroup";

import GroupSearch from "./GroupSearch";
import { Can } from "../../hooks/useCanAccess";
import { groupActions } from "../../constants/actions";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_SORT } from "../../utils/constants";

function GroupsTable() {
  const [searchParams] = useSearchParams();

  const { rows, isLoading, handleSelectDepartment, handleSelectGroup, data } = useGroup({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
    sort: searchParams.get("sort") || DEFAULT_SORT,
  });
  const columns = getColumns();
  const handleChange = (value, type) => {
    if (type === "department") {
      handleSelectDepartment(value);
    }
    if (type === "group") {
      handleSelectGroup(value);
    }
  };

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="Tổ"
      canSearch={false}
      // onSearch={handleSearchChange}
      canCreate={
        <Can permissions={[groupActions.insertGroup]} fallback={"Bạn không có quyền thêm tổ "}>
          <UpdateForm create={true} />
        </Can>
      }
      extraSearch={<GroupSearch onChange={handleChange} groups={data} />}
      canExport={groupQueryKey}
      refreshButton={true}
      compact={true}
    />
  );
}

export default GroupsTable;
