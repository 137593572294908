import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const authToken = localStorage.getItem("authToken");

const axiosInstance = axios.create({
  baseURL: API_URL, // Replace with your API base URL
  timeout: 60000, // Request timeout
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    console.log("Request error: " + error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data ? response.data : response;
  },
  (error) => {
    console.log("Request error: " + error);
    return Promise.reject(error);
  }
);

class APIBase {
  get(url, config) {
    return axiosInstance.get(url, { ...config });
  }

  post(url, data, config) {
    return axiosInstance.post(url, data, { ...config });
  }

  put(url, data, config) {
    return axiosInstance.put(url, data, { ...config });
  }

  delete(url, config) {
    return axiosInstance.delete(url, { ...config });
  }
}

export default APIBase;
