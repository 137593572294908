import { createContext, useCallback, useContext, useMemo, useState } from "react";

const modalContext = createContext(undefined);

export const ModalProvider = ({ children }) => {
  const [openObserver, setOpenObserver] = useState(1);

  const emitClose = useCallback(() => {
    setOpenObserver(openObserver + 1);

    //   auto reset
    setTimeout(() => {
      reset();
    }, 100);
  }, []);
  const reset = () => {
    setOpenObserver(1);
  };

  const value = useMemo(() => ({ openObserver, emitClose }), [openObserver]);
  return <modalContext.Provider value={{ value }}>{children}</modalContext.Provider>;
};

export const useModal = () => {
  const context = useContext(modalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context.value;
};
