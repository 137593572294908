import { useRequest } from "context/RequestContext";
import React, { useState } from "react";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useQueryClient } from "@tanstack/react-query";
import { useGroup } from "../../hooks/useGroup";
import { useDepartment } from "../../hooks/useDepartments";
import DatePicker from "../../components/DatePicker";
import MultipleSelectChip from "../../components/MultipleSelectChip";
import Autocomplete from "@mui/material/Autocomplete";
import { useCard } from "../../hooks/useCard";
import { NumericFormat } from "react-number-format";
import { cardCheckQueryKey } from "../../hooks/useCardCheck";
import QuillInputField from "../../components/QuillInputField";
import { CardCheckShift } from "../../constants/cardCheckShift";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const inputStyle = {
  marginTop: "20px",
};

const context = "cardCheck";

function UpdateForm({ cardCheck, create = false }) {
  const [name, setName] = useState(cardCheck?.name || "");
  const [departments, setDepartments] = useState(() => {
    const department = cardCheck?.departments || [];
    return department.map((item) => item._id);
  });
  const [availableBalance, setAvailableBalance] = useState(
    Number(cardCheck?.availableBalance) || 0
  );
  const [actualBalance, setActualBalance] = useState(Number(cardCheck?.actualBalance) || 0);
  const [card, setCard] = useState(cardCheck?.card?._id || "");
  const [groups, setGroups] = useState(() => {
    const group = cardCheck?.groups || [];
    return group.map((item) => item._id);
  });
  const [checkingTime, setCheckingTime] = useState(() => {
    return new Date(cardCheck?.checkingTime || new Date());
  });
  const [note, setNote] = useState(cardCheck?.note || "");
  const [shift, setShift] = useState(cardCheck?.shift || CardCheckShift.startShift);
  const [isLoading, setIsLoading] = useState(false);

  const { updateRequest, createRequest } = useRequest();

  const { data: groupsData, isLoading: isLoadingGroups } = useGroup();
  const { data: departmentsData, isLoading: isLoadingDepartments } = useDepartment();
  const { data: cardData, isLoading: isLoadingCard } = useCard();

  const queryClient = useQueryClient();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  function handleChangeContent(value) {
    setNote(value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const body = {
        name,
        card,
        groups,
        departments,
        note,
        checkingTime,
        actualBalance,
        availableBalance,
        shift,
      };

      if (!create) {
        await updateRequest({ context, id: cardCheck._id, body });
        await queryClient.invalidateQueries({
          queryKey: [cardCheckQueryKey],
          type: "active",
        });
      } else {
        await createRequest({ context, body });
        await queryClient.invalidateQueries({
          queryKey: [cardCheckQueryKey],
          type: "active",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoadingGroups || isLoadingDepartments || isLoadingCard) return <div>Loading...</div>;

  return (
    <MDBox component="form" width={600} onSubmit={handleSubmit}>
      <MDInput
        fullWidth
        id="name"
        label="Tên"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={inputStyle}
        required
      />
      {!isLoadingCard && (
        <Autocomplete
          required
          disablePortal
          options={cardData}
          defaultValue={cardData.find((item) => item._id === card)}
          sx={{
            ...inputStyle,
            width: "100%",
            "& .MuiOutlinedInput-root": {
              paddingTop: "4.5px",
              paddingBottom: "4.5px",
            },
          }}
          renderInput={(params) => (
            <MDInput {...params} variant="outlined" label="Thẻ Chuyển" required />
          )}
          getOptionLabel={(option) => {
            return `${option?.account} - ${option?.accountNumber || ""}`;
          }}
          onChange={(e, newValue) => {
            setCard(newValue?._id);
          }}
        />
      )}
      {/* Đầu ca, cuối ca  */}
      <FormControl sx={{ marginTop: "20px", width: "100%" }} required={true}>
        <InputLabel id="data-select-label" sx={{ left: "0.5" }}>
          Ca
        </InputLabel>
        <Select
          value={shift}
          onChange={(e) => {
            setShift(e.target.value);
          }}
          label="Ca"
          sx={{ padding: "15px" }}
        >
          <MenuItem key={CardCheckShift.startShift} value={CardCheckShift.startShift}>
            Đầu ca
          </MenuItem>
          <MenuItem key={CardCheckShift.endShift} value={CardCheckShift.endShift}>
            Cuối ca
          </MenuItem>
        </Select>
      </FormControl>
      <NumericFormat
        fullWidth
        id="availableBalance"
        label="Số dư khả dụng"
        variant="outlined"
        value={availableBalance}
        thousandSeparator
        customInput={MDInput}
        sx={inputStyle}
        onChange={(e) => {
          const value = e.target.value.replaceAll(",", "");
          setAvailableBalance(value);
        }}
        required={true}
      />
      <NumericFormat
        fullWidth
        id="actualBalance"
        label="Số dư thực tế"
        variant="outlined"
        value={actualBalance}
        thousandSeparator
        customInput={MDInput}
        sx={inputStyle}
        onChange={(e) => {
          const value = e.target.value.replaceAll(",", "");
          setActualBalance(value);
        }}
        required={true}
      />

      <DatePicker
        label="Thời gian"
        selected={checkingTime}
        onChange={(date) => {
          setCheckingTime(date?.toISOString());
        }}
      />
      <MultipleSelectChip
        title="Bộ Phận"
        data={departmentsData || []}
        dataSelected={departments}
        onChange={(value) => {
          setDepartments(value);
        }}
        required
      />
      <MultipleSelectChip
        title="Tổ"
        data={groupsData || []}
        dataSelected={groups}
        onChange={(value) => {
          setGroups(value);
        }}
        required
      />

      <QuillInputField label="Ghi chú" initialValue={note} onChange={handleChangeContent} />

      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
