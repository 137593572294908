import { getColumns } from "./rolesData";
import TableLayout from "layouts/TableLayout";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { roleQueryKey, useRole } from "../../hooks/useRole";
import { roleActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";

function RolesTable() {
  const { rows, isLoading, error, handleSearchChange } = useRole();

  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="Vai Trò"
      onSearch={handleSearchChange}
      canCreate={
        <Can permissions={roleActions.insertRole} fallback={"Bạn không có quyền thêm vai trò"}>
          <UpdateForm create={true} />
        </Can>
      }
      canExport={roleQueryKey}
      refreshButton={true}
      compact={true}
      sortButton={false}
    />
  );
}

export default RolesTable;
