import { getColumns } from "./bankingsData";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { bankingQueryKey, useBanking } from "../../hooks/useBanking";
import AdvancedSearch from "./AdvancedSearch";
import BankingsStastics from "./BankingsStastics";
import "./Banking.css";
import { Can } from "../../hooks/useCanAccess";
import { bankingActions, bankingTypeActions, cardActions } from "../../constants/actions";
import exampleFile from "./banking-example.csv";
import ImportModal from "../../components/ImportModal";
import BankingExportModal, { handleDownload } from "./BankingExportModal";
import MDButton from "../../components/MDButton";
import { separateThousandsAndRound } from "../../utils/helpers";
import { useSearchParams } from "react-router-dom";
import BankingDateSort from "./BankingDateSort";
import { useRequest } from "../../context/RequestContext";
import React, { useState } from "react";
import Select from "@mui/material/Select";
import TableServerPaginateLayout from "../TableServerPaginateLayout";

import { Dialog, DialogActions, MenuItem } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";
import { bankingStatusOptions } from "../../utils/constants";
import { useQueryClient } from "@tanstack/react-query";

const BankingImportTranslate = {
  fromCard: "Thẻ chuyển",
  toCard: "Thẻ nhận",
  amountOfMoney: "Số tiền",
  bankingType: "Loại giao dịch",
  bankingTime: "Thời gian giao dịch",
  note: "Ghi chú",
};

function UpdateStatusMultiple({ ids }) {
  const [open, setOpen] = useState(false);
  const { updateMultipleStatuses } = useRequest();
  const [status, setStatus] = useState(null);
  const queryClient = useQueryClient();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <MenuItem
        sx={{
          mt: 2,
        }}
        onClick={handleClickOpen}
      >
        Cập nhật trạng thái giao dịch
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            try {
              const context = "banking";
              const body = {
                ids,
                status: status?.value,
              };
              await updateMultipleStatuses({ context, body });
              queryClient.invalidateQueries({
                queryKey: [bankingQueryKey],
                type: "all",
              });
              handleClose();
            } catch (error) {
              console.log(error);
            }
          },
        }}
      >
        <DialogContent>
          <Autocomplete
            required
            renderInput={(params) => (
              <MDInput
                {...params}
                label="Trạng thái"
                inputProps={{
                  ...params.inputProps,
                  required: true,
                }}
              />
            )}
            options={bankingStatusOptions}
            value={status}
            onChange={(event, newValue) => {
              setStatus(newValue);
            }}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Hủy</MDButton>
          <MDButton type="submit" color="primary">
            Cập nhật
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

function BankingsTable() {
  const [searchParams] = useSearchParams();
  const [isExporting, setIsExporting] = useState(false);

  const { dispatch } = useRequest();
  const {
    data,
    rows,
    isLoading,
    handleSearchChange,
    handleAdvancedSearchChange,
    setSortOption,
    handlePaginationChange,
    pagination,
    advancedSearch,
    isHasAdvancedSearch,
  } = useBanking({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
  });
  const handleExport = async (selectedRowKeys) => {
    setIsExporting(true);
    const originData = selectedRowKeys.map((key) => {
      return rows?.find((row) => row?.originalData?._id === key)?.originalData;
    });
    await handleDownload(undefined, dispatch, originData);
    setIsExporting(false);
  };

  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;
  return (
    <TableServerPaginateLayout
      columns={columns}
      rows={rows}
      onSearchChange={handleSearchChange}
      title="Giao Dịch"
      totalItems={data?.pagination?.totalItems}
      pageCount={data?.pagination?.totalPages}
      pageIndex={pagination?.pageIndex}
      pageSize={pagination?.pageSize}
      canCreate={
        <Can
          permissions={[
            bankingActions.insertBanking,
            cardActions.getAllCards,
            bankingTypeActions.getAllBankingTypes,
          ]}
          fallback={"Bạn không có quyền thêm giao dịch hoặc xem danh sách thẻ hoặc loại giao dịch"}
        >
          <UpdateForm create={true} />
        </Can>
      }
      canExport={
        <BankingExportModal
          isHasAdvancedSearch={isHasAdvancedSearch}
          advancedSearch={advancedSearch}
        />
      }
      extraSearch={<BankingDateSort onChange={(value) => setSortOption(value)} />}
      refreshButton={true}
      manualPagination={true}
      onPaginationChange={handlePaginationChange}
      importModal={
        <ImportModal
          translate={BankingImportTranslate}
          exampleFile={exampleFile}
          queryKey={bankingQueryKey}
          bodyPayload="bankings"
        />
      }
      advancedSearch={<AdvancedSearch onSearch={handleAdvancedSearchChange} />}
      chartTable={
        <Can permissions={bankingActions.bankingStatistics}>
          <BankingsStastics />
        </Can>
      }
      compact={true}
      // chartTable={<BankingsStastics />}

      rowSelection={{
        render: (selectedRowKeys) => {
          let total = 0;
          selectedRowKeys?.map((key) => {
            const banking = rows?.find((row) => row?.originalData?._id === key)?.originalData;
            const money = !isNaN(banking?.amountOfMoney) ? banking?.amountOfMoney : 0;
            const moneyType = banking?.moneyType;
            if (moneyType === 0) {
              // vnd
              total += money;
            } else if (moneyType === 1) {
              const usdExchangeRate = banking?.usdExchangeRate;
              // usd
              total += money * usdExchangeRate;
            }
          });
          // const ids = selectedRowKeys.map((key) => rows[key]?.originalData);

          return (
            <>
              {/* Export  */}
              <Select sx={{ padding: "8px" }} displayEmpty renderValue={() => "Hành động"}>
                <MenuItem onClick={() => handleExport(selectedRowKeys)} disabled={isExporting}>
                  {isExporting ? "Exporting..." : "Xuất giao dịch đã chọn"}
                </MenuItem>
                <UpdateStatusMultiple ids={selectedRowKeys} />
              </Select>
              <MDButton>Tổng giao dịch đã chọn : {separateThousandsAndRound(total)} VND</MDButton>
            </>
          );
        },
      }}
    />
  );
}

export default BankingsTable;
