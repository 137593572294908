import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import DeleteForm from "components/DeleteForm";
import MDTypography from "components/MDTypography";
import Chip from "@mui/material/Chip";
import CardInfo from "components/CardInfo";
import { adsAccountActions, bankActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import { convertGmtOffsetToTime } from "../../utils/helpers";
import ModalPreviewContent from "../../components/ModalPreviewContent";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

function getRows({ adsAccount }) {
  try {
    const rows = adsAccount.map((adsAccount) => {
      const {
        accountID,
        timeRegion,
        members,
        cards,
        status,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
        country,
        condition,
        accountNumber1,
        accountNumber2,
        via,
        group,
        note,
      } = adsAccount || [];

      return {
        accountID: <MDTypography variant="span">{accountID}</MDTypography>,
        timeRegion: (
          <MDTypography variant="span">
            {country?.flag} {convertGmtOffsetToTime(country?.timeRegion)}
          </MDTypography>
        ),
        note: <ModalPreviewContent content={note} />,

        users: (
          <MDBox sx={{ width: 150, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {members.map((member, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={member?.name || "User Full Name"}
                sx={{ color: "text.main", m: "5px" }}
              />
            ))}
          </MDBox>
        ),
        cards: (
          <MDBox sx={{ width: 200, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {cards.map((card, index) => (
              <CardInfo key={`${card?.accountNumber}-${index}`} card={card} />
            ))}
          </MDBox>
        ),
        group: <MDTypography variant="span">{group?.name}</MDTypography>,
        status: (
          <MDTypography
            variant="span"
            sx={{
              display: "inline-block",
              width: "150px",
            }}
          >
            {condition?.title}
          </MDTypography>
        ),
        createdTime: createdTime ? <Time time={createdTime} /> : null,
        accountNumber1: <MDTypography variant="span">{accountNumber1}</MDTypography>,
        accountNumber2: <MDTypography variant="span">{accountNumber2}</MDTypography>,
        via: <MDTypography variant="span">{via}</MDTypography>,
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={createdBy?.fullName} />
        ) : null,
        updatedTime: updatedTime ? <Time time={updatedTime} /> : null,
        updatedBy: updatedBy ? (
          <StyledNameComponent img={ASSET_URL + updatedBy.avatar} name={updatedBy?.fullName} />
        ) : null,
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={adsAccountActions.updateAdsAccount}>
              <Modal>
                <UpdateForm adsAccount={adsAccount} />
              </Modal>
            </Can>
            <Can permissions={adsAccountActions.deleteAdsAccount}>
              <Modal action="delete">
                <DeleteForm context="adsAccount" id={adsAccount._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "id tài khoản", accessor: "accountID", align: "left" },
    { Header: "múi giờ", accessor: "timeRegion", align: "left" },
    { Header: "trạng thái", accessor: "status", align: "center" },
    { Header: "thành viên", accessor: "users", align: "center" },
    { Header: "thẻ", accessor: "cards", align: "center" },
    { Header: "tổ", accessor: "group", align: "center" },
    { Header: "số thẻ 1", accessor: "accountNumber1", align: "left" },
    { Header: "số thẻ 2", accessor: "accountNumber2", align: "left" },
    { Header: "Via", accessor: "via", align: "left" },
    { Header: "Ghi chú", accessor: "note", align: "center" },
    { Header: "tạo lúc", accessor: "createdTime", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },
    { Header: "cập nhật bởi", accessor: "updatedBy", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
