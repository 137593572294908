import { useRequest } from "context/RequestContext";
import { useReducer, useState } from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MDButton from "./MDButton";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import SpinnerFullPage from "./SpinnerFullPage/SpinnerFullPage";
import { useMaterialUIController } from "context";
import { useActionGroup } from "../hooks/useActionGroup";
import { useAction } from "../hooks/useAction";

const initialState = {
  isLoading: false,
  actionGroups: [],
  acitons: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return { ...state, isLoading: true };

    case "actionGroups/loading":
      return {
        ...state,
        isLoading: false,
        actionGroups: action.payload,
      };

    case "actions/loading":
      return {
        ...state,
        isLoading: false,
        actions: action.payload,
      };

    default:
      throw new Error("Invalid action type: " + action.type);
  }
}

export default function CheckboxActionsList({ actionSelected, onCheckedActions }) {
  const {
    data: listActionGroups,
    isLoading: isLoadingActionGroups,
    refetch: fetchActionGroups,
    isFetching: isFetchingActionGroups,
  } = useActionGroup({
    enabled: false,
  });
  const {
    data: listActions,
    isLoading: isLoadingActions,
    refetch: fetchActions,
    isFetching: isFetchingActions,
  } = useAction();

  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [checked, setChecked] = useState(actionSelected);

  async function getAllActionGroups() {
    await fetchActionGroups();
  }

  async function getAllActions() {
    await fetchActions();
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onCheckedActions(newChecked);
  };

  if (isLoadingActions || isLoadingActionGroups || isFetchingActionGroups || isFetchingActions)
    return <SpinnerFullPage />;

  console.log("listActionGroups", listActionGroups);

  return (
    <>
      {listActionGroups.length === 0 ? (
        <MDButton
          color={sidenavColor}
          sx={{ margin: "20px 0 20px 0" }}
          onClick={getAllActionGroups}
        >
          Hành Động
        </MDButton>
      ) : (
        <List
          sx={{
            width: "100%",
            height: 300,
            bgcolor: "background.sidenav",
            color: "text.main",
            margin: "20px 0 20px 0",
            borderRadius: "2%",
            overflow: "auto",
          }}
        >
          {listActionGroups.map((actionGroup, index) => (
            <ActionGroup key={index} actionGroup={actionGroup}>
              <Divider />
              <Actions
                actions={listActions}
                actionGroupId={actionGroup._id}
                checked={checked}
                onChecked={handleToggle}
              />
            </ActionGroup>
          ))}
        </List>
      )}
    </>
  );
}

function ActionGroup({ actionGroup, children, onOpen }) {
  const [open, setOpen] = useState(false);

  async function handleOpen() {
    onOpen && (await onOpen());
    setOpen(!open);
  }

  return (
    <Box
      sx={{
        bgcolor: open ? "rgba(71, 98, 130, 0.2)" : null,
        pb: open ? 2 : 0,
      }}
    >
      <ListItemButton
        alignItems="flex-start"
        onClick={handleOpen}
        sx={{
          px: 3,
          pt: 2.5,
          pb: open ? 0 : 2.5,
          "&:hover, &:focus": { "& svg": { opacity: open ? 1 : 0 } },
        }}
      >
        <ListItemText
          primary={actionGroup.viName || actionGroup.name}
          primaryTypographyProps={{
            fontSize: "16px",
            fontWeight: "medium",
            lineHeight: "20px",
            mb: "2px",
          }}
          sx={{ my: 0 }}
        />
        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: 0,
            transform: open ? "rotate(-180deg)" : "rotate(0)",
            transition: "0.2s",
          }}
        />
      </ListItemButton>
      {open && children}
    </Box>
  );
}

function Actions({ actions, actionGroupId, checked, onChecked }) {
  const actionsList = actions.filter((action) => {
    const actionGroups = action.actionGroups;
    return (
      Array.isArray(actionGroups) && actionGroups.length > 0 && actionGroups.includes(actionGroupId)
    );
  });

  if (actions.length === 0) return <SpinnerFullPage />;

  return actionsList.map((value) => {
    const labelId = `checkbox-list-label-${value._id}`;

    return (
      <ListItemButton key={value._id} onClick={onChecked(value._id)} dense>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked.indexOf(value._id) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={`${value.viName || value.name}`} />
      </ListItemButton>
    );
  });
}
