import APIBase from "./axiosBase";

const apiBase = new APIBase();

export async function readNotification(id) {
  try {
    const user = localStorage.getItem("_id");
    const url = `/notification/read/${id}`;

    const res = await apiBase.put(url, { user });
    return res;
  } catch (err) {
    throw new Error(err);
  }
}
