import { createContext, useContext } from "react";
import { useNotificationQuery } from "layouts/notification/useNotificationQuery";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";

const NotificationContext = createContext();

function NotificationProvider({ children }) {
  const { data: notifications, isLoading } = useNotificationQuery();

  return (
    <NotificationContext.Provider value={{ notifications, isLoading }}>
      {children}
    </NotificationContext.Provider>
  );
}

function useNotification() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error("NotificationContext is used outside of NotificationProvider");
  }
  return context;
}

export { NotificationProvider, useNotification };
