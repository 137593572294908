import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import DeleteForm from "components/DeleteForm";
import { bankActions, simActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;
import { listSimType } from "../../constants/listSimType";
import { deepSearch } from "../../utils/deep-search";
import { displayShortContent } from "../banking/bankingsData";
import { DisplayContent } from "../../libs/display-content";
import NestedList from "../../components/NestedList";
import moment from "moment";
import { formatCurrency, separateThousands } from "../../utils/helpers";
import MDTypography from "../../components/MDTypography";
import ModalPreviewContent from "../../components/ModalPreviewContent";
function getRows({ sim, filters = {} }) {
  try {
    let rows = sim;
    const { searchValue, rangeDate, groups, departments } = filters;
    const [startDate, endDate] = rangeDate || [null, null];
    // filter by name
    if (searchValue) {
      rows = rows.filter((row) => row?._id === searchValue || deepSearch(row, searchValue));
    }
    // filter by date
    if (startDate && endDate) {
      rows = rows.filter((row) => {
        const createdTime = moment(row.createdTime);
        return createdTime.isBetween(startDate, endDate);
      });
    }
    if (groups?.length && departments?.length) {
      rows = rows.filter((row) => {
        return (
          groups.some((group) => row.groups.map((g) => g._id).includes(group)) ||
          departments.some((department) => row.departments.map((d) => d._id).includes(department))
        );
      });
    } else if (groups?.length) {
      rows = rows.filter((row) =>
        groups.some((group) => row.groups.map((g) => g._id).includes(group))
      );
    } else if (departments?.length) {
      rows = rows.filter((row) => {
        return departments.some((department) =>
          row.departments.map((d) => d._id).includes(department)
        );
      });
    }
    //

    rows = rows.map((item) => {
      const {
        name,
        departments,
        isDeleted,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
        groups,
        simType,
        phoneNumber,
        expiryDate,
        note,
        isHighlight,
      } = item;
      if (isHighlight) {
        return {
          departments: (
            <MDBox sx={{ width: 100, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {departments.map((department, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={department.name}
                  sx={{ color: "warning.main", borderColor: "warning.main", m: "5px" }}
                />
              ))}
            </MDBox>
          ),
          groups: (
            <MDBox sx={{ width: 100, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {groups.map((group, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={group.name}
                  sx={{ color: "warning.main", borderColor: "warning.main", m: "5px" }}
                />
              ))}
            </MDBox>
          ),
          name: (
            <MDTypography variant="caption" color="warning" fontWeight="medium">
              {name}
            </MDTypography>
          ),
          simType: (
            <MDBadge
              badgeContent={listSimType[simType]?.name}
              variant="gradient"
              size="sm"
            ></MDBadge>
          ),
          phoneNumber: (
            <MDTypography component="span" variant="caption" color="warning" fontWeight="medium">
              {phoneNumber}
            </MDTypography>
          ),
          expiryDate: expiryDate ? <Time time={expiryDate} color="warning" /> : null,
          isDeleted: (
            <MDBadge
              badgeContent={String(isDeleted)}
              color={isDeleted ? "error" : "success"}
              variant="gradient"
              size="sm"
            />
          ),
          note: <ModalPreviewContent content={note} />,
          balance: (
            <MDTypography variant="caption" color="warning" fontWeight="medium">
              {separateThousands(item?.balance)} đ
            </MDTypography>
          ),
          createdTime: createdTime ? <Time time={createdTime} color="warning" /> : null,
          createdBy: createdBy ? (
            <StyledNameComponent
              img={ASSET_URL + createdBy.avatar}
              name={createdBy.fullName}
              isHighlighted
            />
          ) : null,
          updatedTime: updatedTime ? <Time time={updatedTime} color="warning" /> : null,
          updatedBy: updatedBy ? (
            <StyledNameComponent
              img={ASSET_URL + updatedBy.avatar}
              isHighlighted
              name={updatedBy.fullName}
            />
          ) : null,
          action: (
            <MDBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}
            >
              <Can permissions={simActions.updateSim}>
                <Modal>
                  <UpdateForm sim={item} />
                </Modal>
              </Can>
              <Can permissions={simActions.deleteSim}>
                <Modal action="delete">
                  <DeleteForm context="sim" id={item._id} />
                </Modal>
              </Can>
            </MDBox>
          ),
        };
      }
      return {
        departments: (
          <MDBox sx={{ width: 100, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {departments.map((department, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={department.name}
                sx={{ color: "text.main", m: "5px" }}
              />
            ))}
          </MDBox>
        ),
        groups: (
          <MDBox sx={{ width: 100, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {groups.map((group, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={group.name}
                sx={{ color: "text.main", m: "5px" }}
              />
            ))}
          </MDBox>
        ),
        name: (
          <Typography variant="caption" color="text" fontWeight="medium">
            {name}
          </Typography>
        ),
        simType: (
          <MDBadge badgeContent={listSimType[simType]?.name} variant="gradient" size="sm"></MDBadge>
        ),
        phoneNumber: (
          <Typography variant="caption" color="text" fontWeight="medium">
            {phoneNumber}
          </Typography>
        ),
        expiryDate: expiryDate ? <Time time={expiryDate} /> : null,
        isDeleted: (
          <MDBadge
            badgeContent={String(isDeleted)}
            color={isDeleted ? "error" : "success"}
            variant="gradient"
            size="sm"
          />
        ),
        note: <ModalPreviewContent content={note} />,

        balance: (
          <Typography variant="caption" color="text" fontWeight="medium">
            {separateThousands(item?.balance)} đ
          </Typography>
        ),
        createdTime: createdTime ? <Time time={createdTime} /> : null,
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={createdBy.fullName} />
        ) : null,
        updatedTime: updatedTime ? <Time time={updatedTime} /> : null,
        updatedBy: updatedBy ? (
          <StyledNameComponent img={ASSET_URL + updatedBy.avatar} name={updatedBy.fullName} />
        ) : null,
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={simActions.updateSim}>
              <Modal>
                <UpdateForm sim={item} />
              </Modal>
            </Can>
            <Can permissions={simActions.deleteSim}>
              <Modal action="delete">
                <DeleteForm context="sim" id={item._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "tạo lúc", accessor: "createdTime", align: "center" },
    { Header: "bộ phận", accessor: "departments", align: "left" },
    { Header: "tổ", accessor: "groups", align: "left" },
    { Header: "tên", accessor: "name", align: "left" },
    { Header: "loại sim", accessor: "simType", align: "center" },
    { Header: "số điện thoại", accessor: "phoneNumber", align: "center" },
    { Header: "số dư", accessor: "balance", align: "center" },
    { Header: "ngày hết hạn", accessor: "expiryDate", align: "center" },
    { Header: "ghi chú", accessor: "note", align: "left" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },

    { Header: "trạng thái xóa", accessor: "isDeleted", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
