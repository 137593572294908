import { useRequest as useRequestAPI } from "../context/RequestContext";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/request/requestData";
import useCanAccess from "./useCanAccess";
import { requestActions } from "../constants/actions";
export const requestQueryKey = "request";

export const useRequest = ({ isDeleted = false } = {}) => {
  const { makeRequest } = useRequestAPI();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [searchValue, setSearchValue] = useState("");
  const { can } = useCanAccess();
  // const [rows, setRows] = useState([]);
  const _transformPagination = {
    ...pagination,
    pageIndex: pagination.pageIndex + 1,
    name: searchValue,
    pendingCount: true,
  };
  const { data, isLoading, error } = useQuery({
    queryKey: [
      requestQueryKey,
      _transformPagination.pageIndex + 1,
      _transformPagination.pageSize,
      searchValue,
      isDeleted,
    ],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${requestQueryKey}/getPaging?isDeleted=${isDeleted}&${new URLSearchParams(
          _transformPagination
        ).toString()}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      const request = data?.data?.items || [];
      const pendingRequest = data?.data?.pendingCount || 0;
      const rows = getRows({ request });

      const pagination = {
        pageIndex: data?.pageIndex - 1 <= 0 ? 0 : data?.pageIndex - 1,
        pageSize: data?.pageSize,
        totalPages: data?.totalPages,
      };
      return {
        rows,
        pagination,
        pendingRequest,
      };
    },
    enabled: can(requestActions.getPagingRequest),
    placeholderData: (previousData, previousQuery) => {
      return (
        previousData || {
          data: [],
          pageIndex: "1",
          pageSize: "10",
          totalPages: 1,
        }
      );
    },
  });
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handlePaginationChange = (pageIndex, pageSize) => {
    setPagination({ pageIndex, pageSize });
  };

  return {
    data,
    isLoading,
    error,
    handlePaginationChange,
    pagination,
    handleSearchChange,
  };
};
