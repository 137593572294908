import React from "react";
import MDBox from "../../components/MDBox";
import Typography from "@mui/material/Typography";
import MDButton from "../../components/MDButton";

const Page404 = () => {
  return (
    <MDBox
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "background.default",
        color: "text.main",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h1" style={{ color: "white" }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: "white" }}>
        Bạn không có quyền truy cập trang này
      </Typography>
      <MDButton variant="contained">
        <a href="/">Quay lại trang chủ</a>
      </MDButton>
    </MDBox>
  );
};

export default Page404;
