import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";

export const countryQueryKey = "country";

export const useCountry = ({ enabled = true } = {}) => {
  const { makeRequest } = useRequest();
  const [searchValue, setSearchValue] = useState("");

  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: [countryQueryKey],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${countryQueryKey}/getAll?`,
      };

      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled,
    placeholderData: [],
  });

  return {
    data,
    isLoading,
    error,
    refetch,
    isFetching,
  };
};
