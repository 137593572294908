import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { useRequest } from "context/RequestContext";
import { useState } from "react";
import { useMaterialUIController } from "context";
import { useQueryClient } from "@tanstack/react-query";

import { actionQueryKey } from "../../hooks/useAction";

const uploadStyle = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
};

const context = "action";

function UpdateForm({ bank, create = false }) {
  const [name, setName] = useState(bank?.name || "");
  const [viName, setViName] = useState(bank?.viName || "");
  const [isLoading, setIsLoading] = useState(false);

  const { createRequest, updateRequest } = useRequest();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const queryClient = useQueryClient();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const body = { name, viName };

      if (!create) {
        await updateRequest({ context, id: bank._id, body });
        queryClient.invalidateQueries({
          queryKey: [actionQueryKey],
        });
      } else {
        await createRequest({ context, body });
        queryClient.invalidateQueries({
          queryKey: [actionQueryKey],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <MDBox component="form" onSubmit={handleSubmit}>
      <MDInput
        fullWidth
        id="name"
        label="Tên"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <MDInput
        fullWidth
        id="viName"
        label="Tên Tiếng Việt"
        variant="outlined"
        value={viName}
        onChange={(e) => setViName(e.target.value)}
        sx={{ marginTop: "20px" }}
        required
      />

      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
