import { getColumns } from "./banksData";
import TableLayout from "layouts/TableLayout";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { bankQueryKey, useBank } from "../../hooks/useBank";
import { bankActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import BankSearch from "./BankSearch";
import { useSearchParams } from "react-router-dom";

function BanksTable() {
  const [searchParams] = useSearchParams();

  const { rows, handleSearchChange, isLoading, data } = useBank({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
  });
  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="Ngân Hàng"
      canCreate={
        <Can permissions={bankActions.insertBank} fallback={"Bạn không có quyền thêm ngân hàng"}>
          <UpdateForm create={true} />
        </Can>
      }
      canSearch={false}
      extraSearch={<BankSearch banks={data} onChange={handleSearchChange} />}
      canExport={bankQueryKey}
      refreshButton={true}
      compact={true}
      sortButton={false}
    />
  );
}

export default BanksTable;
