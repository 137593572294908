import React from "react";
import RangeDatePicker from "../../components/RangeDatePicker";
import MDBox from "../../components/MDBox";
import useFuse from "../../hooks/useFuse";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import { convertGmtOffsetToTime } from "../../utils/helpers";
import { useGroup } from "../../hooks/useGroup";
import { useDepartment } from "../../hooks/useDepartments";

const SimExtraSearch = ({ onChange, sims }) => {
  const options = {
    keys: ["name", "departments.name", "groups.name", "phoneNumber"],
    includeMatches: true,
  };
  const { data: groupData } = useGroup();
  const { data: departmentData } = useDepartment();
  const { result, search, reset } = useFuse({
    data: sims || [],
    options,
  });
  const handleChange = (value, type) => {
    if (type === "date" || type === "groups" || type === "departments") {
      onChange && onChange(value, type);
    }
    if (type === "sim") {
      value = value?._id || value;
      onChange && onChange(value, type);
    }
  };
  return (
    <>
      <MDBox
        sx={{
          width: 120,
          flexShrink: 0,
        }}
      >
        <RangeDatePicker
          label="Ngày Tạo"
          onChange={(dates) => {
            handleChange(dates, "date");
          }}
        />
      </MDBox>
      <Autocomplete
        disablePortal
        options={departmentData || []}
        sx={{ width: 120, flexShrink: 0 }}
        multiple
        getOptionLabel={(option) => `${option?.name}`}
        onChange={(e, newValue) => {
          const value = newValue?.map((item) => item?._id);
          handleChange(value, "departments");
        }}
        renderOption={(props, option) => (
          <MDBox component="li" {...props} key={option?._id}>
            <p>{option.name}</p>
          </MDBox>
        )}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder="Bộ phận"
            size="small"
            variant="outlined"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                paddingTop: "7px",
                paddingBottom: "7px",
              },
            }}
            onChange={(e) => {
              search(e.target.value);
            }}
          />
        )}
      />
      <Autocomplete
        sx={{ width: 120, flexShrink: 0 }}
        disablePortal
        multiple={true}
        options={groupData || []}
        getOptionLabel={(option) => `${option?.name}`}
        onChange={(e, newValue) => {
          const value = newValue?.map((item) => item?._id);

          handleChange(value, "groups");
        }}
        renderOption={(props, option) => (
          <MDBox
            component="li"
            {...props}
            sx={{
              padding: "5px",
              flexShrink: 0,
            }}
            key={option?._id}
          >
            <p>{option.name}</p>
          </MDBox>
        )}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder="Tổ"
            size="small"
            variant="outlined"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                paddingTop: "7px",
                paddingBottom: "7px",
              },
            }}
            onChange={(e) => {
              search(e.target.value);
            }}
          />
        )}
      />

      <Autocomplete
        disablePortal
        options={sims || []}
        sx={{ width: 300, flexShrink: 0 }}
        getOptionLabel={(option) => `${option?.name}`}
        filterOptions={(options) => {
          return result;
        }}
        onChange={(e, newValue) => {
          handleChange(newValue, "sim");
        }}
        renderOption={(props, option) => (
          <MDBox
            component="li"
            sx={{ "& > p": { mr: 2, flexShrink: 0 } }}
            {...props}
            key={option?._id}
          >
            <p>
              {option.name}-{option.phoneNumber}
            </p>
          </MDBox>
        )}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder="Tìm kiếm (Tên thẻ sim,Số điện thoại, Tên tổ, Tên Bộ phận)"
            size="small"
            variant="outlined"
            fullWidth
            // sx={{ paddingTop: "8px", paddingBottom: "6px", transform: "translateY(-2px)" }}
            onBlur={() => {
              reset();
            }}
            sx={{
              "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                paddingTop: "7px",
                paddingBottom: "7px",
              },
            }}
            onChange={(e) => {
              search(e.target.value);
            }}
          />
        )}
      />
    </>
  );
};

export default SimExtraSearch;
