import { getColumns } from "./actionData";

import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { actionQueryKey, useAction } from "../../hooks/useAction";

import TableLayout from "../TableLayout";

function ActionsTable() {
  const { rows, handleSearchChange, isLoading } = useAction();

  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="Hành Động"
      canCreate={<UpdateForm create={true} />}
      onSearch={handleSearchChange}
      canExport={actionQueryKey}
      refreshButton={true}
    />
  );
}

export default ActionsTable;
