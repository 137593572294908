/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { useMemo, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { List } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { ChevronRight, StarBorder } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import MDBadge from "../../components/MDBadge";
import { useRequest } from "../../hooks/useRequest";

const RenderItem = ({ item, rest }) => {
  const [controller] = useMaterialUIController();

  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const itemChildrenList = item?.childrenList || item?.children || [];

  let active = item?.route === window.location.pathname;
  const [itemOpen, setItemOpen] = useState(window.location.pathname.includes(item?.route));

  if (itemChildrenList.length > 0) {
    active = itemChildrenList.some((child) => child?.route === window.location.pathname);
    return (
      <ListItem
        component="li"
        key={item.name}
        sx={{
          flexWrap: "wrap",
        }}
      >
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
              hasChildren: true,
            })
          }
          onClick={() => setItemOpen(!itemOpen)}
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
                active,
              })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{item?.icon}</Icon>
            ) : (
              item?.icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={item?.name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          {itemOpen ? <ExpandLess /> : <ChevronRight />}
        </MDBox>

        <Collapse
          in={itemOpen}
          timeout="auto"
          unmountOnExit
          sx={{
            width: "100%",
          }}
        >
          <List disablePadding>
            {itemChildrenList.map((item, index) => (
              <RenderItem item={item} rest={rest} key={index} />
            ))}
          </List>
        </Collapse>
      </ListItem>
    );
  }
  return (
    <NavLink key={item?.key} to={item?.route}>
      <ListItem component="li" key={item.name}>
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
                active,
              })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{item?.icon}</Icon>
            ) : (
              item?.icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={item?.name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
        </MDBox>
      </ListItem>
    </NavLink>
  );
};
function SidenavCollapse({ icon, name, active, childrenList, meta, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [open, setOpen] = useState(active || false);
  const { data } = useRequest();

  const pendingRequest = data?.pendingRequest || 0;

  function renderCollapseItem(item, index) {
    return <RenderItem key={item?.key || index} item={item} rest={rest} />;
  }
  if (childrenList) {
    return (
      <ListItem
        component="li"
        sx={{
          flexWrap: "wrap",
        }}
      >
        <MDBox
          {...rest}
          sx={(theme) => {
            return {
              ...collapseItem(theme, {
                active,
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
                hasChildren: true,
              }),
              width: "100%",
            };
          }}
          onClick={() => setOpen(!open)}
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          {open ? <ExpandLess /> : <ChevronRight />}
        </MDBox>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{
            width: "100%",
          }}
        >
          <List disablePadding>{childrenList.map(renderCollapseItem)}</List>
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem component="li">
      <MDBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
        key={`collapse-${name}`}
      >
        <MDBadge badgeContent={meta?.badge ? pendingRequest : 0} color="primary">
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>
        </MDBadge>

        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />
      </MDBox>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
