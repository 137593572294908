import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import MDInput from "./MDInput";
import moment from "moment";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export default function DatePicker({ selected, onChange, formClick, ...rest }) {
  const [startDate, setStartDate] = useState(() => {
    return selected || new Date();
  });
  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   setIsOpen(false);
  // }, [formClick]);

  const momentData = moment(startDate);

  function handleDateChange(date) {
    setStartDate(date);
    onChange && onChange(date);
  }

  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  }

  return (
    <ReactDatePicker
      className="custom-daypicker"
      selected={startDate}
      onChange={handleDateChange}
      dateFormat="dd/MM/yyyy HH:mm"
      showTimeInput
      timeFormat="HH:mm"
      customInput={
        <MDInput
          fullWidth
          label={rest.label || "Thời Gian Giao Dịch"}
          size="small"
          value={momentData.format("DD/MM/YYYY-HH:mm")}
          sx={{ marginTop: "20px", width: "100%" }}
          required
          onClick={handleClick}
        />
      }
    />
  );
}
