import { getColumns } from "./punishmentsData";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import TableServerPaginateLayout from "../TableServerPaginateLayout";
import { punishmentQueryKey, usePunishment } from "../../hooks/usePunishment";
import UserSelect from "./UserSelect";
import { punishmentActions, userActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import { useSearchParams } from "react-router-dom";
import ImportModal from "../../components/ImportModal";
import exampleFile from "../banking/banking-example.csv";
import React from "react";

function PunishmentsTable() {
  const [searchParams] = useSearchParams();

  const {
    data,
    error,
    handlePaginationChange,
    isLoading,
    pagination,
    handleSearchChange,
    handleUserChange,
    handleDateRangeChange,
    setSortBy,
  } = usePunishment({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
  });
  const columns = getColumns();

  const handleChange = (values, type) => {
    if (type === "member") {
      handleUserChange(values);
      return;
    }

    if (type === "date") {
      handleDateRangeChange(values);
    }

    if (type === "sortBy") {
      setSortBy(values);
    }
  };

  if (isLoading) return <SpinnerFullPage />;

  let BankingImportTranslate = {};
  return (
    <TableServerPaginateLayout
      columns={columns}
      rows={data?.rows || []}
      title="Đơn Phạt"
      canCreate={
        <Can
          permissions={[punishmentActions.insertPunishment, userActions.getPagingUsers]}
          fallback={"Bạn không có quyền thêm đơn phạt hoặc xem danh sách nhân viên"}
        >
          <UpdateForm create={true} />
        </Can>
      }
      importModal={
        <ImportModal
          translate={BankingImportTranslate}
          exampleFile={exampleFile}
          queryKey={punishmentQueryKey}
          bodyPayload="punishments"
        />
      }
      totalItems={data?.pagination?.totalItems}
      pageCount={data?.pagination?.totalPages}
      manualPagination={true}
      onPaginationChange={handlePaginationChange}
      pageIndex={pagination?.pageIndex}
      pageSize={pagination?.pageSize}
      onSearchChange={handleSearchChange}
      canSearch={true}
      extraSearch={<UserSelect onChange={handleChange} />}
      canExport={punishmentQueryKey}
      refreshButton={true}
      compact={true}
    />
  );
}

export default PunishmentsTable;
