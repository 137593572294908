import React, { memo, useCallback, useMemo } from "react";
import { isArray, isEmpty, isUndefined } from "lodash";
import { useAuth } from "../context/AuthContext";

const checkAccess = (permissions, userPermissions, role, isAuthenticated) => {
  if (role === "SUPERADMIN") {
    return true;
  }

  if (isEmpty(permissions) || isUndefined(permissions)) {
    return true;
  }

  if (isArray(permissions)) {
    return permissions.every((permission) => userPermissions.includes(permission));
  } else {
    return userPermissions.includes(permissions);
  }
};

const CanComponent = memo(
  ({ permissions, userPermissions, role, isAuthenticated, children, fallback }) => {
    const hasAccess = checkAccess(permissions, userPermissions, role, isAuthenticated);
    return hasAccess ? <>{children}</> : fallback ? <>{fallback}</> : null;
  }
);

const useCanAccess = () => {
  const { user, isAuthenticated } = useAuth();
  const userPermissions = useMemo(() => {
    return (user?.role?.actions || user?.user?.role?.actions || []).map((action) => action.name);
  }, [user]);
  const role = useMemo(() => {
    return user?.role?.name || user?.user?.role?.name;
  }, [user]);

  const Can = useCallback(
    ({ permissions, children, fallback = null }) => (
      <CanComponent
        permissions={permissions}
        userPermissions={userPermissions}
        role={role}
        isAuthenticated={isAuthenticated}
        fallback={fallback}
      >
        {children}
      </CanComponent>
    ),
    [userPermissions, role, isAuthenticated]
  );

  const can = useCallback(
    (permissions) => {
      return checkAccess(permissions, userPermissions, role, isAuthenticated);
    },
    [userPermissions, role, isAuthenticated]
  );

  const canOneOf = useCallback(
    (permissions) => {
      if (role === "SUPERADMIN") {
        return true;
      }

      if (isEmpty(permissions) || isUndefined(permissions)) {
        return true;
      }

      if (isArray(permissions)) {
        return permissions.some((permission) => userPermissions.includes(permission));
      } else {
        return userPermissions.includes(permissions);
      }
    },
    [userPermissions, role]
  );

  return {
    Can,
    can,
    canOneOf,
  };
};

export const Can = ({ permissions, children, fallback }) => {
  const { Can } = useCanAccess();
  // return children;
  return (
    <Can permissions={permissions} fallback={fallback}>
      {children}
    </Can>
  );
};

export default useCanAccess;
