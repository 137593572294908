import MenuItem from "@mui/material/MenuItem";
import SelectRenderProp from "./Select";
import { useSearchParams } from "react-router-dom";
import useCanAccess from "hooks/useCanAccess";

function IsDeletedFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { can } = useCanAccess();

  function handleChange(value) {
    searchParams.set("isDeleted", value);
    setSearchParams(searchParams);
  }

  return (
    can("isDeletedFitler") && (
      <SelectRenderProp
        title="Trạng Thái"
        defaultValue={searchParams.get("isDeleted") ?? false}
        data={[
          { value: true, title: "Đã Xoá" },
          { value: false, title: "Hoạt Động" },
        ]}
        render={(data) => {
          return (
            <MenuItem key={data.value} value={data.value}>
              {data.title}
            </MenuItem>
          );
        }}
        onChange={handleChange}
      />
    )
  );
}

export default IsDeletedFilter;
