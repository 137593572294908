import { getColumns } from "./cardCategoriesData";
import TableLayout from "layouts/TableLayout";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { cardCategoryQueryKey, useCardCategory } from "../../hooks/useCardCategory";
import { Can } from "../../hooks/useCanAccess";
import { cardCategoryActions } from "../../constants/actions";
import { useSearchParams } from "react-router-dom";

function CardCategoriesTable() {
  const [searchParams] = useSearchParams();

  const { rows, handleSearchChange, dispatchError, isLoading } = useCardCategory({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
  });
  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="Loại Thẻ"
      onSearch={handleSearchChange}
      canCreate={
        <Can
          permissions={cardCategoryActions.insertCardCategory}
          fallback={"Bạn không có quyền thêm loại thẻ"}
        >
          <UpdateForm create={true} />
        </Can>
      }
      canSearch={true}
      canExport={cardCategoryQueryKey}
      refreshButton={true}
      compact={true}
      sortButton={false}
    />
  );
}

export default CardCategoriesTable;
