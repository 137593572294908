import { getColumns } from "./bankingTypesData";
import TableLayout from "layouts/TableLayout";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { bankingTypeQueryKey, useBakingType } from "../../hooks/useBankingType";
import { Can } from "../../hooks/useCanAccess";
import { bankingTypeActions } from "../../constants/actions";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_SORT } from "../../utils/constants";

function BankingTypesTable() {
  const [searchParams] = useSearchParams();

  const { rows, handleSearchChange, isLoading } = useBakingType({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
    sort: searchParams.get("sort") || DEFAULT_SORT,
  });
  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="Kiểu Giao Dịch"
      onSearch={handleSearchChange}
      canCreate={
        <Can
          permissions={bankingTypeActions.insertBankingType}
          fallback={"Bạn không có quyền thêm kiểu giao dịch"}
        >
          <UpdateForm create={true} />
        </Can>
      }
      canExport={bankingTypeQueryKey}
      refreshButton={true}
      compact={true}
    />
  );
}

export default BankingTypesTable;
