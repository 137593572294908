import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/card-check/cardCheckData";
import useCanAccess from "./useCanAccess";
import { cardCheckActions } from "../constants/actions";
import { DEFAULT_SORT } from "../utils/constants";
export const cardCheckQueryKey = "cardCheck";

export const useCardCheck = ({ isDeleted = false, sort = DEFAULT_SORT } = {}) => {
  const { makeRequest } = useRequest();
  const [searchValue, setSearchValue] = useState("");
  const [advancedSearchContext, setAdvancedSearchContext] = useState({});
  const { can } = useCanAccess();

  const { data, isLoading, error } = useQuery({
    queryKey: [cardCheckQueryKey, { isDeleted, sort }],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${cardCheckQueryKey}/getAll?isDeleted=${isDeleted}&sort=${sort}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled: can(cardCheckActions.getAllCardChecks),
    placeholderData: (previousData, previousQuery) => previousData,
  });

  const rows = useMemo(() => {
    try {
      const filters = {
        searchValue,
        ...advancedSearchContext,
      };
      const cards = data;

      return getRows({ cards, filters });
    } catch (e) {
      return [];
    }
  }, [data, searchValue, advancedSearchContext]);
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleAdvancedSearchChange = (value) => {
    setAdvancedSearchContext(value);
  };

  return {
    data,
    rows,
    isLoading,
    error,
    handleSearchChange,
    handleAdvancedSearchChange,
  };
};
