import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/groups/groupsData";
import { isArray } from "lodash";
import useCanAccess from "./useCanAccess";
import { groupActions } from "../constants/actions";
import { DEFAULT_SORT } from "../utils/constants";
export const groupQueryKey = "group";

export const useGroup = ({ enabled = true, isDeleted = false, sort = DEFAULT_SORT } = {}) => {
  const { makeRequest } = useRequest();
  const [searchValue, setSearchValue] = useState("");
  const [department, setDepartment] = useState("");
  const [groupValue, setGroupValue] = useState("");
  const { can } = useCanAccess();
  // const [rows, setRows] = useState([]);

  const { data, isLoading, error } = useQuery({
    queryKey: [groupQueryKey, { isDeleted, sort }],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${groupQueryKey}/getAll?isDeleted=${isDeleted}&sort=${sort}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    placeholderData: (previousData, previousQuery) => previousData || [],
    enabled: can(groupActions.getAllGroups) && enabled,
  });
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleSelectDepartment = (value) => {
    if (value && isArray(value)) value = value[0];
    if (value) {
      setDepartment(value);
    } else {
      setDepartment("");
    }
  };

  const handleSelectGroup = (value) => {
    if (value && isArray(value)) value = value[0];
    if (value) {
      setGroupValue(value);
    } else {
      setGroupValue("");
    }
  };

  const rows = useMemo(() => {
    try {
      const filters = {
        name: searchValue,
        department: department,
        group: groupValue,
      };
      const group = data;

      return getRows({ group, filters });
    } catch (e) {
      return [];
    }
  }, [data, searchValue, department, groupValue]);

  return {
    data,
    rows,
    isLoading,
    error,
    handleSearchChange,
    handleSelectDepartment,
    handleSelectGroup,
  };
};
