import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import DeleteForm from "components/DeleteForm";
import { Can } from "../../hooks/useCanAccess";
import { memberActions } from "../../constants/actions";
import { DisplayContent, getTextFromHtmlTag } from "../../libs/display-content";
import NestedList from "../../components/NestedList";
import { displayShortContent } from "../banking/bankingsData";
import ModalPreviewContent from "../../components/ModalPreviewContent";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

function Badge({ content, data }) {
  return (
    <MDBadge
      badgeContent={content}
      color={data ? "error" : "success"}
      variant="gradient"
      size="sm"
    />
  );
}

function getRows({ member, filters = {} }) {
  try {
    let rows = member;
    // filter by group
    if (filters.group) {
      rows = rows.filter((row) => {
        return row.group._id === filters.group;
      });
    }
    if (filters?.member) {
      rows = rows.filter((row) => {
        return row._id === filters.member;
      });
    }
    rows = rows.map((member) => {
      const {
        name,
        avatar,
        group,
        title,
        isDeleted,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
        note,
      } = member || [];

      return {
        member: <StyledNameComponent img={ASSET_URL + avatar} name={name} />,
        group: <StyledNameComponent img={ASSET_URL + group.icon} name={group.name} />,
        title: <StyledNameComponent name={title ? "Manager" : "Member"} />,
        isDeleted: <Badge content={String(isDeleted)} data={isDeleted} />,
        createdTime: createdTime ? <Time time={createdTime} /> : null,
        note: <ModalPreviewContent content={note} />,
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={createdBy.fullName} />
        ) : null,
        updatedTime: updatedTime ? <Time time={updatedTime} /> : null,
        updatedBy: updatedBy ? (
          <StyledNameComponent img={ASSET_URL + updatedBy.avatar} name={updatedBy.fullName} />
        ) : null,
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={memberActions.updateMember}>
              <Modal>
                <UpdateForm member={member} />
              </Modal>
            </Can>
            <Can permissions={memberActions.deleteMember}>
              <Modal action="delete">
                <DeleteForm context="member" id={member._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "thành viên", accessor: "member", width: "15%", align: "left" },
    { Header: "tổ", accessor: "group", align: "left" },
    { Header: "chức vụ", accessor: "title", align: "center" },
    { Header: "ghi chú", accessor: "note", align: "left", width: "25%" },

    { Header: "trạng thái xoá", accessor: "isDeleted", align: "center" },
    { Header: "tạo lúc", accessor: "createdTime", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },
    { Header: "cập nhật bởi", accessor: "updatedBy", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
