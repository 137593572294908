import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { FormControl, FormLabel, FormHelperText, Box } from "@mui/material";
import "react-quill/dist/quill.snow.css";
// overwrite css
import "./quill-style.css";
import { useMaterialUIController } from "context";
import { QuillToolbar, modules, uploadFile } from "./QuillToolbar";
const ASSET_URL = process.env.REACT_APP_ASSET_URL;

function QuillInputField({ helperText, initialValue, onChange, ...rest }) {
  const [content, setContent] = useState(initialValue || "");
  const [{ darkMode }] = useMaterialUIController();
  const quillRef = useRef(null);

  const handleContentChange = (content) => {
    setContent(content);
    onChange && onChange(content);
  };

  const handleFileUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.onchange = async (e) => {
      const file = e.target.files[0];
      const url = await uploadFile(file);
      const fullUrl = `${ASSET_URL}${url}`;
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      if (range) {
        // insert html to editor
        editor.insertText(range.index, "Đường dẫn File:", "link", fullUrl);
      } else {
        editor.insertText(editor.getLength(), "Đường dẫn File:", "link", fullUrl);
      }
    };
    input.click();
  };

  const customDark = `${darkMode && "custom-quill-dark"}`;

  return (
    <FormControl fullWidth margin="normal">
      <Box sx={{ mt: 1, mb: 2, borderRadius: 1 }}>
        <QuillToolbar className={customDark} onFileUpload={handleFileUpload} />
        <ReactQuill
          placeholder={rest?.label || "Nội Dung"}
          className={`custom-quill ${customDark}`}
          value={content}
          onChange={handleContentChange}
          modules={modules}
          style={{ overflow: "auto" }}
          ref={quillRef}
        />
      </Box>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default QuillInputField;
