import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import ReactDatePicker from "react-datepicker";
import MDInput from "./MDInput";

function RangeDatePicker({ selected, onChange, ...rest }) {
  const [startDate, setStartDate] = useState(selected ? selected[0] : undefined);
  const [endDate, setEndDate] = useState(selected ? selected[1] : undefined);

  useEffect(() => {
    if (selected) {
      setStartDate(selected[0]);
      setEndDate(selected[1]);
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [selected]);

  const value = `${startDate ? moment(startDate).format("DD/MM/YYYY") : ""} - ${
    endDate ? moment(endDate).format("DD/MM/YYYY") : ""
  }`;

  const handleOnChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if ((start && end) || (!start && !end)) {
      onChange && onChange(dates);
    }
  };

  return (
    <ReactDatePicker
      className={rest?.className || "custom-daypicker"}
      selectsRange
      endDate={endDate}
      selected={startDate}
      startDate={startDate}
      onChange={handleOnChange}
      style={{ width: "100%", ...rest?.style }}
      isClearable
      customInput={
        <MDInput
          fullWidth
          size={rest?.size || "small"}
          label={rest.label || "Thời Gian Giao Dịch"}
          value={value}
          required
          style={{ ...rest?.style }}
          inputProps={{
            autocomplete: "off",
            form: {
              autocomplete: "off",
            },
          }}
        />
      }
    />
  );
}

export default RangeDatePicker;
