import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useRequest } from "context/RequestContext";
import React, { useState } from "react";
import { useMaterialUIController } from "context";
import { useQueryClient } from "@tanstack/react-query";
import { bankingTypeQueryKey } from "../../hooks/useBankingType";
import MultipleSelectChip from "components/MultipleSelectChip";
import { useDepartment } from "hooks/useDepartments";
import { useGroup } from "hooks/useGroup";
import QuillInputField from "../../components/QuillInputField";

const uploadStyle = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
};

const context = "bankingType";

function UpdateForm({ bankingType, create = false }) {
  const [name, setName] = useState(bankingType?.name || "");
  const [icon, setIcon] = useState("");
  const [bankingTypeDepartments, setBankingTypeDepartments] = useState(
    bankingType?.departments || []
  );
  const [bankingTypeGroups, setBankingTypeGroups] = useState(bankingType?.groups || []);
  const [note, setNote] = useState(bankingType?.note || "");
  const [isLoading, setIsLoading] = useState(false);

  const { createRequest, updateRequest } = useRequest();
  const [{ sidenavColor }] = useMaterialUIController();
  const { data: departments = [] } = useDepartment();

  const { data: groups = [] } = useGroup();
  let filteredGroups = [];
  groups.filter((group) => {
    for (let department of bankingTypeDepartments) {
      const include = group?.departments?.filter?.((item) => item._id === department);

      if (include?.length > 0) {
        filteredGroups.push(group);
      }
    }
  });

  const queryClient = useQueryClient();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const body = {
        name,
        icon,
        departments: bankingTypeDepartments,
        groups: bankingTypeGroups,
        note,
      };

      if (!create) {
        await updateRequest({
          context,
          id: bankingType._id,
          body,
        });
        await queryClient.invalidateQueries({
          queryKey: [bankingTypeQueryKey],
          type: "active",
        });
      } else {
        await createRequest({ context, body });
        await queryClient.invalidateQueries({
          queryKey: [bankingTypeQueryKey],
          type: "active",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleDepartmentChange(value) {
    setBankingTypeDepartments(value);
  }

  function handleGroupChange(value) {
    setBankingTypeGroups(value);
  }

  return (
    <MDBox component="form" onSubmit={handleSubmit} width={500}>
      <MDInput
        fullWidth
        id="name"
        label="Kiểu Giao Dịch"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <MultipleSelectChip
        data={departments}
        dataSelected={bankingTypeDepartments}
        onChange={handleDepartmentChange}
        required
      />
      <MultipleSelectChip
        title="Tổ"
        data={filteredGroups}
        dataSelected={bankingTypeGroups}
        onChange={handleGroupChange}
        // required
      />
      <QuillInputField initialValue={note} label="ghi chú" onChange={(value) => setNote(value)} />

      <MDButton
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        color={sidenavColor}
        sx={{ marginTop: "20px" }}
      >
        Tải Icon
        <MDInput
          type="file"
          sx={uploadStyle}
          onChange={(e) => {
            setIcon(e.target.files[0]);
          }}
        />
      </MDButton>
      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
