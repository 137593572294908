import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useRequest } from "context/RequestContext";
import React, { useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import FormSelect from "components/FormSelect";
import { useQueryClient } from "@tanstack/react-query";
import { memberQueryKey } from "../../hooks/useMember";
import { useUser } from "../../hooks/useUser";
import { useGroup } from "../../hooks/useGroup";
import Spinner from "../../components/Spinner/Spinner";
import { useParams } from "react-router-dom";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import QuillInputField from "../../components/QuillInputField";

const context = "member";
const inputStyle = {
  marginTop: "20px",
};

const avatarStyle = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
};

function UpdateForm({ member, create = false }) {
  const { groupId: groupIdParams } = useParams();

  const [group, setGroup] = useState(() => {
    if (member?.group) {
      return member.group;
    }
    return null;
  });

  const [title, setTitle] = useState(member?.title || 0);
  const [avatar, setAvatar] = useState("");
  const [name, setName] = useState(member?.name || "");
  const [note, setNote] = useState(member?.note || "");
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const { updateRequest, createRequest } = useRequest();
  const { data, isLoading: isLoadingUser } = useUser({
    getAll: true,
  });
  const { data: groupData, isLoading: isLoadingGroup } = useGroup();
  const [controller] = useMaterialUIController();

  useEffect(() => {
    if (groupIdParams) {
      const group = groupData?.find((group) => group._id === groupIdParams);
      setGroup(group);
    }
  }, [groupData]);
  const { sidenavColor } = controller;

  function handleChangeGroup(e) {
    setGroup(e.target.value);
  }

  function handleChangeTitle(e) {
    setTitle(Number(e.target.value));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const body = { name, avatar, group: group?._id, title, note };

      if (!create) {
        await updateRequest({ context, id: member._id, body });
        await queryClient.invalidateQueries({
          queryKey: [memberQueryKey],
        });
      } else {
        await createRequest({ context, body });
        await queryClient.invalidateQueries({
          queryKey: [memberQueryKey],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoadingUser || isLoadingGroup)
    return (
      <div>
        <Spinner />
      </div>
    );

  return (
    <MDBox
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: "400px",
      }}
    >
      {/*<Autocomplete*/}
      {/*  options={data?.data || []}*/}
      {/*  onChange={(event, newValue) => {*/}
      {/*    setUser(newValue);*/}
      {/*  }}*/}
      {/*  value={user}*/}
      {/*  isOptionEqualToValue={(option, value) => option === value}*/}
      {/*  getOptionLabel={(option) => option.fullName}*/}
      {/*  sx={inputStyle}*/}
      {/*  renderInput={(params) => (*/}
      {/*    <MDInput*/}
      {/*      {...params}*/}
      {/*      inputProps={{ ...params.inputProps, required: true }}*/}
      {/*      label="Người Dùng"*/}
      {/*    />*/}
      {/*  )}*/}
      {/*/>*/}
      <MDInput
        fullWidth
        id="name"
        label="Tên thành viên"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={inputStyle}
        required
      />
      <MDButton
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        color={sidenavColor}
        sx={{ marginTop: "20px" }}
      >
        Nhập Avatar
        <MDInput
          type="file"
          sx={avatarStyle}
          onChange={(e) => {
            setAvatar(e.target.files[0]);
          }}
        />
      </MDButton>
      <Autocomplete
        options={groupData || []}
        onChange={(event, newValue) => {
          setGroup(newValue);
        }}
        value={group}
        sx={inputStyle}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <MDInput {...params} inputProps={{ ...params.inputProps, required: true }} label="Tổ" />
        )}
      />

      <FormSelect
        title="Chức Vụ"
        data={[
          { _id: 0, name: "Member" },
          { _id: 1, name: "Manager" },
        ]}
        dataSelected={title}
        onChange={handleChangeTitle}
        sx={{
          width: "100%",
        }}
      />
      <QuillInputField initialValue={note} label="ghi chú" onChange={(value) => setNote(value)} />

      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
