import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";

import Modal from "components/Modal";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import Time from "components/Time";
import Chip from "@mui/material/Chip";
import DeleteForm from "components/DeleteForm";
import * as PropTypes from "prop-types";
import { useAction } from "../../hooks/useAction";
import { DisplayContent } from "../../libs/display-content";
import NestedList from "../../components/NestedList";
import { roleActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";

function ActionsCell({ actions }) {
  const { data, isLoading, isFetching } = useAction();

  if (isLoading || isFetching) return <></>;

  const mappedActions = actions.map((action) => {
    return data?.find((item) => item._id === action);
  });
  return (
    <>
      {mappedActions.map((action, index) => (
        <Chip
          key={index}
          variant="outlined"
          label={action?.viName || action?.name || "Action Name"}
          sx={{ color: "text.main", marginLeft: "5px" }}
        />
      ))}
    </>
  );
}

ActionsCell.propTypes = {
  actions: PropTypes.any,
};

function getRows({ role, filters = {} }) {
  try {
    let rows = role || [];
    // filter by name
    if (filters.name) {
      rows = rows.filter((row) => row.name.toLowerCase().includes(filters.name.toLowerCase()));
    }
    rows = rows.map((role) => {
      const {
        name,
        actions,
        createdBy,
        createdTime,
        updatedTime,
        updatedBy,
        default: defaultRole,
      } = role;

      return {
        name: <StyledNameComponent name={name} />,
        actions: (
          <NestedList title="Quyền">
            <MDBox sx={{ minWidth: 200, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {name === "SUPERADMIN" ? (
                <Chip
                  key="SUPERADMIN"
                  variant="outlined"
                  label="Tất Cả"
                  sx={{ color: "text.main", marginLeft: "5px" }}
                />
              ) : (
                actions && actions.length > 0 && <ActionsCell actions={actions} />
              )}
            </MDBox>
          </NestedList>
        ),
        createdBy: createdBy && (
          <StyledNameComponent img={createdBy.avatar} name={createdBy.fullName} />
        ),
        createdTime: createdTime && <Time time={createdTime} />,
        updatedBy: updatedBy && (
          <StyledNameComponent img={updatedBy.avatar} name={updatedBy.fullName} />
        ),
        updatedTime: updatedTime && <Time time={updatedTime} />,
        default: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={defaultRole ? "true" : "false"}
              color={defaultRole ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={roleActions.updateRole}>
              <Modal>
                <UpdateForm role={role} />
              </Modal>
            </Can>
            <Can permissions={roleActions.deleteRole}>
              <Modal action="delete">
                <DeleteForm context="role" id={role._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });
    return rows;
  } catch (err) {
    console.log(err);
  }
}

function getColumns() {
  return [
    { Header: "vai trò", accessor: "name", width: "20%", align: "left" },
    { Header: "hành động", accessor: "actions", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "tạo lúc", accessor: "createdTime", align: "center" },
    { Header: "cập nhật bởi", accessor: "updatedBy", align: "center" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },
    { Header: "mặc định", accessor: "default", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
