/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";
import Users from "layouts/users/Users";
import MemberOfDepartmentTable, { DepartmentsTable } from "layouts/departments/DepartmentsTable";
import RolesTable from "layouts/roles/RolesTable";
import GroupsTable from "layouts/groups/GroupsTable";
import MembersTable from "layouts/members/MembersTable";
import PunishmentsTable from "layouts/punishments/PunishmentsTable";
import BanksTable from "layouts/bank/BanksTable";
import BankingsTable from "layouts/banking/BankingsTable";
import BankingTypesTable from "layouts/banking-type/BankingTypesTable";
import CardsTable from "layouts/card/CardsTable";
import CardCategoriesTable from "layouts/card-category/CardCategoriesTable";
import ActionsTable from "./layouts/action/ActionsTable";
import ActionGroupTable from "./layouts/action-group/ActionGroupTable";
import NotificationsList from "layouts/notification/NotificationsList";
import NotificationsTable from "layouts/notification/NotificationsTable";
import AdsAccount from "layouts/ads-account/AdsAccount";
import Request from "layouts/request/Request";
import Sim from "layouts/sims/SimsTable";
import CardCheck from "layouts/card-check/CardCheckTable";
import {
  actionActions,
  actionGroupActions,
  adsAccountActions,
  bankActions,
  bankingActions,
  bankingTypeActions,
  cardActions,
  cardCategoryActions,
  cardCheckActions,
  departmentActions,
  groupActions,
  memberActions,
  notificationActions,
  punishmentActions,
  requestActions,
  roleActions,
  simActions,
  userActions,
} from "./constants/actions";
import Page404 from "./layouts/errors/Page404";

const routes = [
  {
    type: "title",
    key: "title-noti",
    title: "Thông Báo",
  },
  {
    type: "collapse",
    name: "Thông Báo",
    key: "notification",
    icon: <Icon fontSize="small">inbox</Icon>,
    route: "/notification",
    component: <NotificationsList />,
  },
  // {
  //   type: "collapse",
  //   name: "Quản Lý Thông Báo",
  //   key: "notification-table",
  //   icon: <Icon fontSize="small">inbox</Icon>,
  //   route: "/notification-table",
  //   component: <NotificationsTable />,
  // },
  {
    type: "divider",
    key: "divider-user",
  },
  {
    type: "title",
    key: "title-user",
    title: "Quản Lý Người Dùng",
  },
  {
    type: "collapse",
    name: "Người dùng",
    key: "users",
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: "/users",
    component: <Users />,
    permissions: Object.values(userActions),
  },
  {
    type: "collapse",
    name: "Thành Viên",
    key: "member",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/member",
    component: <MembersTable />,
    permissions: Object.values(memberActions),
  },
  {
    type: "collapse",
    name: "Tổ",
    key: "group",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/group",
    component: <GroupsTable />,
    permissions: Object.values(groupActions),
  },
  {
    type: "collapse",
    name: "Danh sách bộ phận",
    key: "list-group",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/list-group",
    component: <DepartmentsTable />,
    permissions: Object.values(departmentActions),
  },
  // {
  //   type: "collapse",
  //   name: "Thành viên bộ phận",
  //   key: "department",
  //   icon: <Icon fontSize="small">groups</Icon>,
  //   route: "/department/:departmentId/:groupId",
  //   dynamic: true,
  //   component: <MemberOfDepartmentTable />,
  //   permissions: Object.values(memberActions),
  //   // TODO : render dynamic with api
  //   // children: [
  //   //   {
  //   //     type: "collapse",
  //   //     name: "Nhân sự",
  //   //     key: "hr",
  //   //     parentKey: "department",
  //   //     icon: <Icon fontSize="small">people</Icon>,
  //   //     route: "/department/12",
  //   //     component: <MemberOfDepartmentTable />,
  //   //     permissions: Object.values(departmentActions),
  //   //     children: [
  //   //       {
  //   //         type: "collapse",
  //   //         name: "Nhân sự 0.1",
  //   //         key: "hr0.1",
  //   //         parentKey: "hr",
  //   //         icon: <Icon fontSize="small">people</Icon>,
  //   //         route: "/department/12/12",
  //   //         component: <MemberOfDepartmentTable />,
  //   //       },
  //   //     ],
  //   //   },
  //   //   {
  //   //     type: "collapse",
  //   //     name: "Nhân sự1",
  //   //     key: "hr1",
  //   //     icon: <Icon fontSize="small">people</Icon>,
  //   //     route: "/department/123/12",
  //   //     component: <MemberOfDepartmentTable />,
  //   //     permissions: Object.values(departmentActions),
  //   //   },
  //   // ],
  // },
  {
    type: "collapse",
    name: "Đơn phạt",
    key: "punishment",
    icon: <Icon fontSize="small">assignment_late</Icon>,
    route: "/punishment",
    component: <PunishmentsTable />,
    permissions: Object.values(punishmentActions),
  },
  {
    type: "collapse",
    name: "Vai trò",
    key: "role",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/role",
    component: <RolesTable />,
    permissions: Object.values(roleActions),
  },
  {
    type: "divider",
    key: "divider-PO",
  },
  {
    type: "title",
    key: "title-PO",
    title: "Quản Lý Chi Tiêu",
  },
  {
    type: "collapse",
    name: "Ngân hàng",
    key: "bank",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/bank",
    component: <BanksTable />,
    permissions: Object.values(bankActions),
  },
  {
    type: "collapse",
    name: "Giao Dịch",
    key: "banking",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/banking",
    component: <BankingsTable />,
    permissions: Object.values(bankingActions),
  },
  {
    type: "collapse",
    name: "Kiểu Giao Dịch",
    key: "banking-type",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/banking-type",
    component: <BankingTypesTable />,
    permissions: Object.values(bankingTypeActions),
  },
  {
    type: "collapse",
    name: "Thẻ",
    key: "card",
    icon: <Icon fontSize="small">credit_card</Icon>,
    route: "/card",
    component: <CardsTable />,
    permissions: Object.values(cardActions),
  },
  {
    type: "collapse",
    name: "Loại Thẻ",
    key: "card-category",
    icon: <Icon fontSize="small">style</Icon>,
    route: "/card-category",
    component: <CardCategoriesTable />,
    permissions: Object.values(cardCategoryActions),
  },
  {
    type: "collapse",
    name: "Tài Khoản Ads",
    key: "ads-account",
    icon: <Icon fontSize="small">photo_camera_front</Icon>,
    route: "/ads-account",
    component: <AdsAccount />,
    permissions: Object.values(adsAccountActions),
  },
  {
    type: "collapse",
    name: "Đề Xuất / Yêu Cầu",
    key: "request",
    icon: <Icon fontSize="small">request_page</Icon>,
    route: "/request",
    component: <Request />,
    permissions: Object.values(requestActions),
    meta: {
      badge: 1,
    },
  },
  {
    type: "collapse",
    name: "Quản lý Sim",
    key: "sim",
    icon: <Icon fontSize="small">request_page</Icon>,
    route: "/sim",
    component: <Sim />,
    permissions: Object.values(simActions),
  },
  {
    type: "collapse",
    name: "Kiểm Tra Thẻ",
    key: "card-check",
    icon: <Icon fontSize="small">request_page</Icon>,
    route: "/card-check",
    component: <CardCheck />,
    permissions: Object.values(cardCheckActions),
  },
  {
    type: "divider",
    key: "divider-action",
  },
  {
    type: "title",
    key: "title-action",
    title: "Quản Lý Hành Động",
  },
  {
    type: "collapse",
    name: "Hành Động",
    key: "action",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/action",
    component: <ActionsTable />,
    permissions: Object.values(actionActions),
  },
  {
    type: "collapse",
    name: "Nhóm Hành Động",
    key: "action-group",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/action-group",
    component: <ActionGroupTable />,
    permissions: Object.values(actionGroupActions),
  },
];

export default routes;
