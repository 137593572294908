import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/sims/simsData";
import useCanAccess from "./useCanAccess";
import { simActions } from "../constants/actions";
import { DEFAULT_SORT } from "../utils/constants";
export const simQueryKey = "sim";

export const useSim = ({ isDeleted = false, sort = DEFAULT_SORT } = {}) => {
  const { makeRequest } = useRequest();
  const [filters, setFilters] = useState({
    searchValue: "",
    rangeDate: [null, null],
    groups: [],
    departments: [],
  });
  const [rangeDate, setRangeDate] = useState([null, null]);
  const { can } = useCanAccess();

  const { data, isLoading, error } = useQuery({
    queryKey: [simQueryKey, { isDeleted, sort }],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${simQueryKey}/getAll?isDeleted=${isDeleted}&sort=${sort}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled: can(simActions.getAllSims),
    placeholderData: (previousData, previousQuery) => previousData,
  });

  const rows = useMemo(() => {
    try {
      const sim = data;

      return getRows({ sim, filters });
    } catch (e) {
      return [];
    }
  }, [data, filters, rangeDate]);
  const handleSearchChange = (value) => {
    setFilters({ ...filters, searchValue: value });
  };

  const handleDateChange = (value) => {
    setFilters({ ...filters, rangeDate: value });
  };

  const handleGroupChange = (value) => {
    setFilters({ ...filters, groups: value });
  };

  const handleDepartmentChange = (value) => {
    setFilters({ ...filters, departments: value });
  };

  return {
    data,
    rows,
    isLoading,
    error,
    handleSearchChange,
    handleDateChange,
    handleGroupChange,
    handleDepartmentChange,
  };
};
