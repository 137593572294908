import { memo, useMemo } from "react";
import { getColumns } from "./membersData";
import TableLayout from "layouts/TableLayout";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { memberQueryKey, useMember } from "../../hooks/useMember";

import MemberSearch from "./MemberSearch";
import { Can } from "../../hooks/useCanAccess";
import { groupActions, memberActions, userActions } from "../../constants/actions";
import { useParams, useSearchParams } from "react-router-dom";
import { useGroup } from "../../hooks/useGroup";
import { DEFAULT_SORT } from "../../utils/constants";

function MembersTable() {
  const [searchParams] = useSearchParams();
  const { rows, isLoading, error, handleChangeGroup, handleMemberChange, data } = useMember({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
    sort: searchParams.get("sort") || DEFAULT_SORT,
  });
  const { departmentId, groupId } = useParams();
  let title = "Thành Viên";
  const { data: groupData } = useGroup({
    enabled: !!groupId,
  });

  if (groupId && groupData) {
    const group = groupData.find((g) => g._id === groupId);
    title = `Thành Viên Tổ ${group?.name}` || " Thành Viên";
  }

  const handleChange = (value, type) => {
    if (type === "group") {
      handleChangeGroup(value);
    }
    if (type === "member") {
      handleMemberChange(value);
    }
  };

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title={title}
      canCreate={
        <Can
          permissions={[
            memberActions.insertMember,
            userActions.getPagingUsers,
            groupActions.getAllGroups,
          ]}
          fallback={
            "Bạn không có quyền thêm thành viên hoặc không có quyền xem danh sách thành viên và danh sách nhóm"
          }
        >
          <UpdateForm create={true} />
        </Can>
      }
      canSearch={false}
      canExport={memberQueryKey}
      extraSearch={!groupId && <MemberSearch onChange={handleChange} members={data} />}
      refreshButton={true}
      compact={true}
    />
  );
}

export default memo(MembersTable);
