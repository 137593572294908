import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/members/membersData";
import { isArray } from "lodash";
import { useParams } from "react-router-dom";
import useCanAccess from "./useCanAccess";
import { memberActions } from "../constants/actions";
import { DEFAULT_SORT } from "../utils/constants";

export const memberQueryKey = "member";

export const useMember = ({ isDeleted = false, sort = DEFAULT_SORT } = {}) => {
  const { makeRequest, dispatchError } = useRequest();
  const [searchValue, setSearchValue] = useState("");
  const { departmentId, groupId } = useParams();
  const [groupValue, setGroupValue] = useState(groupId || "");
  const [memberValue, setMemberValue] = useState("");
  const { can } = useCanAccess();

  const { data, isLoading, error } = useQuery({
    queryKey: [memberQueryKey, { isDeleted, sort }],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${memberQueryKey}/getAll?isDeleted=${isDeleted}&sort=${sort}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled: can(memberActions.getAllMembers),
    placeholderData: (previousData, previousQuery) => {
      return previousData || [];
    },
  });
  const rows = useMemo(() => {
    try {
      const filters = {
        name: searchValue,
        group: groupValue,
        member: memberValue,
      };
      const member = data;

      return getRows({ member, filters });
    } catch (e) {
      return [];
    }
  }, [data, searchValue, groupValue, memberValue]);
  const handleSearchChange = (value) => {
    if (isArray(value)) {
      value = value[0];
    }
    setSearchValue(value);
  };

  const handleChangeGroup = (value) => {
    value = isArray(value) ? value[0] : value;
    setGroupValue(value);
  };

  const handleMemberChange = (value) => {
    value = isArray(value) ? value[0] : value;
    setMemberValue(value);
  };

  return {
    rows,
    isLoading,
    error,
    handleSearchChange,
    handleChangeGroup,
    data,
    handleMemberChange,
  };
};
