import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import { useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

const { createContext, useReducer, useContext, useEffect } = require("react");

const AuthContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
  error: null,
  isLoading: false,
  authToken: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "login":
      return {
        ...state,
        user: action.payload.data,
        isAuthenticated: true,
        error: null,
        isLoading: true,
        authToken: action.payload.data?.token || action.payload.token,
      };
    case "logout":
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        error: null,
        isLoading: false,
        authToken: "",
      };
    case "loggedIn":
      return { ...state, user: action.payload, isLoading: true, isAuthenticated: true };
    case "error":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isAuthenticated: false,
        authToken: "",
      };
    case "updatePassword":
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case "updatePasswordError":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      throw new Error("Invalid action.type: " + action.type);
  }
}

function AuthProvider({ children }) {
  const [isMounted, setIsMounted] = useState(false);
  const [{ isAuthenticated, user, isLoading, error, authToken }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const queryClient = useQueryClient();

  async function login(username, password) {
    try {
      dispatch({ type: "loading" });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      };
      const res = await fetch(process.env.REACT_APP_API_URL + "/user/login", requestOptions);
      const data = await res.json();

      if (String(res.status).startsWith(4)) {
        throw new Error(data.message);
      }

      dispatch({ type: "login", payload: data });
    } catch (err) {
      dispatch({ type: "error", payload: err.message });
    }
  }

  function logout() {
    try {
      dispatch({ type: "loading" });

      dispatch({ type: "logout" });

      localStorage.removeItem("authToken");
      localStorage.removeItem("_id");
      queryClient.clear();
    } catch (err) {
      dispatch({ type: "error", payload: err.message });
    }
  }

  async function updatePassword({ oldPassword, newPassword }) {
    try {
      dispatch({ type: "loading" });
      const authToken = localStorage.getItem("authToken");

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      };
      const result = await fetch(
        process.env.REACT_APP_API_URL + "/user/updatePassword",
        requestOptions
      );
      const data = await result.json();
      if (String(result.status).startsWith(4)) {
        throw new Error(data.message);
      }
      return data;
    } catch (err) {
      dispatch({ type: "updatePasswordError", payload: err.message });
      return {
        error: err.message,
      };
    }
  }

  useEffect(
    function () {
      if (user && !localStorage.getItem("authToken")) {
        localStorage.setItem("authToken", user?.token);
        localStorage.setItem("_id", user?.user?._id);
      }
    },
    [user]
  );

  useEffect(function () {
    async function checkExistingToken() {
      try {
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
          dispatch({ type: "loading" });
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
            },
          };
          const res = await fetch(
            process.env.REACT_APP_API_URL + "/user/getByToken",
            requestOptions
          );
          const data = await res.json();

          if (data) {
            if (String(data.status).startsWith(4)) {
              throw new Error(data.message);
            }

            dispatch({ type: "loggedIn", payload: data });
          } else {
            logout();
          }
        }
      } catch (err) {
        logout();
        dispatch({ type: "error", payload: err.message });
      } finally {
        setIsMounted(true);
      }
    }
    checkExistingToken();
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated,
      user,
      isLoading,
      error,
      login,
      logout,
      authToken,
      updatePassword,
    }),
    [isAuthenticated, user, isLoading, error, login, logout, authToken, updatePassword]
  );

  if (!isMounted) {
    return <SpinnerFullPage />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) throw new Error("AuthContext is used outside of AuthProvider");
  return context;
}

export { AuthProvider, useAuth };
