import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import Chip from "@mui/material/Chip";
import DeleteForm from "components/DeleteForm";
import { groupActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import { DisplayContent, getTextFromHtmlTag } from "../../libs/display-content";
import NestedList from "../../components/NestedList";
import { displayShortContent } from "../banking/bankingsData";
import ModalPreviewContent from "../../components/ModalPreviewContent";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

function getRows({ group, filters = {} }) {
  try {
    const filterKeys = Object.keys(filters);
    let rows = group;
    // filter by name
    if (filterKeys.includes("name") && filters.name !== "") {
      rows = rows.filter((row) => row.name.toLowerCase().includes(filters.name.toLowerCase()));
    }
    // filter by department
    if (filterKeys.includes("department") && filters.department !== "") {
      rows = rows.filter((row) =>
        row.departments.some((department) => department._id === filters.department)
      );
    }
    // filter by group
    if (filters?.group) {
      rows = rows.filter((row) => {
        return row._id === filters.group;
      });
    }

    rows = rows.map((group) => {
      const {
        name,
        icon,
        isDeleted,
        departments,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
        note,
      } = group;

      return {
        group: <StyledNameComponent img={ASSET_URL + icon} name={name} />,
        isDeleted: (
          <MDBadge
            badgeContent={String(isDeleted)}
            color={isDeleted ? "error" : "success"}
            variant="gradient"
            size="sm"
          />
        ),

        departments: (
          <MDBox sx={{ width: 300, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {departments.map((department, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={department.name}
                sx={{ color: "text.main", m: "5px" }}
              />
            ))}
          </MDBox>
        ),
        note: <ModalPreviewContent content={note} />,

        createdTime: createdTime ? <Time time={createdTime} /> : null,
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={createdBy.fullName} />
        ) : null,
        updatedTime: updatedTime ? <Time time={updatedTime} /> : null,
        updatedBy: updatedBy ? (
          <StyledNameComponent img={ASSET_URL + updatedBy.avatar} name={updatedBy.fullName} />
        ) : null,
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={groupActions.updateGroup}>
              <Modal>
                <UpdateForm group={group} />
              </Modal>
            </Can>
            <Can permissions={groupActions.deleteGroup}>
              <Modal action="delete">
                <DeleteForm context="group" id={group._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "tổ", accessor: "group", width: "25%", align: "left" },
    { Header: "trạng thái xoá", accessor: "isDeleted", align: "center" },
    { Header: "bộ phận", accessor: "departments", align: "center" },
    { Header: "ghi chú", accessor: "note", align: "left" },

    { Header: "tạo lúc", accessor: "createdTime", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },
    { Header: "cập nhật bởi", accessor: "updatedBy", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
