import Papa from "papaparse";
import { isArray, isObject } from "lodash";

const toStringArrayCSV = (items) => {
  const array = [];
  items.forEach((item) => {
    // remove _id field
    const flattenItem = {};
    Object.keys(item).forEach((key) => {
      delete item._id;
      if (isObject(item[key])) {
        // delete item[key]._id;
        flattenItem[key] = item[key]._id;
      } else {
        flattenItem[key] = item[key];
      }
    });
    array.push(flattenItem);
  });
  return array;
};

const HeaderVietnamese = {
  _id: "ID",
  createdTime: "Thời gian tạo",
  createdBy: "Người tạo",
  isDeleted: "Đã xóa",
  moneyType: "Loại tiền",
  group: "Tổ",
  title: "Chức danh",
  departments: "Bộ phận",
  department: "Bộ phận",
  username: "Tên người dùng",
  email: "Email",
  role: "Vai trò",
  roles: "Vai trò",
  fullName: "Họ và tên",
  status: "Trạng thái",
  members: "Thành viên",
  member: "Thành viên",
  punishedTime: "Thời gian phạt",
  content: "Nội dung(ghi chú)",
  note: "Ghi chú",
  currencyRate: "Tỉ giá",
  name: "Tên",
  accountNumber: "Số tài khoản",
  bank: "Ngân hàng",
  account: "Tài khoản",
  expiryDate: "Ngày hết hạn",
  cardCategories: "Danh mục thẻ",
  managers: "Người quản lý",
  accountID: "ID tài khoản",
  cards: "Thẻ",
  country: "Múi giờ",
  condition: "Tình trạng",
  accountNumber1: "Số thẻ 1",
  accountNumber2: "Số thẻ 2",
  groups: "Tổ",
  simType: "Loại sim",
  phoneNumber: "Số điện thoại",
  balance: "Số dư",
  isHighlight: "Nổi bật",
  card: "Thẻ",
  availableBalance: "Số dư khả dụng",
  checkingTime: "Thời gian kiểm tra",
  actualBalance: "Số dư thực tế",
  shift: "Ca",
  moneyVND: "Số tiền(VND)",
};

const transformHeader = (headers) => {
  // convert headers to vietnamese
  const newHeaders = {};
  Object.keys(headers).forEach((key) => {
    newHeaders[key] = HeaderVietnamese[key] || headers[key];
  });
  return newHeaders;
};
export const exportCSVFile = (headers, items, fileTitle, callback) => {
  if (isArray(items)) {
    headers = transformHeader(headers);
    items.unshift(headers);
  }
  // convert items which contain nested objects into array
  items = toStringArrayCSV(items);
  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  // Convert JSON to CSV using Papaparse
  const csv = Papa.unparse(jsonObject, {
    header: false,
  });

  // Create a Blob with CSV data
  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  var link = document.createElement("a");

  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileTitle + ".csv");
    link.style.visibility = "hidden";
    link.addEventListener("click", () => {
      callback && callback();
    });
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
