import React from "react";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";
import RangeDatePicker from "../../components/RangeDatePicker";
import pxToRem from "../../assets/theme/functions/pxToRem";
import MDButton from "../../components/MDButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CardCheckShift } from "../../constants/cardCheckShift";
import { useGroup } from "../../hooks/useGroup";
import { useDepartment } from "../../hooks/useDepartments";

const initialSearchValue = {
  shift: 2, // 2 is all
  dateRange: [null, null],
  groups: [],
  departments: [],
};
const CardCheckAdvanceSearch = ({ onSearch }) => {
  const [searchValue, setSearchValue] = React.useState(initialSearchValue);
  const { data: groupData } = useGroup();
  const { data: departmentData } = useDepartment();
  const handleClear = () => {
    setSearchValue(initialSearchValue);
  };
  return (
    <Grid container spacing={2}>
      <Grid item display="flex" alignItems="center" gap="10px" flexWrap="wrap" xs={12}>
        <Grid container spacing={2}>
          {/*Đầu ca , cuối ca*/}
          <Grid item xs={3}>
            {/* Đầu ca, cuối ca  */}
            <FormControl
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  padding: "0.625rem",
                  fontSize: "0.625rem",
                },
              }}
              size="small"
              variant="outlined"
            >
              <InputLabel id="data-select-label" sx={{ left: "0.5" }} size="small">
                Ca
              </InputLabel>
              <Select
                value={searchValue?.shift !== undefined ? searchValue?.shift : 2}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchValue({ ...searchValue, shift: value });
                }}
                label="Ca"
                sx={{ padding: "15px" }}
                size="small"
              >
                <MenuItem key="all" value={2}>
                  Tất cả
                </MenuItem>
                <MenuItem key={CardCheckShift.startShift} value={CardCheckShift.startShift}>
                  Đầu ca
                </MenuItem>
                <MenuItem key={CardCheckShift.endShift} value={CardCheckShift.endShift}>
                  Cuối ca
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <RangeDatePicker
              label="Thời gian"
              selected={searchValue.dateRange}
              onChange={(dates) => {
                setSearchValue({ ...searchValue, dateRange: dates });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              sx={{
                "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                  paddingTop: "8px",
                  paddingBottom: "7px",
                },
              }}
              multiple
              value={searchValue.groups}
              onChange={(e, value) => {
                setSearchValue({ ...searchValue, groups: value });
              }}
              renderInput={(params) => <MDInput {...params} label="Tổ" size="small" />}
              getOptionLabel={(option) => option?.name}
              options={groupData || []}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              sx={{
                "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                  paddingTop: "8px",
                  paddingBottom: "7px",
                },
              }}
              multiple
              value={searchValue.departments}
              onChange={(e, value) => {
                setSearchValue({ ...searchValue, departments: value });
              }}
              renderInput={(params) => <MDInput {...params} label="Bộ phận" size="small" />}
              getOptionLabel={(option) => option?.name}
              options={departmentData || []}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          border="1px solid"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          paddingInline="10px"
          paddingBlock="5px"
          marginTop="10px"
        >
          <MDButton
            variant="outlined"
            color="success"
            onClick={() => {
              onSearch &&
                onSearch({
                  ...searchValue,
                  groups: searchValue.groups.map((group) => group?._id),
                  departments: searchValue.departments.map((department) => department?._id),
                });
            }}
            sx={{ marginRight: pxToRem(10) }}
          >
            Search
          </MDButton>
          <MDButton
            variant="outlined"
            color="error"
            onClick={() => {
              handleClear();
              onSearch && onSearch(initialSearchValue);
            }}
          >
            Clear
          </MDButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardCheckAdvanceSearch;
