import React, { useMemo, useState } from "react";

import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { useTable } from "react-table";
import Table from "@mui/material/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import { isEmpty } from "lodash/lang";
import { cloneDeep, isArray, isObject, isString } from "lodash";

import { useQueryClient } from "@tanstack/react-query";
import MDBox from "./MDBox";
import MDButton from "./MDButton";
import DataTableHeadCell from "../examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "../examples/Tables/DataTable/DataTableBodyCell";
import { useMaterialUIController } from "../context";
import { useModal } from "../context/ModalContext";
import MDInput from "./MDInput";
import { deepParseArray, deepRmField, parseCsvToJson } from "../libs/parse-csv";
import { deepRemoveUndefined } from "../utils/parse";
import { useRequest } from "../context/RequestContext";
import { method } from "../libs/fetchRequestUtils";

const fileStyle = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  whiteSpace: "nowrap",
};

const CsvTable = ({ columns: initColumns, data: initData, onDeleteRow, translateHeader = {} }) => {
  const handleDeleteRow = (row) => {
    const id = row?.original?.id;
    onDeleteRow && onDeleteRow(id);
  };
  const columns = React.useMemo(() => {
    return [
      {
        Header: "Index",
        accessor: "index",
        align: "center",
        width: 50,
        Cell: ({ row }) => {
          return (
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                color: "text.main",
              }}
            >
              {row?.index + 1}
            </MDBox>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        align: "center",
        width: 100,
        Cell: ({ row }) => {
          return (
            <MDBox display="flex" alignItems="center" justifyContent="center">
              <MDButton variant="text" color="error" onClick={() => handleDeleteRow(row)}>
                <DeleteIcon />
              </MDButton>
            </MDBox>
          );
        },
      },
      ...initColumns.map((column) => ({
        Header: translateHeader[column] || column,
        accessor: column,
        align: "center",
        width: 200,
      })),
    ];
  }, [initColumns]);
  const data = useMemo(() => {
    try {
      // debugger;
      const result = initData.map((item) => {
        const obj = {};
        Object.keys(item).map((key, index) => {
          if (isObject(item[key])) {
            obj[key] = <div key={`${key}-${index}`}> {JSON.stringify(item[key])} </div>;
          } else {
            obj[key] = item[key];
          }
        });
        return obj;
      });

      return result;
    } catch {
      return [];
    }
  }, [initData]);

  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = tableInstance;

  return (
    <TableContainer sx={{ boxShadow: "none", overflowY: "scroll", height: "500px" }}>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => (
                <DataTableHeadCell
                  compact={true}
                  key={idx}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody
          {...getTableBodyProps()}
          sx={{
            position: "relative",
          }}
        >
          {rows?.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell, idx) => (
                  <DataTableBodyCell
                    key={idx}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const translateColumns = (columns, translate) => {
  if (isArray(columns)) {
    return columns.map((column) => {
      if (isString(column)) {
        return translate[column] || column;
      }
    });
  }
};
const ImportModal = ({ exampleFile, queryKey, bodyPayload, translate = {} }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [controller] = useMaterialUIController(null);
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const { sidenavColor } = controller;
  const theme = useTheme();
  const { makeRequest, dispatchError, dispatch } = useRequest();
  const { emitClose } = useModal();
  const queryClient = useQueryClient();
  const handleDownloadExampleFile = (e) => {
    e.preventDefault();
    //   TODO: download sample csv file
    const link = document.createElement("a");
    link.href = exampleFile;
    link.download = `${bodyPayload}-example.csv`;
    link.click();
  };
  const handleFileChange = async (e) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      // validate file
      if (!file.name.match(/\.(csv)$/)) {
        alert("Please upload valid file format(csv)");
        return;
      } else {
        setFile(file);
      }
      const data = await parseCsvToJson(file);
      const deepValue = deepParseArray(data?.data) || [];

      // embed id for each record
      const records = deepValue.map((record, index) => {
        return {
          ...record,
          id: index,
        };
      });

      setRecords(records);
      setColumns(data?.meta.fields);
    } catch (e) {
      dispatchError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id) => {
    setRecords((prev) => {
      const clone = cloneDeep(prev);
      return clone.filter((item) => item.id !== id);
    });
  };

  const handleImport = async () => {
    try {
      setLoading(true);
      //   remove id field
      const newData = records?.map((item) => {
        const { id, ...rest } = item;
        return rest;
      });

      //   convert records to json which for insertMany in mongodb
      const json = deepRmField(newData, "_id");
      // remove undefined field
      const result = deepRemoveUndefined(json);

      const request = {
        method: method.post,
        endpoint: `/${queryKey}/insertMany`,
        body: {
          [bodyPayload]: result,
        },
      };
      const res = await makeRequest(request);
      dispatch({
        type: `${queryKey}/createMany`,
        payload: res,
      });

      emitClose && emitClose();
      queryClient.invalidateQueries({
        type: "active",
      });
    } catch (e) {
      dispatchError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MDBox
      sx={{
        width: 1200,
        height: "100%",
        maxHeight: "80vh",
      }}
    >
      <Typography color={"primary"}>
        <a
          href=""
          style={{
            color: theme?.palette?.white?.main,
          }}
          onClick={handleDownloadExampleFile}
        >
          Nhấn vào đây để tải file mẫu
        </a>
      </Typography>
      <MDButton
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        color={sidenavColor}
        sx={{ marginTop: "20px" }}
      >
        Nhập File (CSV, XLSX)
        <MDInput type="file" sx={fileStyle} onChange={handleFileChange} />
      </MDButton>
      <MDBox>
        {/*RENDER TABLE CSV*/}
        {file && (
          <MDBox>
            <Typography color={"primary"}>File Name: {file.name}</Typography>
          </MDBox>
        )}
      </MDBox>
      <MDBox>
        {/*RENDER TABLE CSV*/}
        {!isEmpty(records) && !isEmpty(columns) && (
          <>
            <CsvTable
              data={records}
              columns={columns}
              onDeleteRow={handleDelete}
              translateHeader={translate}
            />

            <MDButton
              block={true}
              sx={{
                width: "100%",
                marginTop: "20px",
              }}
              onClick={handleImport}
              disabled={loading}
            >
              {loading ? "Đang Nhập" : "Nhập"}
            </MDButton>
          </>
        )}
      </MDBox>
    </MDBox>
  );
};

export default ImportModal;
