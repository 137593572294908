import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useRequest } from "context/RequestContext";
import React, { useState } from "react";
import { useMaterialUIController } from "context";
import { useQueryClient } from "@tanstack/react-query";
import { cardCategoryQueryKey } from "../../hooks/useCardCategory";
import QuillInputField from "../../components/QuillInputField";

const uploadStyle = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
};

const context = "cardCategory";

function UpdateForm({ cardCategory, create = false }) {
  const [name, setName] = useState(cardCategory?.name || "");
  const [icon, setIcon] = useState("");
  const queryClient = useQueryClient();
  const [note, setNote] = useState(cardCategory?.note || "");
  const [isLoading, setIsLoading] = useState(false);

  const { createRequest, updateRequest } = useRequest();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const body = { name, icon, note };

      if (!create) {
        await updateRequest({ context, id: cardCategory._id, body });
        queryClient.invalidateQueries({
          queryKey: [cardCategoryQueryKey],
        });
      } else {
        await createRequest({ context, body });
        queryClient.invalidateQueries({
          queryKey: [cardCategoryQueryKey],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <MDBox component="form" onSubmit={handleSubmit} width={500}>
      <MDInput
        fullWidth
        id="name"
        label="Loại Thẻ"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <QuillInputField initialValue={note} label="ghi chú" onChange={(value) => setNote(value)} />

      <MDButton
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        color={sidenavColor}
        sx={{ marginTop: "20px" }}
      >
        Nhập Icon
        <MDInput
          type="file"
          sx={uploadStyle}
          onChange={(e) => {
            setIcon(e.target.files[0]);
          }}
        />
      </MDButton>
      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
