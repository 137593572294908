import React, { useEffect, useState } from "react";
import { useCard } from "../../hooks/useCard";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import { NumericFormat } from "react-number-format";
import {
  generateNumberListGreaterThanThreshold,
  isValidDate,
  separateThousands,
} from "../../utils/helpers";
import Chip from "@mui/material/Chip";
import MDBox from "../../components/MDBox";
import { useMaterialUIController } from "../../context";
import FormSelect from "../../components/FormSelect";
import moneyTypeUtils from "../../utils/moneyTypeUtils";
import MDTypography from "../../components/MDTypography";
import useExchangeRate from "../../hooks/useExchangeRate";
import { useQueryClient } from "@tanstack/react-query";
import DatePicker from "../../components/DatePicker";
import { useRequest } from "../../context/RequestContext";
import { useMember } from "../../hooks/useMember";
import { useBakingType } from "../../hooks/useBankingType";

const inputStyle = {
  marginTop: "20px",
};
const BankingQuickUpdateForm = ({ data, field }) => {
  const [fromCard, setFromCard] = useState(data?.fromCard || null);
  const [toCard, setToCard] = useState(data?.toCard || null);
  const [amountOfMoney, setAmountOfMoney] = useState(data?.amountOfMoney || "");
  const [moneyList, setMoneyList] = useState([]);
  const [moneyType, setMoneyType] = useState(data?.moneyType || 0);
  const [controller] = useMaterialUIController();
  const [bankingTime, setBankingTime] = useState(
    isValidDate(data?.bankingTime) ? new Date(data?.bankingTime) : new Date()
  );
  const [company, setCompany] = useState(data?.company || "");
  const [bankingType, setBankingType] = useState(data?.bankingType || null);
  const [members, setMembers] = useState(data?.members || []);

  const { sidenavColor } = controller;
  const { data: cardData } = useCard();
  const { data: memberData } = useMember();
  const { data: bankingTypeData } = useBakingType();
  const { vndRate } = useExchangeRate();
  const { updateRequest } = useRequest();

  const queryClient = useQueryClient();

  const [usdExchangeRate, setUsdExchangeRate] = useState(data?.usdExchangeRate || 1);
  useEffect(() => {
    if (data?.usdExchangeRate) return;

    setUsdExchangeRate(vndRate);
  }, [vndRate]);

  const handleSubmit = async () => {
    const body = {
      amountOfMoney: Number(amountOfMoney),
      moneyType: Number(moneyType),
      fromCard: fromCard?._id,
      toCard: toCard?._id,
      bankingType: bankingType?._id,
      bankingTime: new Date(bankingTime).toISOString(),
      content: data?.content || "",
      members: members?.map((member) => member._id),
      company,
      isHighlight: data?.isHighlight || false,
      usdExchangeRate,
      status: data?.status || 0,
    };
    console.log("body", body);
    const context = "banking";
    await updateRequest({ context, id: data?._id, body });
    queryClient.invalidateQueries({
      queryKey: [context],
      type: "active",
    });
  };
  const renderField = () => {
    let element = null;
    switch (field) {
      case "fromCard":
        element = (
          <Autocomplete
            value={fromCard}
            sx={{
              width: "100%",
            }}
            renderInput={(params) => (
              <MDInput
                {...params}
                label="Thẻ Chuyển"
                helperText={fromCard?.isDeleted ? "Thẻ đã bị xóa" : ""}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option?._id} className="MuiAutocomplete-option">
                  {option?.account} - {option?.accountNumber || ""}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            getOptionLabel={(option) => {
              return `${option?.account} - ${option?.accountNumber || ""}`;
            }}
            onChange={(e, newValue) => {
              setFromCard(newValue);
            }}
            options={cardData || []}
          />
        );
        break;
      case "toCard":
        element = (
          <Autocomplete
            value={toCard}
            sx={{
              width: "100%",
            }}
            renderInput={(params) => (
              <MDInput
                {...params}
                label="Thẻ Nhận"
                helperText={toCard?.isDeleted ? "Thẻ đã bị xóa" : ""}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option?._id} className="MuiAutocomplete-option">
                  {option?.account} - {option?.accountNumber || ""}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            getOptionLabel={(option) => {
              return `${option?.account} - ${option?.accountNumber || ""}`;
            }}
            onChange={(e, newValue) => {
              setToCard(newValue);
            }}
            options={cardData || []}
          />
        );
        break;
      case "amountOfMoney":
        element = (
          <>
            <FormSelect
              sx={inputStyle}
              title="Đơn Vị Tiền Tệ"
              data={moneyTypeUtils}
              dataSelected={moneyType}
              onChange={(e) => setMoneyType(Number(e.target.value))}
            />
            <NumericFormat
              fullWidth
              sx={inputStyle}
              id="amount-of-money"
              label="Khoản Chi Tiêu"
              variant="outlined"
              value={amountOfMoney}
              thousandSeparator
              customInput={MDInput}
              onChange={(e) => {
                const value = e.target.value.replaceAll(",", "");
                // const firstChar = Number(value.charAt(0));
                const list = generateNumberListGreaterThanThreshold(value, 5);
                setMoneyList(list);
                setAmountOfMoney(value);
              }}
              required={true}
            />
            <MDBox sx={inputStyle}>
              {moneyList.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    variant="contained"
                    color={sidenavColor}
                    sx={{ marginRight: 2, marginBottom: 2 }}
                    onClick={() => setAmountOfMoney(item)}
                    label={separateThousands(item)}
                  ></Chip>
                );
              })}
            </MDBox>
            {moneyType === 1 && (
              <>
                <NumericFormat
                  fullWidth
                  id="usdExchangeRate"
                  label="Tỷ giá USD"
                  variant="outlined"
                  value={usdExchangeRate}
                  thousandSeparator
                  customInput={MDInput}
                  sx={inputStyle}
                  onChange={(e) => {
                    const value = e.target.value.replaceAll(",", "");
                    setUsdExchangeRate(value);
                  }}
                  required={true}
                />
                {/*  show hint with vndRate  */}
                <MDBox sx={inputStyle}>
                  <MDTypography
                    variant="caption"
                    sx={{
                      color: "text.focus",
                    }}
                  >
                    Tỷ giá VND: {vndRate}
                  </MDTypography>
                </MDBox>
              </>
            )}
          </>
        );
        break;
      case "bankingTime":
        element = (
          <DatePicker
            selected={bankingTime}
            onChange={(date) => setBankingTime(new Date(date).toISOString())}
          />
        );
        break;
      case "members":
        element = (
          <Autocomplete
            value={members}
            multiple
            disableCloseOnSelect
            options={memberData || []}
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            renderOption={(props, option) => <li {...props}>{option?.name}</li>}
            onChange={(e, newValue) => {
              setMembers(newValue);
            }}
            renderInput={(params) => (
              <MDInput {...params} label="Thành Viên" placeholder="Chọn Thành Viên" />
            )}
          />
        );
        break;
      case "company":
        element = (
          <MDInput
            fullWidth
            id="company"
            label="Hậu đài"
            variant="outlined"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            sx={inputStyle}
            required
          />
        );
        break;
      case "bankingType":
        element = (
          <Autocomplete
            value={bankingType}
            sx={{
              width: "100%",
            }}
            renderInput={(params) => <MDInput {...params} label="Loại Giao Dịch" />}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option?._id} className="MuiAutocomplete-option">
                  {option?.name}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            getOptionLabel={(option) => {
              return `${option?.name}`;
            }}
            onChange={(e, newValue) => {
              setBankingType(newValue);
            }}
            options={bankingTypeData || []}
          />
        );
        break;
      default:
        break;
    }
    return (
      <>
        {element}
        {/*  submit button*/}
        <MDButton sx={{ mt: 2 }} onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </MDButton>
      </>
    );
  };

  return <>{renderField()}</>;
};

export default BankingQuickUpdateForm;
