import React, { Fragment, useEffect } from "react";
import MDBox from "../../components/MDBox";
import Modal from "@mui/material/Modal";
import BankingQuickUpdateForm from "./BankingQuickUpdateForm";
import { useModal } from "../../context/ModalContext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.default",
  boxShadow: 24,
  color: "text.main",

  p: 4,
};
const QuickUpdateModal = ({ children, data, field }) => {
  const [open, setOpen] = React.useState(false);
  const { openObserver } = useModal();
  useEffect(() => {
    //  close modal from children
    if (openObserver !== 1) {
      setOpen(false);
    }
  }, [openObserver]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div
        style={{
          display: "contents",
        }}
        onDoubleClick={handleOpen}
      >
        {children}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <MDBox sx={style}>
          <BankingQuickUpdateForm data={data} field={field} />
        </MDBox>
      </Modal>
    </>
  );
};

export default QuickUpdateModal;
