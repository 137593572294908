import { useQuery } from "@tanstack/react-query";
import { useRequest } from "context/RequestContext";
import { useParams } from "react-router-dom";

export default function useNotificationId() {
  const { makeRequest } = useRequest();
  const { id } = useParams("id");

  async function getDetailNotification() {
    try {
      const request = {
        method: "GET",
        endpoint: `/notification/getById/${id}`,
      };

      const res = await makeRequest(request);

      return res || [];
    } catch (err) {
      console.log(err);
    }
  }

  const { data: notificationDetail, isLoading } = useQuery({
    queryKey: ["notificationDetail", id],
    queryFn: getDetailNotification,
  });

  return { id, notificationDetail, isLoading };
}
