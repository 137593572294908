import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function FormSelect({
  title = "Form Select",
  data,
  dataSelected,
  onChange,
  ...rest
}) {
  return (
    <div>
      <FormControl
        sx={{ marginTop: "20px", width: "100%", ...rest?.sx }}
        required={rest.required || false}
      >
        <InputLabel id="data-select-label" sx={{ left: "0.5" }}>
          {title}
        </InputLabel>
        <Select
          labelId="data-select-label"
          id={`data-select-${title}`}
          value={dataSelected}
          onChange={onChange}
          label={title}
          sx={{ padding: "15px" }}
        >
          {data.map((data, index) => {
            if (data?.isDeleted) return;
            return (
              <MenuItem value={data._id} key={index}>
                {data?.name || data?.fullName || data?.cardName || data?.username || "Data"}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
