import { useRequest } from "context/RequestContext";
import React, { useState } from "react";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useQueryClient } from "@tanstack/react-query";
import { punishmentQueryKey } from "../../hooks/usePunishment";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import QuillInputField from "../../components/QuillInputField";
import { useMember } from "../../hooks/useMember";
import DatePicker from "../../components/DatePicker";

const textAreaStyle = {
  width: "320px",
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: 1.5,
  padding: "12px",
  borderRadius: "5px 5px 0 5px",
  marginTop: "20px",
};

const context = "punishment";

function UpdateForm({ punishment, create = false }) {
  const [member, setMember] = useState(punishment?.member || null);
  const [content, setContent] = useState(punishment?.content || "");
  const [punishedTime, setPunishedTime] = useState(
    new Date(punishment?.punishedTime || new Date())
  );
  const queryClient = useQueryClient();

  const { updateRequest, createRequest, makeRequest } = useRequest();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const { data: memberData = [], isLoading: isLoadingMember } = useMember();

  function handleChangeUser(e) {
    setMember(e.target.value);
  }

  function handleChangeContent(e) {
    setContent(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const body = { member: member?._id, content, punishedTime };

    if (!create) {
      await updateRequest({ context, id: punishment._id, body });
      queryClient.invalidateQueries({
        queryKey: [punishmentQueryKey],
      });
    } else {
      await createRequest({ context, body });
      queryClient.invalidateQueries({
        queryKey: [punishmentQueryKey],
      });
    }
  }

  return (
    <MDBox
      component="form"
      sx={{
        maxHeight: "80vh",
        overflowY: "auto",
      }}
      onSubmit={handleSubmit}
      width={600}
    >
      <Autocomplete
        required
        disablePortal
        options={memberData}
        defaultValue={member}
        value={member}
        sx={{
          width: "100%",
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            label="Thành viên"
            inputProps={{
              ...params.inputProps,
              required: true,
            }}
            required
          />
        )}
        getOptionLabel={(option) => {
          return `${option?.name}`;
        }}
        onChange={(e, newValue) => {
          setMember(newValue);
        }}
      />

      {/*Punished Time*/}
      <DatePicker
        label="Thời Gian Phạt"
        selected={punishedTime}
        onChange={(date) => setPunishedTime(date)}
      />

      <QuillInputField initialValue={content} onChange={(value) => setContent(value)} />

      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
