import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import { useCountry } from "../../hooks/useCountry";
import MDBox from "../../components/MDBox";
import { convertGmtOffsetToTime } from "../../utils/helpers";
import useAdsAccountCondition from "../../hooks/useAdsAccountCondition";
import { useMember } from "../../hooks/useMember";
import { useGroup } from "../../hooks/useGroup";
import { useCard } from "../../hooks/useCard";
import MDButton from "../../components/MDButton";
import { autocompleteStyles } from "../../assets/styles/autocomplete";
const initialState = {
  accountID: "",
  country: null,
  condition: null,
  member: null,
  group: null,
  card: null,
  accountNumber1: "",
  accountNumber2: "",
  via: "",
};
const AdsAccountAdvancedSearch = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState(initialState);
  const { data: countryData, isLoading: isLoadingCountry } = useCountry();
  const { data: conditionsData } = useAdsAccountCondition();
  const { data: memberData, isLoading: isLoadingMember } = useMember();
  const { data: groupData, isLoading: isLoadingGroup } = useGroup();
  const { data: cardData, isLoading: isLoadingCard } = useCard();

  const handleSearchChange = (value, type) => {
    setSearchValue((prevState) => {
      return {
        ...prevState,
        [type]: value,
      };
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item display="flex" alignItems="center" gap="10px" flexWrap="wrap" xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <MDInput
              size="small"
              label="ID tài khoản"
              value={searchValue.accountID}
              sx={{
                width: "100%",
              }}
              onChange={(e) => {
                handleSearchChange(e.target.value, "accountID");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              options={countryData || []}
              sx={autocompleteStyles}
              size="small"
              autoHighlight
              value={searchValue.country}
              renderOption={(props, option) => (
                <MDBox component="li" sx={{ "& > p": { mr: 2, flexShrink: 0 } }} {...props}>
                  <p>{option.flag}</p>
                  {`${option.name} - (${convertGmtOffsetToTime(option?.timeRegion)})`}
                </MDBox>
              )}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Múi giờ"
                  inputProps={{
                    ...params.inputProps,
                    required: true,
                  }}
                  required
                />
              )}
              getOptionLabel={(option) => {
                return `${option?.name}`;
              }}
              onChange={(e, newValue) => {
                handleSearchChange(newValue, "country");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              options={conditionsData || []}
              sx={autocompleteStyles}
              size="small"
              autoHighlight
              value={searchValue.condition}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Tình trạng"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              getOptionLabel={(option) => {
                return `${option?.title}`;
              }}
              onChange={(e, newValue) => {
                handleSearchChange(newValue, "condition");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              options={groupData || []}
              sx={autocompleteStyles}
              size="small"
              autoHighlight
              value={searchValue.group}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Tổ"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              getOptionLabel={(option) => {
                return `${option?.name}`;
              }}
              onChange={(e, newValue) => {
                handleSearchChange(newValue, "group");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              options={memberData || []}
              sx={autocompleteStyles}
              size="small"
              autoHighlight
              value={searchValue.member}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Thành viên"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              getOptionLabel={(option) => {
                return `${option?.name} - ${option?.group?.name}`;
              }}
              onChange={(e, newValue) => {
                handleSearchChange(newValue, "member");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              options={cardData || []}
              sx={autocompleteStyles}
              size="small"
              autoHighlight
              value={searchValue.card}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Thẻ"
                  // inputProps={{
                  //   ...params.inputProps,
                  // }}
                />
              )}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              getOptionLabel={(option) => {
                return `${option?.account}`;
              }}
              onChange={(e, newValue) => {
                handleSearchChange(newValue, "card");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <MDInput
              size="small"
              label="Số thẻ 1"
              value={searchValue.accountNumber1}
              sx={{
                width: "100%",
              }}
              onChange={(e) => {
                handleSearchChange(e.target.value, "accountNumber1");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <MDInput
              size="small"
              label="Số thẻ 2"
              value={searchValue.accountNumber2}
              sx={{
                width: "100%",
              }}
              onChange={(e) => {
                handleSearchChange(e.target.value, "accountNumber2");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <MDInput
              size="small"
              label="Via"
              value={searchValue.via}
              sx={{
                width: "100%",
              }}
              onChange={(e) => {
                handleSearchChange(e.target.value, "via");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        border="1px solid"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        paddingInline="10px"
        paddingBlock="5px"
        marginTop="10px"
      >
        <MDButton
          variant="outlined"
          color="success"
          onClick={() => {
            onSearch &&
              onSearch({
                ...searchValue,
                country: searchValue?.country?._id,
                condition: searchValue?.condition?._id,
                group: searchValue?.group?._id,
                member: searchValue?.member?._id,
                card: searchValue?.card?._id,
              });
          }}
          sx={{
            marginRight: "10px",
          }}
        >
          Search
        </MDButton>

        <MDButton
          variant="outlined"
          color="error"
          onClick={() => {
            setSearchValue(initialState);
            onSearch &&
              onSearch({
                ...initialState,
                bankingType: null,
              });
          }}
        >
          Clear
        </MDButton>
      </Grid>
    </Grid>
  );
};

export default AdsAccountAdvancedSearch;
