import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/bank/banksData";
import useCanAccess from "./useCanAccess";
import { bankActions } from "../constants/actions";
export const bankQueryKey = "bank";

export const useBank = ({ isDeleted = false } = {}) => {
  const { makeRequest } = useRequest();
  const [bankValue, setBankValue] = useState("");
  const { can } = useCanAccess();

  const { data, isLoading, error } = useQuery({
    queryKey: [bankQueryKey, { isDeleted }],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${bankQueryKey}/getAll?isDeleted=${isDeleted}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled: can(bankActions.getAllBanks),
    placeholderData: (previousData, previousQuery) => previousData,
  });

  const rows = useMemo(() => {
    try {
      const filters = {
        bank: bankValue,
      };
      const bank = data;

      return getRows({ bank, filters });
    } catch (e) {
      return [];
    }
  }, [data, bankValue]);
  const handleSearchChange = (value) => {
    setBankValue(value);
  };

  return {
    data,
    rows,
    isLoading,
    error,
    handleSearchChange,
  };
};
