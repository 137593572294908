import { getColumns } from "./actionGroupData";

import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { actionGroupQueryKey, useActionGroup } from "../../hooks/useActionGroup";

import TableLayout from "../TableLayout";
import { actionGroupActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";

function ActionGroupTable() {
  const { rows, handleSearchChange, isLoading } = useActionGroup();

  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="Nhóm Hành Động"
      canCreate={
        <Can
          permissions={actionGroupActions.inserActionGroup}
          fallback={"Bạn không có quyền thêm nhóm hành động"}
        >
          <UpdateForm create={true} />
        </Can>
      }
      onSearch={handleSearchChange}
      canExport={actionGroupQueryKey}
      refreshButton={true}
    />
  );
}

export default ActionGroupTable;
