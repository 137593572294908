import { useQuery } from "@tanstack/react-query";
import { useRequest } from "context/RequestContext";
import { useEffect } from "react";

import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export function useNotificationQuery() {
  const { makeRequest } = useRequest();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isAuthenticated } = useAuth();

  const isDeleted = searchParams.get("isDeleted") || false;
  const priority = searchParams.get("priority") || "";

  // useEffect(() => {
  //   if (!isDeleted) {
  //     searchParams.set("isDeleted", false);
  //   }
  //   if (!priority) {
  //     searchParams.set("priority", 1);
  //   }
  //   setSearchParams(searchParams);
  // }, []);

  const { data, isLoading } = useQuery({
    queryKey: ["notification", isDeleted, priority],
    queryFn: async () => {
      async function fetchNotifications() {
        try {
          const request = {
            method: "GET",
            endpoint: `/notification/getByToken?isDeleted=${isDeleted}&priority=${priority}`,
          };
          const res = await makeRequest(request);

          return res || [];
        } catch (err) {
          dispatchError(err);
        }
      }
      return fetchNotifications();
    },
    enabled: isAuthenticated,
  });

  return { data, isLoading };
}
