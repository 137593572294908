import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import { useEffect, useState } from "react";
import MDBox from "./MDBox/index";
import { useModal } from "../context/ModalContext";
import { useSearchParams } from "react-router-dom";

export const modalWrapperStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(400px,'min-content')",
  bgcolor: "background.default",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  paddingTop: "40px",
  paddingBottom: "55px",
};

export default function CompositionModal({ action = "edit", children }) {
  const [open, setOpen] = useState(false);
  const { openObserver } = useModal();

  const [searchParams] = useSearchParams();
  const isDeleted = searchParams.get("isDeleted") ?? "false";

  useEffect(() => {
    //  close modal from children
    if (openObserver !== 1) {
      setOpen(false);
    }
  }, [openObserver]);
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = (e) => {
    // e.stopPropagation();
    // check is click from close button
    if (e?.target?.tagName === "BUTTON") {
      setOpen(false);
    }
    // check double click
    if (e?.type === "dblclick") {
      setOpen(false);
    }
    if (e?.code === "Escape") {
      setOpen(false);
    }
  };
  // close modal from children

  return (
    <div>
      <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
        <MDBox>
          {action === "edit" && isDeleted === "false" && (
            <MDButton variant="text" color="warning" onClick={handleOpen}>
              <Icon>edit</Icon>&nbsp;Cập Nhật
            </MDButton>
          )}
          {action === "create" && (
            <MDButton variant="outlined" color="success" onClick={handleOpen}>
              <Icon>add_circle</Icon>&nbsp;Tạo Mới
            </MDButton>
          )}
          {action === "delete" && isDeleted === "false" && (
            <MDButton variant="text" color="error" onClick={handleOpen}>
              <Icon>delete</Icon>&nbsp;Xoá
            </MDButton>
          )}
          {/*    show action*/}

          {action === "show" && (
            <MDButton variant="text" onClick={handleOpen}>
              <Icon>visibility</Icon>&nbsp;show
            </MDButton>
          )}

          {/*   import modal */}
          {action === "import" && (
            <MDButton color="success" variant="outlined" onClick={handleOpen}>
              <Icon>import_export</Icon>&nbsp;Import
            </MDButton>
          )}
        </MDBox>
      </MDBox>
      <Modal
        closeAfterTransition={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={(e) => {
          e.stopPropagation();
        }}
        slotProps={{
          backdrop: {
            onDoubleClick: handleClose,
          },
        }}
      >
        <MDBox sx={modalWrapperStyle}>
          {children}
          <MDButton
            variant="contained"
            color="error"
            iconOnly
            onClick={() => {
              setOpen(false);
            }}
            size="small"
            sx={{
              position: "absolute",
              top: 10,
              right: 5,
            }}
          >
            <Icon>clear</Icon>
          </MDButton>
        </MDBox>
      </Modal>
    </div>
  );
}
