import { useMemo } from "react";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UpdateForm from "./UpdateForm";
import Modal from "components/Modal";
import DeleteForm from "components/DeleteForm";
import SelectRenderProp from "components/Select";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { useMaterialUIController } from "context";
import { useNotification } from "context/NotificationContext";

import { useNavigate, useSearchParams } from "react-router-dom";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import MDBadge from "components/MDBadge";
import useCanAccess from "hooks/useCanAccess";
import { notificationActions } from "constants/actions";
import IsDeletedFilter from "components/IsDeletedFilter";

function NotificationsList() {
  const [controller] = useMaterialUIController();
  const { darkMode } = useMemo(() => controller, [controller]);

  const { notifications, isLoading } = useNotification();
  const { Can } = useCanAccess();

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar Can={Can} />
        <NotificationToolbar Can={Can} />

        {isLoading ? (
          <SpinnerFullPage />
        ) : (
          <>
            {notifications?.unread &&
              notifications?.unread.map((unread) => (
                <NotificationItem
                  key={unread._id}
                  darkMode={darkMode}
                  noti={unread}
                  unread={true}
                  Can={Can}
                />
              ))}

            {notifications?.read &&
              notifications?.read.map((unread) => (
                <NotificationItem key={unread._id} darkMode={darkMode} noti={unread} Can={Can} />
              ))}
          </>
        )}
      </DashboardLayout>
    </>
  );
}

function NotificationItem({ darkMode, noti, unread = false, Can }) {
  const { palette } = darkMode ? theme : themeDark;
  const { createdBy, title } = noti;

  const navigate = useNavigate();

  function handleClick() {
    navigate(`${noti._id}?unread=${unread}`);
  }

  return (
    <MDBox
      bgColor={!unread ? palette.background.default : palette.badgeColors.primary.background}
      borderRadius="10px"
      color={palette.text.main}
      my={2}
      p={1}
      onClick={handleClick}
    >
      <MDBox sx={{ color: "inherit", display: "flex", alignItems: "center", gap: 1, p: 2 }}>
        <Avatar alt={createdBy?.fullName} src={createdBy?.avatar} />
        <span>{createdBy?.fullName}</span>
      </MDBox>
      <MDTypography variant="body2" sx={{ color: "inherit", fontWeight: "bold" }} px={2}>
        {title}
      </MDTypography>
      <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
        <Can permissions={notificationActions.updateNotification}>
          <Modal>
            <UpdateForm notification={noti} />
          </Modal>
        </Can>
        <Can permissions={notificationActions.deleteNotification}>
          <Modal action="delete">
            <DeleteForm context="notification" id={noti._id} />
          </Modal>
        </Can>
      </MDBox>
    </MDBox>
  );
}

function NotificationToolbar({ Can }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const priority = searchParams.get("priority") || "";

  function handlePriority(value) {
    searchParams.set("priority", value);
    setSearchParams(searchParams);
  }

  return (
    <MDBox display="flex" gap={2} justifyContent="end">
      <Can permissions={notificationActions.insertNotification}>
        <Modal action="create">
          <UpdateForm create={true} />
        </Modal>
      </Can>
      <IsDeletedFilter />
      <PriorityFilter onChange={handlePriority} priority={priority} />
    </MDBox>
  );
}

function PriorityFilter({ onChange, priority }) {
  return (
    <SelectRenderProp
      title="Độ Ưu Tiên"
      defaultValue={priority}
      data={[
        { value: "", title: "Tất cả" },
        { value: 1, title: "Mức Độ 1", color: "error" },
        { value: 2, title: "Mức Độ 2", color: "success" },
        { value: 3, title: "Mức Độ 3", color: "info" },
      ]}
      render={(data) => {
        return (
          <MenuItem key={data.value} value={data.value}>
            <MDBadge badgeContent={data.title} color={data.color} variant="gradient" size="sm" />
          </MenuItem>
        );
      }}
      onChange={onChange}
    />
  );
}

export default NotificationsList;
