import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/action-group/actionGroupData";
import { useAction } from "./useAction";
import useCanAccess from "./useCanAccess";
import { actionGroupActions } from "../constants/actions";
export const actionGroupQueryKey = "actionGroup";

export const useActionGroup = ({ enabled = true } = {}) => {
  const { makeRequest } = useRequest();
  const [searchValue, setSearchValue] = useState("");
  const { can } = useCanAccess();

  const { data: actions, isLoading: isLoadingActions } = useAction();
  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: [actionGroupQueryKey],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${actionGroupQueryKey}/getAll`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled: can(actionGroupActions.getAllActionGroups) && enabled,
    placeholderData: (previousData, previousQuery) => previousData || [],
  });
  const rows = useMemo(() => {
    try {
      const filters = {
        name: searchValue,
      };
      const actionGroup = data;

      return getRows({ actionGroup, actions, filters });
    } catch (e) {
      return [];
    }
  }, [data, searchValue, actions]);
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  return {
    data,
    rows,
    isLoading,
    isLoadingActions,
    error,
    handleSearchChange,
    refetch,
    isFetching,
  };
};
