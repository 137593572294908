import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { useRequest } from "context/RequestContext";
import React, { useState } from "react";
import { useMaterialUIController } from "context";
import FormSelect from "components/FormSelect";
import MultipleSelectChip from "components/MultipleSelectChip";
import { useQueryClient } from "@tanstack/react-query";
import { cardQueryKey } from "../../hooks/useCard";
import { useBank } from "../../hooks/useBank";
import { useUser } from "../../hooks/useUser";
import { useCardCategory } from "../../hooks/useCardCategory";
import Spinner from "../../components/Spinner/Spinner";
import QuillInputFiled from "components/QuillInputField/QuillInputFiled";
import Autocomplete from "@mui/material/Autocomplete";
import { useMember } from "../../hooks/useMember";

const inputStyle = {
  marginTop: "20px",
};

const context = "card";

function UpdateForm({ card, create = false }) {
  const catigories = card?.cardCategories.map((category) => category._id) || [];
  const cardMembers = card?.members?.map((manager) => manager) || [];

  const [account, setAccount] = useState(card?.account || "");
  const [note, setNote] = useState(card?.note || "");
  const [accountNumber, setAccountNumber] = useState(card?.accountNumber || "");
  const [bank, setBank] = useState(card?.bank || null);
  const [cardCategories, setCardCategories] = useState(catigories || []);
  const [members, setMembers] = useState(cardMembers || []);

  const { createRequest, updateRequest } = useRequest();
  const { data: bankRequest, isLoading: isLoadingBank } = useBank();
  const { data: user, isLoading: isLoadingUser } = useUser({
    getAll: true,
  });
  const { data: memberData } = useMember();
  const { data: cardCategory, isLoading: isLoadingCardCategory } = useCardCategory();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const queryClient = useQueryClient();

  const handleCategoriesChange = (value) => {
    setCardCategories(value);
  };

  const handleMangersChange = (value) => {
    setMembers(value);
  };

  function handleChangeDescription(value) {
    setNote(value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const body = {
      account,
      accountNumber,
      bank: bank?._id,
      cardCategories,
      members: members.map((member) => member?._id),
      note,
    };

    if (!create) {
      await updateRequest({ context, id: card._id, body });
      await queryClient.invalidateQueries({
        queryKey: [cardQueryKey],
        type: "all",
      });
    } else {
      await createRequest({ context, body });
      await queryClient.invalidateQueries({
        queryKey: [cardQueryKey],
        type: "all",
      });
    }
  }

  if (isLoadingBank || isLoadingUser || isLoadingCardCategory)
    return (
      <div>
        <Spinner />
      </div>
    );
  return (
    <MDBox
      component="form"
      sx={{
        maxHeight: "90vh",
        overflowY: "auto",
      }}
      onSubmit={handleSubmit}
    >
      <MDInput
        fullWidth
        id="account"
        label="Tên Tài Khoản"
        variant="outlined"
        value={account}
        onChange={(e) => setAccount(e.target.value)}
        sx={inputStyle}
        required
      />
      <MDInput
        fullWidth
        id="account-number"
        label="Số Tài Khoản"
        variant="outlined"
        value={accountNumber}
        onChange={(e) => setAccountNumber(e.target.value)}
        sx={inputStyle}
      />
      <Autocomplete
        disablePortal
        options={bankRequest}
        defaultValue={bank}
        value={bank}
        sx={{
          width: "100%",
          ...inputStyle,
          "& .MuiOutlinedInput-root": {
            padding: "4px",
          },
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            label="Ngân Hàng"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        getOptionLabel={(option) => {
          return `${option?.name}`;
        }}
        onChange={(e, newValue) => {
          setBank(newValue);
        }}
      />
      {/*<MultipleSelectChip*/}
      {/*  title="Người Quản Lý"*/}
      {/*  data={user?.data || []}*/}
      {/*  dataSelected={managers}*/}
      {/*  onChange={handleMangersChange}*/}
      {/*/>*/}
      <Autocomplete
        sx={{
          width: "100%",
          ...inputStyle,
          "& .MuiOutlinedInput-root": {
            padding: "4px",
          },
        }}
        multiple
        value={members}
        isOptionEqualToValue={(option, value) => option?._id === value?._id}
        onChange={(e, newValue) => {
          setMembers(newValue);
        }}
        renderInput={(params) => {
          return (
            <MDInput
              {...params}
              label="Thành viên"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
              }}
            />
          );
        }}
        getOptionLabel={(option) => {
          return `${option?.name} - ${option?.group?.name}`;
        }}
        options={memberData}
      />
      <MultipleSelectChip
        title="Loại Thẻ"
        data={cardCategory}
        dataSelected={cardCategories}
        onChange={handleCategoriesChange}
      />
      <QuillInputFiled initialValue={note} onChange={handleChangeDescription} />
      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
