import { useMaterialUIController } from "context";
import styles from "./Spinner.module.css";

function Spinner() {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  return (
    <div className={styles.spinnerContainer}>
      <div
        style={{ background: `conic-gradient(#0000 10%, ${sidenavColor ?? "#ececec"})` }}
        className="spinner"
      ></div>
    </div>
  );
}

export default Spinner;
