import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useMemo } from "react";
import { useAuth } from "../context/AuthContext";

function TableLayout({
  columns,
  rows,
  title,
  canCreate,
  onSearch,
  extraSearch,
  canSearch = true,
  canExport = false,
  refreshButton = false,
  importModal = null,
  advancedSearch = false,
  chartTable = null,
  compact = false,
  isSorted = false,
  rowSelection = undefined,
  sortButton = true,
  sortOptions = undefined,
}) {
  const { user, isAuthenticated } = useAuth();

  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const filteredColumns = useMemo(() => {
    if (!isAuthenticated) return columns;

    //  check role
    const role = user?.role?.name || user?.user?.role?.name;

    if (role === "SUPERADMIN") return columns;

    //   filter isDeleted column
    return columns?.filter((column) => {
      return column?.accessor !== "isDeleted";
    });
  }, [columns, isAuthenticated]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {!!chartTable && (
            <Grid item xs={12}>
              {chartTable}
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={2}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow={sidenavColor}
              >
                <MDTypography variant="h6" color="white" sx={{ textTransform: "uppercase" }}>
                  Bảng {title}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: filteredColumns, rows }}
                  isSorted={isSorted}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  canSearch={canSearch}
                  canCreate={canCreate}
                  noEndBorder
                  onSearch={onSearch}
                  extraSearch={extraSearch}
                  canExport={canExport}
                  refreshButton={refreshButton}
                  importModal={importModal}
                  advancedSearch={advancedSearch}
                  compact={compact}
                  rowSelection={rowSelection}
                  sortButton={sortButton}
                  sortOptions={sortOptions}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default TableLayout;
