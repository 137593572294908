export const bankingStatusOptions = [
  {
    value: 0,
    label: "Chưa nhận",
    color: "error",
  },
  {
    value: 1,
    label: "Đã nhận",
    color: "success",
  },
  {
    value: 2,
    label: "Đã chuyển",
    color: "primary",
  },
];
export const DEFAULT_SORT = "-createdTime";
