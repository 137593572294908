import React, { useState } from "react";
import { useRequest } from "../../context/RequestContext";
import { useMutation } from "@tanstack/react-query";
import { method } from "../../libs/fetchRequestUtils";
import { get, isUndefined } from "lodash";
import { exportCSVFile } from "../../libs/export-csv";
import Spinner from "../../components/Spinner/Spinner";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import { CircularProgress, Dialog, DialogTitle } from "@mui/material";
import MDBox from "../../components/MDBox";
import { getKeysFromObjArray, isISODateString, separateThousands } from "../../utils/helpers";
import moment from "moment";
import { getColumns } from "./bankingsData";
import { getTextFromHtmlTag } from "../../libs/display-content";

const context = "banking";

export async function handleDownload(makeRequest, dispatch, listRecords, advancedSearch) {
  const request = {
    method: method.get,
    endpoint: `/banking/getAll`,
  };
  let data = [];
  if (advancedSearch) {
    request.endpoint = `/banking/getPaging?${new URLSearchParams(
      advancedSearch
    ).toString()}&pageSize=${Infinity}`;
    const result = await makeRequest(request);
    data = result?.data || [];
  } else {
    data = listRecords || (await makeRequest(request));
  }
  const columns = getColumns();
  // map columns to headers
  const listKeys = getKeysFromObjArray(data, [
    "_id",
    "createdTime",
    "createdBy",
    "updatedBy",
    "updatedTime",
    "departments",
    "groups",
    "isDeleted",
  ]);
  const headers = listKeys.reduce((acc, cur) => {
    const headerTitle = columns?.find((column) => column.accessor === cur)?.Header;
    return {
      ...acc,
      [cur]: headerTitle || cur,
    };
  }, {});
  headers.usdExchangeRate = "Tỉ giá USD/VND";
  headers.moneyVND = "moneyVND";

  const fileTitle = `${context}-${new Date().getTime()}`;

  // const rate = Number(currentcyRate?.replace(/,/g, "")) || 1;
  const exportData = data.map((record) => {
    //   map data with headers
    const result = Object.keys(headers).reduce((acc, cur) => {
      let value = isUndefined(record[cur]) ? "" : record[cur];
      // check if value is datetime
      if (isISODateString(value)) {
        value = moment(value).format("DD/MM/YYYY HH:mm:ss");
      }
      return {
        ...acc,
        [cur]: value,
      };
    }, {});

    result.moneyVND = separateThousands(
      record?.moneyType === 1
        ? record?.amountOfMoney * (record?.usdExchangeRate || 1)
        : record?.amountOfMoney
    );
    if (result?.toCard) {
      result.toCard = `${result.toCard?.account}-${get(result, "toCard.accountNumber", "")}`;
    }
    if (result?.fromCard) {
      result.fromCard = `${result.fromCard?.account}-${get(result, "fromCard.accountNumber", "")}`;
    }
    if (result?.bankingType) {
      result.bankingType = result.bankingType?.name;
    }
    // members
    result.members = record?.members.map((member) => member?.name).join(", ");
    result.amountOfMoney = separateThousands(record?.amountOfMoney);
    // moneyType
    result.moneyType = result.moneyType === 1 ? "USD" : "VND";
    // status
    result.status =
      result.status === 0 ? "Chưa nhận" : result.status === 1 ? "Đã nhận" : "Đã chuyển";
    // content
    result.content = getTextFromHtmlTag(result.content, {
      ALLOWED_TAGS: [],
      KEEP_TEXT_AND_BREAK: true,
    });

    return result;
  });

  exportCSVFile(headers, exportData, fileTitle, () => {
    dispatch({
      type: "download",
      payload: {
        message: `Download ${context} successfully!`,
      },
    });
  });
}

const BankingExportModal = ({ isHasAdvancedSearch, advancedSearch }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [open, setOpen] = useState(false);
  const { makeRequest, dispatch, dispatchError } = useRequest();
  // const {
  //   data: { data, fileTitle, headers } = {},
  //   isLoading: isLoadingQuery,
  //   isFetching: isFetchingQuery,
  //   error,
  // } = useQuery({
  //   queryKey: ["banking", "export"],
  //   queryFn: async () => {
  //     debugger;
  //     // if (context === "user" || context === "punishment") {
  //     //   let request = {
  //     //     method: method.get,
  //     //     endpoint: `/${context}/getAll`,
  //     //   };
  //     //
  //     //   return await makeRequest(request);
  //     // }
  //     // special case for user
  //     let request = {
  //       method: method.get,
  //       endpoint: `/banking/getAll`,
  //     };
  //     return await makeRequest(request);
  //   },
  //   select: (data) => {
  //     data = data?.data ? data.data : data;
  //     if (isEmpty(data))
  //       return {
  //         data: [],
  //         headers: {},
  //         fileTitle: "",
  //       };
  //
  //     // omit some fields and transform some fields
  //     data = data.map((item) => {
  //       return {
  //         ...omit(item, ["_id", "createdTime", "createdBy", "updatedBy", "updatedTime"]),
  //         moneyType: item.moneyType === 1 ? "USD" : "VND",
  //       };
  //     });
  //     // make headers
  //
  //     const headers = Object.keys(data[0]).reduce((acc, cur) => {
  //       return {
  //         ...acc,
  //         [cur]: cur,
  //       };
  //     }, {});
  //
  //     // make title with timestamp
  //     const fileTitle = `Banking-${new Date().getTime()}`;
  //     return {
  //       data,
  //       headers,
  //       fileTitle,
  //     };
  //   },
  //   placeholderData: () => {
  //     return {
  //       data: [],
  //       headers: {},
  //       fileTitle: "",
  //     };
  //   },
  // });

  const { mutate, isPending } = useMutation({
    mutationFn: async (advancedSearchData) => {
      await handleDownload(makeRequest, dispatch, undefined, advancedSearchData);
    },
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (currentcyRate.trim() === "") {
    //   dispatchError({
    //     message: "Vui lòng nhập tỷ giá",
    //   });
    // } else {
    //   mutate();
    // }
    if (isHasAdvancedSearch) {
      mutate(advancedSearch);
    } else {
      mutate();
    }
  };
  if (!context) return null;

  // if (isLoadingQuery) {
  //   return <Spinner />;
  // }

  return (
    <>
      <MDButton
        sx={{
          flexShrink: 0,
        }}
        onClick={() => setOpen(true)}
      >
        {isDownloading ? <Spinner /> : <Icon>import_export</Icon>}
        Export {context}
      </MDButton>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            width: "600px",
          },
        }}
      >
        <DialogTitle>Xuất Danh sách giao dịch</DialogTitle>

        <MDBox
          component="form"
          sx={{
            width: "100%",
            padding: "10px",
          }}
        >
          {isHasAdvancedSearch && (
            <MDButton
              type="submit"
              variant="contained"
              sx={{
                marginBottom: "20px",
                width: "100%",
              }}
              disabled={isPending}
              onClick={handleSubmit}
            >
              {isPending && <CircularProgress size={12} />}
              Xuất giao dịch đã lọc
            </MDButton>
          )}
          <MDButton
            type="submit"
            variant="contained"
            sx={{
              marginBottom: "20px",
              width: "100%",
            }}
            disabled={isPending}
            onClick={handleSubmit}
          >
            {isPending && <CircularProgress size={12} />}
            Xuất toàn bộ giao dịch
          </MDButton>
        </MDBox>
      </Dialog>
    </>
  );
};

export default BankingExportModal;
