import unidecode from "unidecode";

/**
 * Performs a deep search on an object for a specific search term.
 *
 * This function takes an object and a search term as parameters. It recursively searches through
 * all values of the object. If a value is an object itself, the function is called recursively.
 * If a value is a number, it is converted to a string and checked if it includes the search term.
 * If a value is a string, it is converted to lowercase and any diacritics are removed before
 * checking if it includes the search term. The search term is also converted to lowercase and
 * any diacritics are removed before the search begins.
 *
 * @param {Object} obj - The object to perform the deep search on.
 * @param {string} searchTerm - The term to search for in the object.
 * @returns {boolean} - Returns true if the search term is found in the object, otherwise false.
 */
export const deepSearch = (obj, searchTerm) => {
  if (!searchTerm) return true;
  const searchTermNoAccent = unidecode(searchTerm).toLowerCase(); // Remove diacritics

  return Object.values(obj).some((value) => {
    if (typeof value === "object" && value !== null) {
      return deepSearch(value, searchTerm);
    }
    if (typeof value === "number") {
      return value.toString().includes(searchTerm);
    }
    if (typeof value === "string") {
      const valueNoAccent = unidecode(value).toLowerCase(); // Remove diacritics
      return valueNoAccent.includes(searchTermNoAccent);
    }
    return false; // Default case if a value isn't a searchable type
  });
};
