import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ServerPaginateDataTable from "../examples/Tables/DataTable/serverPaginateTable";

function TableServerPaginateLayout({
  columns,
  rows,
  title,
  canCreate,
  entriesPerPage,
  pageCount,
  manualPagination,
  onPaginationChange = () => {},
  pageIndex,
  pageSize,
  onSearchChange = () => {},
  canSearch = true,
  extraSearch = null,
  canExport = false,
  advancedSearch = false,
  refreshButton = false,
  compact = false,
  importModal = null,
  totalItems,
  chartTable = null,
  rowSelection = undefined,
}) {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {!!chartTable && (
            <Grid item xs={12}>
              {chartTable}
            </Grid>
          )}
          {/* TODO: Map departments with reusable "Grid item" component */}
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={2}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow={sidenavColor}
              >
                <MDTypography variant="h6" color="white" sx={{ textTransform: "uppercase" }}>
                  Bảng {title}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <ServerPaginateDataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  canSearch={canSearch}
                  canCreate={canCreate}
                  noEndBorder
                  pageCount={pageCount}
                  manualPagination={manualPagination}
                  onPaginationChange={onPaginationChange}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  onSearchChange={onSearchChange}
                  extraSearch={extraSearch}
                  canExport={canExport}
                  refreshButton={refreshButton}
                  compact={compact}
                  advancedSearch={advancedSearch}
                  importModal={importModal}
                  totalItems={totalItems}
                  rowSelection={rowSelection}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default TableServerPaginateLayout;
