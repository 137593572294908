import React, { useContext, useState } from "react";
import MDTypography from "./MDTypography";
import { useAuth } from "../context/AuthContext";
import MDBox from "./MDBox";
import MDInput from "./MDInput";
import MDButton from "./MDButton";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const UpdatePasswordForm = () => {
  const authContext = useAuth();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setSuccess(false);
      // validate password and confirm password
      if (newPassword !== confirmPassword) {
        setErrors({
          newPassword: "Mật khẩu không khớp",
          confirmPassword: "Mật khẩu không khớp",
        });

        return;
      }
      setErrors({
        newPassword: "",
        confirmPassword: "",
      });
      // call api
      const result = await authContext.updatePassword({
        oldPassword: currentPassword,
        newPassword: newPassword,
      });
      if (result?.error) {
        setErrors({
          currentPassword: result.error,
          newPassword: result.error,
          confirmPassword: result.error,
        });
        return;
      }
      setSuccess(true);
      // reset form
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (e) {
      debugger;
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <MDBox component="form" onSubmit={handleSubmit}>
        {success && <Alert severity="success">Cập nhật mật khẩu thành công</Alert>}
        {errors.currentPassword && <Alert severity="error">{errors.currentPassword}</Alert>}
        <MDTypography variant="h6" fontWeight="bold" gutterBottom>
          Xin chào, {authContext.user?.username}
        </MDTypography>
        <MDInput
          fullWidth
          id="currentPassword"
          label="Mật khẩu hiện tại"
          variant="outlined"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          sx={{ mb: 2 }}
          required
          type="password"
          error={errors.currentPassword}
          helperText={errors.currentPassword}
          inputProps={{
            autocomplete: "off",
            form: {
              autocomplete: "off",
            },
          }}
        />
        <MDInput
          fullWidth
          id="newPassword"
          label="Mật khẩu mới"
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          sx={{ mb: 2 }}
          required
          error={newPassword !== confirmPassword}
          helperText={errors.newPassword}
          type="password"
          inputProps={{
            autocomplete: "off",
            form: {
              autocomplete: "off",
            },
          }}
        />
        <MDInput
          fullWidth
          id="confirmPassword"
          label="Nhập lại mật khẩu mới"
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{ mb: 2 }}
          required
          error={newPassword !== confirmPassword}
          helperText={errors.confirmPassword}
          type="password"
          inputProps={{
            autocomplete: "off",
            form: {
              autocomplete: "off",
            },
          }}
        />
        <MDButton
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={isLoading}
        >
          {isLoading ? "Đang cập nhật..." : "Cập nhật"}
        </MDButton>
      </MDBox>
    </>
  );
};

export default UpdatePasswordForm;
