import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import MDBox from "./MDBox";
import { Stack } from "@mui/material";
import { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip({
  title = "Bộ Phận",
  data,
  dataSelected,
  onChange,
  labelProps,
  ...rest
}) {
  const [selected, setSelected] = useState(dataSelected);

  function getName(value) {
    const result = data.find((record) => record._id === value);
    return result?.name || result?.username || result?.cardName || result?.user?.fullName || "Data";
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newSelected = typeof value === "string" ? value.split(",") : value;

    setSelected(newSelected);
    onChange?.(newSelected);
  };

  function handleDelete(value) {
    const newSelected = selected.slice().filter((item) => item !== value);

    setSelected(newSelected);
    onChange?.(newSelected);
  }

  return (
    <div>
      <FormControl
        sx={{ m: 1, width: "100%", marginLeft: 0, marginTop: "20px", ...rest?.sx }}
        required={rest?.required || false}
      >
        <InputLabel id="deparment-multiple-chip-label" size={rest?.size || "normal"}>
          {title}
        </InputLabel>
        <Select
          labelId="deparment-multiple-chip-label"
          id="deparment-multiple-chip"
          multiple
          value={selected}
          onChange={handleChange}
          input={
            <OutlinedInput
              id="select-multiple-chip"
              label="Deparments"
              size={rest?.size || "normal"}
            />
          }
          renderValue={(selected) => (
            <Stack direction="row" gap={0.5}>
              {selected.map((value, index) => (
                <Chip
                  variant="outlined"
                  key={`${value}-${index}`}
                  label={getName(value)}
                  sx={{
                    color: "text.main",
                    m: "5px",
                    textTransform: "uppercase",
                  }}
                  onClick={() => handleDelete(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ))}
            </Stack>
          )}
          MenuProps={MenuProps}
          sx={{ padding: "10px" }}
        >
          {data.map((record, index) => {
            if (record?.isDeleted) return;
            return (
              <MenuItem key={index} value={record._id} sx={{ textTransform: "uppercase" }}>
                {record.name ||
                  record.username ||
                  record?.cardName ||
                  record?.user?.fullName ||
                  "Data"}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
