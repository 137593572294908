import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import DeleteForm from "components/DeleteForm";
import MDTypography from "../../components/MDTypography";

import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import { actionGroupActions, bankActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
}));

function getRows({ actionGroup, actions: actionsList, filters = {} }) {
  try {
    let rows = actionGroup;
    // Apply filters
    Object.keys(filters).forEach((key) => {
      rows = rows.filter((row) => {
        if (!filters[key]) return true;
        if (key === "actions") {
          return row.actions.map((item) => item._id).includes(filters[key]);
        }

        return row[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    rows = rows.map((item) => {
      const { name, icon, actions, createdBy, viName } = item;

      const mappedActions = actions.map((id) => {
        const action = actionsList.find((action) => action._id === id);
        return action || null;
      });

      return {
        name: <StyledNameComponent img={ASSET_URL + icon} name={name} />,
        viName: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox ml={2} lineHeight={1}>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                sx={{ textTransform: "uppercase" }}
              >
                {viName}
              </MDTypography>
            </MDBox>
          </MDBox>
        ),
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={createdBy.fullName} />
        ) : null,
        actions: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Modal action="show">
              <MDBox display="flex" alignItems="center">
                <StyledBox>
                  {/*check length is empty and show message*/}
                  {mappedActions.length === 0 ? (
                    <MDTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                      sx={{ textTransform: "uppercase" }}
                    >
                      Chưa có action
                    </MDTypography>
                  ) : (
                    <>
                      {mappedActions.map((item, index) => (
                        <Chip
                          key={item._id || index}
                          variant="outlined"
                          label={item?.viName || item?.name || "Action Name"}
                          sx={{ color: "text.main", marginTop: "10px" }}
                        />
                      ))}
                    </>
                  )}
                </StyledBox>
              </MDBox>
            </Modal>
          </MDBox>
        ),
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={actionGroupActions.updateActionGroup}>
              <Modal
                boxStyle={{
                  width: 600,
                }}
              >
                <UpdateForm actionGroup={item} />
              </Modal>
            </Can>
            <Can permissions={actionGroupActions.deleteActionGroup}>
              <Modal action="delete">
                <DeleteForm context="bank" id={item._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "tên", accessor: "name", width: "25%", align: "left" },
    { Header: "tên tiếng việt", accessor: "viName", width: "25%", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "các hành động", accessor: "actions", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };

// <List dense title="Action List">
//   {mappedActions.map((item) => (
//       <ListItem
//           key={item?._id}
//           style={{
//             color: "white",
//             borderBottom: "1px solid white",
//           }}
//       >
//         <ListItemText
//             disableTypography
//             primary={
//               <MDTypography
//                   display="block"
//                   variant="button"
//                   fontWeight="medium"
//                   sx={{ textTransform: "uppercase" }}
//               >
//                 {item?.name}
//               </MDTypography>
//             }
//             secondary={
//               <MDTypography
//                   display="block"
//                   variant=""
//                   fontWeight="medium"
//                   sx={{ textTransform: "uppercase" }}
//                   color="secondary"
//               >
//                 {item?.viName}
//               </MDTypography>
//             }
//         />
//       </ListItem>
//   ))}
// </List>
