import DOMPurify from "dompurify";

export function DisplayContent({ content, ignoreTags = [], ignoreAttr = [] }) {
  const safeContent = DOMPurify.sanitize(content, {
    FORBID_TAGS: ignoreTags,
    FORBID_ATTR: ignoreAttr,
  });

  return <div dangerouslySetInnerHTML={{ __html: safeContent }} />;
}
export const getRemovedHtmlTag = (content) => {
  const regex = /(<([^>]+)>)/gi;
  return content.replace(regex, "");
};
export const getTextFromHtmlTag = (
  dirty,
  {
    ALLOWED_TAGS = ["b", "q", "img"],
    ALLOWED_ATTR = ["style", "src"],
    KEEP_TEXT = true,
    REMOVE_TEXT = ["script"],
    FORBID_TAGS = [],
    KEEP_TEXT_AND_BREAK = false,
  } = {}
) => {
  // remove &nbsp;
  dirty = dirty?.replace(/&nbsp;/g, " ");
  //
  if (KEEP_TEXT_AND_BREAK) {
    dirty = dirty
      ?.replace(/<br\s*\/?>/gi, "\n")
      .replace(/<\/p>/gi, "\n")
      .replace(/<[^>]+>/g, ""); // Xóa các tag HTML còn lại
  }
  const result = DOMPurify.sanitize(dirty, {
    ALLOWED_TAGS,
    ALLOWED_ATTR,
    KEEP_TEXT,
    REMOVE_TEXT,
    FORBID_TAGS,
  });

  return result;
};

export const sanitizeImage = (content) => {
  return DOMPurify.sanitize(content, {
    FORBID_TAGS: ["img"],
    FORBID_ATTR: ["src"],
  });
};
