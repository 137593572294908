import { useState } from "react";
import Fuse from "fuse.js";
import diacritics from "diacritics";

function fuzzySearch({ fuse, data, term }) {
  const result = fuse.search(`${term}`);
  const originalResult = result.map((r) => r.item);
  return term ? originalResult : data;
}

/**
 *
 * @param {*} param0
 *
 * A custom React Hook to do a in-memory fuzzy text search
 * using Fuse.js.
 */
function useFuse({ data, options }) {
  const [term, setTerm] = useState("");

  const fuseOptions = {
    threshold: 0.2,
    getFn: function () {
      const [obj, path] = arguments;
      const value = Fuse.config.getFn(obj, path);
      if (typeof value === "string") {
        return diacritics.remove(value);
      }
      if (Array.isArray(value)) {
        return value.map(diacritics.remove);
      }
      // return diacritics.remove(Fuse.config.getFn.apply(this, arguments));
      return Fuse.config.getFn.apply(this, arguments);
    },
    ...options,
  };

  const fuse = new Fuse(data, fuseOptions);

  const result = fuzzySearch({ data, term, fuse });

  const reset = () => {
    setTerm("");
  };

  const search = (term) => {
    term = diacritics.remove(term);
    setTerm(term);
  };

  return { result, search, term, reset };
}

export default useFuse;
