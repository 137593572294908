import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { useRequest } from "context/RequestContext";
import { useState } from "react";
import { useMaterialUIController } from "context";
import StatusSelect from "components/StatusSelect";
import CheckboxActionsList from "components/CheckboxActionsList";
import { useQueryClient } from "@tanstack/react-query";
import { roleQueryKey } from "../../hooks/useRole";

const context = "role";

function UpdateForm({ role, create = false }) {
  const actionSelected = role?.actions.map((action) => (action?._id ? action.id : action)) || [];

  const [name, setName] = useState(role?.name || "");
  const [actions, setActions] = useState(actionSelected);
  const [isDefault, setIsDefault] = useState(role?.default || false);
  const [isLoading, setIsLoading] = useState(false);

  const { updateRequest, createRequest } = useRequest();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const queryClient = useQueryClient();

  function handleChangeIsDefault(e) {
    setIsDefault(e.target.value);
  }

  function handleChangeActions(value) {
    setActions(value);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setIsLoading(true);
      const body = { name, actions, default: isDefault === 1 ? true : "false" };
      if (!create) {
        await updateRequest({ context, id: role._id, body });
        queryClient.invalidateQueries({
          queryKey: [roleQueryKey],
        });
      } else {
        await createRequest({ context, body });
        queryClient.invalidateQueries({
          queryKey: [roleQueryKey],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <MDBox
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: 600,
      }}
    >
      <MDInput
        fullWidth
        id="name"
        label="Vai Trò"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <StatusSelect
        title="Mặc Định"
        context="role"
        status={isDefault}
        onChange={handleChangeIsDefault}
      />
      <CheckboxActionsList actionSelected={actions} onCheckedActions={handleChangeActions} />
      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
