/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useMemo, useEffect, useState, isValidElement, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes, { func } from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import Modal from "components/Modal";
import { ExportModal } from "./ExportModal";
import MDButton from "components/MDButton";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import Spinner from "../../../components/Spinner/Spinner";
import { bankingStatisticsQueryKey } from "../../../hooks/useBankingStastic";
import Checkbox from "@mui/material/Checkbox";
import { isEqual } from "lodash";
import LinearProgress from "@mui/material/LinearProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Tag } from "@mui/icons-material";
import MDBadge from "../../../components/MDBadge";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import SortButton from "./SortButton";

function DataTable({
  entriesPerPage,
  canSearch,
  canCreate = false,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  onSearch,
  extraSearch,
  canExport,
  refreshButton = false,
  importModal = null,
  advancedSearch = null,
  compact = false,
  rowSelection = undefined,
  sortButton = true,
  sortOptions = undefined,
}) {
  const isFetching = useIsFetching({
    fetchStatus: "fetching",
  });
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;

  /**
   * ["5", "10", "15", "20", "25"] => og values
   */
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "20", "50", "100"];
  // State for data
  const [dataState, setDataState] = useState([]);
  // State for selected rows
  const [selectedRows, setSelectedRows] = useState([]);
  // State for select all
  const [selectAll, setSelectAll] = useState(false);
  // State for isDeleted
  const [isDeleted, setIsDeleted] = useState(() => {
    return searchParams.get("isDeletedRecords") === "true";
  });
  const [initialPageIndex, setInitialPageIndex] = useState(0);
  // ref
  const tableContainerRef = useRef(null);

  const columns = useMemo(() => {
    return table.columns;
  }, [table]);
  const data = useMemo(() => {
    const newData = table.rows;
    // Only update the state if the data has changed
    if (!isEqual(newData, dataState)) {
      setSelectedRows([]);
      setSelectAll(false);
      setDataState(newData);
      return newData;
    }
    // If the data hasn't changed, return the old state to prevent unnecessary re-renders
    return dataState;
  }, [table, dataState]);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: initialPageIndex },
      getRowId: (row, index) => {
        return row.id || index?.toString();
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Handler for checkbox click
  const handleCheckboxClick = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        // If the row is already selected, unselect it
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        // If the row is not selected, select it
        return [...prevSelectedRows, rowId];
      }
    });
  };
  // Handler for select all checkbox click
  const handleSelectAllClick = () => {
    setSelectAll((prevSelectAll) => {
      const newSelectAll = !prevSelectAll;
      if (newSelectAll) {
        // If the select all checkbox is checked, select all rows
        setSelectedRows((prev) => {
          const newArr = page.map((row) => {
            return row.id;
          });
          return [...new Set([...prev, ...newArr])];
        });
      } else {
        // If the select all checkbox is unchecked, unselect all rows from the current page
        setSelectedRows((prev) => {
          const newArr = page.map((row) => {
            return row.id;
          });
          return prev.filter((id) => !newArr.includes(id));
        });
      }
      return newSelectAll;
    });
  };

  useEffect(() => {
    //     emit event
    rowSelection?.onChange && rowSelection.onChange(selectedRows);
  }, [selectedRows]);
  useEffect(() => {
    if (tableContainerRef?.current) {
      // scroll to top
      tableContainerRef.current.scrollTop = 0;
    }
    //if rows is 0
    if (rows?.length === 0) {
      setSelectAll(false);
      return;
    }
    // find the selected rows from the current page
    const selectedRowsFromCurrentPage = page.map((row) => row.id);
    // check if all the rows from the current page are selected
    const allRowsSelected = selectedRowsFromCurrentPage.every((row) => selectedRows.includes(row));
    // set the select all checkbox based on the above condition
    setSelectAll(allRowsSelected);
  }, [pageIndex]);

  useEffect(() => {
    if (pageIndex > 0 && page?.length === 0) {
      setInitialPageIndex(0);
      gotoPage(0);
    }
  }, [page, pageIndex]);
  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => {
    setInitialPageIndex(Number(value.value - 1));
    gotoPage(Number(value.value - 1));
  };

  // Search input value state
  const [search, setSearch] = useState("");

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }
  const handleSearchChange = ({ currentTarget }) => {
    const value = currentTarget.value;
    setSearch(value);
  };
  const handleRefresh = () => {
    queryClient.invalidateQueries({
      type: "active",
    });
  };

  const renderExport = () => {
    if (!canExport) return null;

    if (isValidElement(canExport)) {
      return canExport;
    } else if (typeof canExport === "string") {
      return <ExportModal context={canExport} />;
    }
  };

  return (
    <>
      {entriesPerPage || canSearch || canCreate ? (
        <>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            {entriesPerPage && (
              <MDBox display="flex" alignItems="center" gap={0.5} maxWidth="60%" flexShrink={0}>
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{
                    width: "5rem",
                    "& .MuiPaper-root": {
                      padding: 0,
                    },
                  }}
                  PaperComponent={({ children }) => <div>{children}</div>}
                  renderInput={(params) => <MDInput {...params} />}
                />
                {/* <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography> */}
                {sortButton && <SortButton sortOptions={sortOptions} />}
                {extraSearch && extraSearch}
                {canSearch && (
                  <>
                    <MDInput
                      placeholder="Tìm Kiếm ..."
                      value={search}
                      size="small"
                      fullWidth
                      onChange={handleSearchChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          onSearch && onSearch(search);
                        }
                      }}
                    />
                    <MDButton sx={{ flexShrink: 0 }} onClick={() => onSearch && onSearch(search)}>
                      Tìm kiếm
                    </MDButton>
                  </>
                )}
                {!!advancedSearch && (
                  <MDButton
                    sx={{ flexShrink: 0 }}
                    onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                  >
                    <Icon>filter_alt</Icon>
                  </MDButton>
                )}
              </MDBox>
            )}
            <MDBox ml="auto" sx={{ display: "flex", gap: 0.5 }}>
              {/*isDeleted Status select*/}
              {user?.role?.name?.toLowerCase() === "superadmin" && (
                <FormControl>
                  <InputLabel id="demo-simple-select-label" size="small">
                    Trạng Thái xóa
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={isDeleted}
                    sx={{
                      height: "100%",
                      width: "80px",
                    }}
                    onChange={(event) => {
                      setIsDeleted(event?.target?.value);
                      setSearchParams({ isDeletedRecords: event?.target?.value });
                    }}
                  >
                    <MenuItem value={false}>
                      <MDBadge
                        badgeContent="False"
                        color={"success"}
                        variant="gradient"
                        size="sm"
                      />
                    </MenuItem>
                    <MenuItem value={true}>
                      <MDBadge badgeContent="True" color={"error"} variant="gradient" size="sm" />
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              {canCreate && <Modal action="create">{canCreate}</Modal>}
              {importModal && <Modal action="import">{importModal}</Modal>}
              {/*Refresh button*/}
              {refreshButton && (
                <MDButton
                  sx={{
                    flexShrink: 0,
                  }}
                  onClick={handleRefresh}
                  disabled={isFetching > 0}
                  size="small"
                >
                  <Icon>refresh</Icon>
                  Tải Lại
                </MDButton>
              )}
              {/* EXPORT */}
              {renderExport()}
            </MDBox>
          </MDBox>
          {showAdvancedSearch && (
            <MDBox
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              p={3}
              gap="20px"
              width="100%"
              border="1px solid"
              flexWrap="wrap"
            >
              {advancedSearch}
            </MDBox>
          )}
        </>
      ) : null}
      {/*Row selected*/}
      {selectedRows.length > 0 && (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDTypography variant="button" color="secondary" fontWeight="regular">
            Đã chọn {selectedRows.length} kết quả
          </MDTypography>
          {/*Custom action with render props*/}

          {rowSelection?.render && (
            <MDBox display="flex" gap={2}>
              {rowSelection.render(selectedRows)}
            </MDBox>
          )}
        </MDBox>
      )}
      <TableContainer sx={{ boxShadow: "none", height: "60vh" }} ref={tableContainerRef}>
        {/*LOADING SPINNER*/}
        {isFetching > 0 && <LinearProgress />}

        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {/*Selection props*/}
                {rowSelection && (
                  <DataTableHeadCell
                    sorted={false}
                    width={"auto"}
                    align={"center"}
                    compact={compact}
                    selection
                  >
                    <Checkbox checked={selectAll} onChange={handleSelectAllClick} />
                  </DataTableHeadCell>
                )}
                {headerGroup.headers.map((column, idx) => {
                  return (
                    <DataTableHeadCell
                      key={idx}
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                      compact={compact}
                      sticky={column?.id === "action"}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  );
                })}
              </TableRow>
            ))}
          </MDBox>
          <TableBody
            {...getTableBodyProps()}
            sx={{
              position: "relative",
            }}
          >
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow key={key} {...row.getRowProps()}>
                  {/*selection*/}
                  {rowSelection && (
                    <DataTableBodyCell align={"center"} compact={compact}>
                      <Checkbox
                        checked={selectedRows.includes(row.id)}
                        onChange={() => handleCheckboxClick(row.id)}
                      />
                    </DataTableBodyCell>
                  )}
                  {row.cells.map((cell, idx) => {
                    return (
                      <DataTableBodyCell
                        key={idx}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                        compact={compact}
                        sticky={cell.column?.id === "action"}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Hiển thị {entriesStart} đến {entriesEnd} của {rows.length} kết quả
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination
                item
                onClick={() => {
                  setInitialPageIndex(pageIndex - 1);
                  previousPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination
                item
                onClick={() => {
                  setInitialPageIndex(pageIndex + 1);
                  nextPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
