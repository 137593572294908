import { useRequest } from "../context/RequestContext";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/punishments/punishmentsData";
import useCanAccess from "./useCanAccess";
import { punishmentActions } from "../constants/actions";
export const punishmentQueryKey = "punishment";

export const usePunishment = ({ isDeleted = false } = {}) => {
  const { makeRequest } = useRequest();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [searchValue, setSearchValue] = useState("");
  const [user, setUser] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [sortBy, setSortBy] = useState("createdTime");
  const { can } = useCanAccess();
  // const [rows, setRows] = useState([]);
  const _transformPagination = {
    ...pagination,
    pageIndex: pagination.pageIndex + 1,
    content: searchValue,
    member: user,
    startDate: dateRange[0] || "",
    endDate: dateRange[1] || "",
    sortBy,
  };
  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });
  }, [sortBy, dateRange, user, searchValue]);
  const { data, isLoading, error } = useQuery({
    queryKey: [
      punishmentQueryKey,
      _transformPagination.pageIndex + 1,
      _transformPagination.pageSize,
      searchValue,
      user,
      dateRange,
      isDeleted,
      sortBy,
    ],
    //   TODO :fix search
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${punishmentQueryKey}/getPaging?isDeleted=${isDeleted}&${new URLSearchParams(
          _transformPagination
        ).toString()}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      const punishment = data?.data;
      const rows = getRows({ punishment });

      const pagination = {
        pageIndex: data?.pageIndex - 1 <= 0 ? 0 : data?.pageIndex - 1,
        pageSize: data?.pageSize,
        totalPages: data?.totalPages,
        totalItems: data?.totalItems,
      };
      return {
        rows,
        pagination,
      };
    },
    enabled: can(punishmentActions.getPagingPunishments),
    placeholderData: (previousData, previousQuery) => previousData,
  });
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handlePaginationChange = (pageIndex, pageSize) => {
    setPagination({ pageIndex, pageSize });
  };

  const handleUserChange = (value) => {
    setUser(value);
  };

  const handleDateRangeChange = (value) => {
    setDateRange(value);
  };
  return {
    data,
    isLoading,
    error,
    handlePaginationChange,
    pagination,
    handleSearchChange,
    handleUserChange,
    handleDateRangeChange,
    setSortBy,
  };
};
