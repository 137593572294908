import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/ads-account/adsAccountData";
import useCanAccess from "./useCanAccess";
import { adsAccountActions } from "../constants/actions";
export const adsAccountQueryKey = "adsAccount";

export const useAdsAccount = ({ isDeleted = false } = {}) => {
  const { makeRequest } = useRequest();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [searchValue, setSearchValue] = useState({});
  const { can } = useCanAccess();

  let _transformPagination = {
    ...pagination,
    pageIndex: pagination.pageIndex + 1,
  };
  const { data, isLoading, error } = useQuery({
    queryKey: [adsAccountQueryKey, _transformPagination, searchValue, isDeleted],
    queryFn: async () => {
      const searchParams = Object.keys(searchValue).reduce((acc, key) => {
        if (searchValue[key]) {
          acc[key] = searchValue[key];
        }
        return acc;
      }, {});
      let request = {
        method: method.get,
        endpoint: `/${adsAccountQueryKey}/getPaging?isDeleted=${isDeleted}&${new URLSearchParams({
          ..._transformPagination,
          ...searchParams,
        }).toString()}`,
      };

      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled: can(adsAccountActions.getPagingAdsAccount),
    placeholderData: (previousData, previousQuery) => previousData,
  });

  const dataPagination = useMemo(() => {
    let adsAccount = data?.data || [];
    const rows = getRows({ adsAccount });

    const pagination = {
      pageIndex: data?.pageIndex - 1 <= 0 ? 0 : data?.pageIndex - 1,
      pageSize: data?.pageSize,
      totalPages: data?.totalPages,
    };
    return {
      rows,
      pagination,
    };
  }, [data]);
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handlePaginationChange = (pageIndex, pageSize) => {
    setPagination({ pageIndex, pageSize });
  };

  return {
    data,
    dataPagination,
    isLoading,
    error,
    handlePaginationChange,
    pagination,
    handleSearchChange,
  };
};
