import React, { useState } from "react";
import { Select, MenuItem, ListItemIcon, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const BankingDateSort = ({ onChange }) => {
  const [sortOption, setSortOption] = useState("-bankingTime");

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    onChange && onChange(event.target.value);
  };

  const IconStyle = {
    color: "text.main",
  };
  return (
    <Select
      sx={{ padding: "8px" }}
      value={sortOption}
      onChange={handleSortChange}
      renderValue={() => "Sắp xếp"}
    >
      <MenuItem value="-bankingTime">
        <ListItemIcon sx={IconStyle}>
          <ArrowDownwardIcon fontSize="small" />
        </ListItemIcon>
        Thời gian dao dịch giảm dần
      </MenuItem>
      <MenuItem value="bankingTime">
        <ListItemIcon sx={IconStyle}>
          <ArrowUpwardIcon fontSize="small" />
        </ListItemIcon>
        Thời gian dao dịch tăng dần
      </MenuItem>
      <MenuItem value="-createdTime">
        <ListItemIcon sx={IconStyle}>
          <ArrowDownwardIcon fontSize="small" />
        </ListItemIcon>
        Thời gian tạo giảm dần
      </MenuItem>
      <MenuItem value="createdTime">
        <ListItemIcon sx={IconStyle}>
          <ArrowUpwardIcon fontSize="small" />
        </ListItemIcon>
        Thời gian tạo tăng dần
      </MenuItem>
    </Select>
  );
};

export default BankingDateSort;
