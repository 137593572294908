import { isEmpty } from "lodash/lang";
import { isNumber, isObject, isString } from "lodash";
import { HtmlToText } from "./html-to-text";

export const deepRemoveUndefined = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (isNumber(obj[key])) return obj[key];

    if (isString(obj[key]) && isEmpty(obj[key])) {
      delete obj[key];
    }

    if (isObject(obj[key])) {
      deepRemoveUndefined(obj[key]);
    }
  });
  return obj;
};

export const isEmptyHtml = (html) => {
  if (!html) return true;
  // check html is contain img tag
  if (html.includes("<img")) return false;
  const content = HtmlToText(html, []);

  return isEmpty(content);
};
