import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useRequest } from "../context/RequestContext";
import { method } from "../libs/fetchRequestUtils";
import { bankingQueryKey } from "./useBanking";
import useCanAccess from "./useCanAccess";
import { bankingActions } from "../constants/actions";
export const bankingStatisticsQueryKey = "banking/statistics";

// let result = {};
// const todayStats = data.today;
// const previousDayStats = data.previousDay;
//
// // Sum of totalReceivedMoney and totalSentMoney for today
// const todayTotal = todayStats.totalReceivedMoney + todayStats.totalSentMoney;
// const previousDayTotal = previousDayStats.totalReceivedMoney + previousDayStats.totalSentMoney;
//
// result.todayTotal = todayTotal;
// result.todayReceivedMoney = todayStats.totalReceivedMoney;
// result.todaySentMoney = todayStats.totalSentMoney;
//
// // Differences between today and previous day
// const diffReceivedMoney = todayStats.totalReceivedMoney - previousDayStats.totalReceivedMoney;
// const diffSentMoney = todayStats.totalSentMoney - previousDayStats.totalSentMoney;
//
// // Percentage difference compared with the previous day
// const percentDiffReceived = calculatePercentageChange(
//     todayStats.totalReceivedMoney,
//     previousDayStats.totalReceivedMoney
// );
// const percentDiffSent = calculatePercentageChange(
//     todayStats.totalSentMoney,
//     previousDayStats.totalSentMoney
// );
// const percentDiffTotal = calculatePercentageChange(todayTotal, previousDayTotal);
// const percentDiffDiffReceived = calculatePercentageChange(diffReceivedMoney, 0);
//
// result.diffReceivedMoney = diffReceivedMoney;
// result.diffSentMoney = diffSentMoney;
// result.percentDiffReceived = percentDiffReceived + "%";
// result.percentDiffSent = percentDiffSent + "%";
// result.percentDiffTotal = percentDiffTotal + "%";
// result.percentDiffDiffReceived = percentDiffDiffReceived + "%";
//
// return result;
function generateStatistics(data) {
  let result = {};
  const todayStats = data.today;
  const previousDayStats = data.previousDay;
  // Sum of totalReceivedMoney and totalSentMoney for today and previous day
  const todayTotal = todayStats.totalReceivedMoney + todayStats.totalSentMoney;
  const previousDayTotal = previousDayStats.totalReceivedMoney + previousDayStats.totalSentMoney;
  result.todayTotal = todayTotal;
  result.todayReceivedMoney = todayStats.totalReceivedMoney;
  result.todaySentMoney = todayStats.totalSentMoney;
  // Differences between todaySentMoney and todayReceivedMoney
  const todayDiffMoney = todayStats.totalSentMoney - todayStats.totalReceivedMoney;
  const previousDayDiffMoney =
    previousDayStats.totalSentMoney - previousDayStats.totalReceivedMoney;
  result.todayDiffMoney = todayDiffMoney;

  // Percentage difference compared with the previous day
  const percentDiffTotal = calculatePercentageChange(todayTotal, previousDayTotal);
  const percentDiffDiffMoney = calculatePercentageChange(todayDiffMoney, previousDayDiffMoney);
  const percentDiffReceivedMoney = calculatePercentageChange(
    todayStats.totalReceivedMoney,
    previousDayStats.totalReceivedMoney
  );
  const percentDiffSentMoney = calculatePercentageChange(
    todayStats.totalSentMoney,
    previousDayStats.totalSentMoney
  );

  result.percentDiffTotal = percentDiffTotal + "%";
  result.percentDiffDiffMoney = percentDiffDiffMoney + "%";
  result.percentDiffReceivedMoney = percentDiffReceivedMoney + "%";
  result.percentDiffSentMoney = percentDiffSentMoney + "%";

  return result;
}

function calculatePercentageChange(todayValue, previousDayValue) {
  if (previousDayValue === 0) {
    return (todayValue * 100).toFixed(2);
  }
  const change = ((todayValue - previousDayValue) / previousDayValue) * 100;
  return change.toFixed(2); // round to two decimal places
}
const UseBankingStatistics = ({ cards }) => {
  const { makeRequest, dispatchError } = useRequest();
  const { can } = useCanAccess();

  const [date, setDate] = useState(() => {
    return moment().format("YYYY-MM-DD");
  });
  const [rangeDate, setRangeDate] = useState([moment().toDate(), moment().toDate()]);
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [bankingStatisticsQueryKey, date, cards, rangeDate[0], rangeDate[1]],
    queryFn: async () => {
      // if (date) {
      //   const currentDate = moment(date).format("YYYY-MM-DD");
      //   const previousDate = moment(date).subtract(1, "days").format("YYYY-MM-DD");
      //   let endpoint = `/${bankingStatisticsQueryKey}?time=${currentDate}`;
      //   if (cards) {
      //     endpoint += `&cards=${cards}`;
      //   }
      //   const currentDateRequest = {
      //     method: method.get,
      //     endpoint,
      //   };
      //   const previousDataRequest = {
      //     method: method.get,
      //     endpoint: `/${bankingStatisticsQueryKey}?time=${previousDate}`,
      //   };
      //   const currentDateData = await makeRequest(currentDateRequest);
      //   const previousData = await makeRequest(previousDataRequest);
      //   const timeRequest = moment(date).format("DD/MM/YYYY");
      //   return {
      //     today: currentDateData,
      //     previousDay: previousData,
      //     timeRequest,
      //   };
      // } else if (rangeDate[0] && rangeDate[1]) { }

      let startDate = moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate = moment(rangeDate[1]).format("YYYY-MM-DD");
      // add default if not set startDate and endDate
      if (startDate === "Invalid date" || endDate === "Invalid date") {
        const currentDate = moment().format("YYYY-MM-DD");
        startDate = currentDate;
        endDate = currentDate;
      }
      let endpoint = `/${bankingStatisticsQueryKey}?startDate=${startDate}&endDate=${endDate}`;
      if (cards) {
        endpoint += `&cards=${cards}`;
      }
      const data = await makeRequest({
        method: method.get,
        endpoint,
      });
      return {
        today: data,
        previousDay: data,
      };
    },
    select: (data) => {
      if (!data) return;
      const statistics = generateStatistics(data);
      return {
        ...statistics,
        timeRequest: data.timeRequest,
      };
    },
    enabled: can(bankingActions.bankingStatistics),
    placeholderData: () => null,
    // refetchInterval: 60000, //  1 minutes
  });
  return {
    data,
    date,
    setDate,
    loading: isLoading || isFetching,
    refetch,
    setRangeDate,
    rangeDate,
  };
};

export default UseBankingStatistics;
