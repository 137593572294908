import { useMaterialUIController } from "context";
import Spinner from "../Spinner/Spinner";
import styles from "./SpinnerFullPage.module.css";

function SpinnerFullPage() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <div
      style={{
        height: "calc(100vh - 0.01rem)",
        backgroundColor: `${darkMode ? "#1a2035" : "#f0f2f5"}`,
      }}
    >
      <Spinner />
    </div>
  );
}

export default SpinnerFullPage;
