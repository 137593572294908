import useAdsAccountCondition from "../../hooks/useAdsAccountCondition";
import MDInput from "../../components/MDInput";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useState } from "react";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import Modal from "@mui/material/Modal";
import { modalWrapperStyle } from "../../components/Modal";
import Icon from "@mui/material/Icon";

const filter = createFilterOptions();

export const AdsAccountConditionSelect = ({
  inputStyle = {},
  initialValue = null,
  required = true,
  label = "Tình trạng",
  onChange,
}) => {
  const [value, setValue] = React.useState(initialValue);

  const [conditions, setConditions] = useState(initialValue);
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    title: "",
    year: "",
  });
  const {
    data: conditionsData,
    create,
    isCreating,
    isLoading,
    isFetching,
    handleDelete,
  } = useAdsAccountCondition();
  const handleClose = () => {
    setDialogValue({
      title: "",
      year: "",
    });
    toggleOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      title: dialogValue.title,
      year: parseInt(dialogValue.year, 10),
    });
    handleClose();
  };

  return (
    <>
      <Autocomplete
        value={value}
        loading={isLoading}
        loadingText={"Loading..."}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              const body = {
                title: newValue,
              };
              create(body).then((data) => {
                const result = data?.data;
                setValue(result);
                onChange && onChange(result);
              });
            });
          } else if (newValue && newValue?.inputValue) {
            const body = {
              title: newValue.inputValue,
            };
            create(body).then((data) => {
              const result = data?.data;
              setValue(result);
              onChange && onChange(result);
            });
          } else {
            setValue(newValue);
            onChange && onChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              title: `Thêm mới "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        options={conditionsData}
        getOptionLabel={(option) => {
          // e.g. value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
            >
              {option.title}
              {/* delete button */}
              {option._id && (
                <MDButton
                  variant="contained"
                  color="error"
                  iconOnly
                  size="small"
                  onClick={(e) => {
                    const id = option._id;
                    if (id) {
                      handleDelete(id).then((data) => {
                        setValue((prev) => {
                          if (prev._id === id) {
                            return null;
                          }
                          return prev;
                        });
                      });
                    }
                  }}
                >
                  <Icon>clear</Icon>
                </MDButton>
              )}
            </li>
          );
        }}
        sx={{
          ...inputStyle,
          width: "100%",
        }}
        freeSolo
        renderInput={(params) => {
          return (
            <MDInput
              {...params}
              label={label}
              inputProps={{
                ...params.inputProps,
                required,
              }}
            />
          );
        }}
      />
      <Modal open={open} onClose={handleClose}>
        <MDBox
          component="form"
          sx={{
            ...modalWrapperStyle,
            width: "600px",
          }}
          onSubmit={handleSubmit}
        >
          <DialogTitle>Thêm tình trạng mới</DialogTitle>
          <DialogContent>
            <MDInput
              autoFocus
              id="name"
              value={dialogValue.title}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  title: event.target.value,
                })
              }
              sx={{
                width: "100%",
                marginTop: "20px",
              }}
              label="title"
              type="text"
            />
            <MDInput
              value={dialogValue.year}
              sx={{
                width: "100%",
                marginTop: "20px",
              }}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  year: event.target.value,
                })
              }
              label="year"
              type="number"
            />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose}>Cancel</MDButton>
            <MDButton type="submit">Add</MDButton>
          </DialogActions>
        </MDBox>
      </Modal>
    </>
  );
};
