import Papa from "papaparse";

export const parseCsvToJson = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        resolve(results);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

export const deepRmField = (obj, field) => {
  if (obj[field]) {
    delete obj[field];
  }
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object") {
      deepRmField(obj[key], field);
    }
  });

  return obj;
};
const deepParseObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "string") {
      try {
        obj[key] = JSON.parse(obj[key]);
      } catch (error) {
        // do nothing
      }
    } else if (typeof obj[key] === "object") {
      obj[key] = deepParseObject(obj[key]);
    }
  });
  return obj;
};
export const deepParseArray = (arr) => {
  return arr.map((item) => {
    if (typeof item === "object") {
      const newItem = {};
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === "string") {
          try {
            newItem[key] = JSON.parse(item[key]);
          } catch (error) {
            newItem[key] = item[key];
          }
        } else if (typeof item[key] === "object") {
          newItem[key] = deepParseObject(item[key]);
        }
      });
      return newItem;
    } else {
      return item;
    }
  });
};
