import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MultipleSelectChip from "components/MultipleSelectChip";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useRequest } from "context/RequestContext";
import { memo, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import StatusSelect from "components/StatusSelect";
import FormSelect from "components/FormSelect";
import { useQueryClient } from "@tanstack/react-query";
import { userQueryKey } from "../../hooks/useUser";
import { useDepartment } from "../../hooks/useDepartments";
import { useRole } from "../../hooks/useRole";
import Spinner from "../../components/Spinner/Spinner";

const avatarStyle = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
};

const inputStyle = {
  marginTop: "10px",
};

const context = "user";

function UpdateForm({ user, create = false }) {
  const { updateRequest, createRequest, dispatchError } = useRequest();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const queryClient = useQueryClient();
  const { data: department, isLoading: isLoadingDepartment } = useDepartment();
  const { data: role, isLoading: isLoadingRole } = useRole();
  const [isLoading, setIsLoading] = useState(false);
  const {
    _id: id,
    role: userRole,
    departments: userDepartments,
    fullName: userFullName,
    status: userStatus,
  } = user || {
    _id: "",
    role: { _id: (role && role.find((record) => record.default === true)?._id) || "" },
    departments: [],
    fullName: "",
    status: 1,
  };

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState(userFullName);
  const [avatar, setAvatar] = useState("");
  const [status, setStatus] = useState(userStatus);
  const [roleSelected, setRoleSelected] = useState(userRole._id);
  const [departmentSelected, setDepartmentSelected] = useState([
    ...userDepartments.map((record) => record._id),
  ]);
  useEffect(() => {
    setRoleSelected(userRole._id);
  }, [role]);
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeRole = (event) => {
    setRoleSelected(event.target.value);
  };

  const handleDeparmentChange = (value) => {
    setDepartmentSelected(value);
  };

  async function handleSubmit(e) {
    try {
      setIsLoading(true);
      e.preventDefault();
      const body = {
        username: userName.toLowerCase() || null,
        password: password || null,
        fullName,
        avatar,
        status,
        role: roleSelected,
        departments: departmentSelected,
      };
      // check username contain space
      if (body.username?.includes(" ")) {
        dispatchError({
          message: "Tên đăng nhập không được chứa khoảng trắng",
        });
        return;
      }

      if (!create) {
        await updateRequest({ context, id, body });
        await queryClient.invalidateQueries({
          queryKey: [userQueryKey],
          type: "active",
        });
      } else {
        await createRequest({ context, body });
        await queryClient.invalidateQueries({
          queryKey: [userQueryKey],
          type: "active",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoadingRole || isLoadingDepartment) return <Spinner />;

  return (
    <MDBox component="form" onSubmit={handleSubmit}>
      {create && (
        <>
          <MDInput
            fullWidth
            id="username"
            label="User Name"
            variant="outlined"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            sx={inputStyle}
            required
          />
        </>
      )}
      <MDInput
        fullWidth
        id="password"
        label="Mật Khẩu"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={inputStyle}
        required={create}
        helperText={!create && "Để trống nếu không muốn thay đổi mật khẩu"}
      />
      <MDInput
        fullWidth
        id="fullName"
        label="Full Name"
        variant="outlined"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
        sx={inputStyle}
      />
      <MDButton
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        color={sidenavColor}
        sx={{ marginTop: "20px" }}
      >
        Nhập Avatar
        <MDInput
          type="file"
          sx={avatarStyle}
          onChange={(e) => {
            setAvatar(e.target.files[0]);
          }}
        />
      </MDButton>
      <StatusSelect
        context="user"
        title="Trạng Thái"
        status={status}
        onChange={handleChangeStatus}
      />
      <FormSelect
        title="Vai Trò"
        data={role}
        dataSelected={roleSelected}
        onChange={handleChangeRole}
        required
      />
      {!isLoadingDepartment && (
        <MultipleSelectChip
          data={department}
          dataSelected={departmentSelected}
          onChange={handleDeparmentChange}
        />
      )}
      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {isLoading ? "Đang cập nhật" : !create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default memo(UpdateForm);
