import { getColumns } from "./simsData";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { Can } from "../../hooks/useCanAccess";
import { simActions } from "../../constants/actions";
import { simQueryKey, useSim } from "../../hooks/useSim";
import TableLayout from "../TableLayout";
import SimExtraSearch from "./SimExtraSearch";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_SORT } from "../../utils/constants";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function SimsTable() {
  const [searchParams] = useSearchParams();

  const SIM_SORT_OPTIONS = [
    { value: "-createdTime", label: "Thời gian tạo giảm dần", Icon: ArrowDownwardIcon },
    { value: "createdTime", label: "Thời gian tạo tăng dần", Icon: ArrowUpwardIcon },
    { value: "-updatedTime", label: "Thời gian cập nhật giảm dần", Icon: ArrowDownwardIcon },
    { value: "updatedTime", label: "Thời gian cập nhật tăng dần", Icon: ArrowUpwardIcon },
    { value: "-expiryDate", label: "Thời gian hết hạn giảm dần", Icon: ArrowDownwardIcon },
    { value: "expiryDate", label: "Thời gian hết hạn tăng dần", Icon: ArrowUpwardIcon },
  ];

  const {
    rows,
    handleSearchChange,
    isLoading,
    handleDateChange,
    data,
    handleGroupChange,
    handleDepartmentChange,
  } = useSim({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
    sort: searchParams.get("sort") || DEFAULT_SORT,
  });
  const columns = getColumns();

  const handleChange = (value, type) => {
    if (type === "date") {
      handleDateChange(value);
    }
    if (type === "sim") {
      handleSearchChange(value, type);
    }
    if (type === "groups") {
      handleGroupChange(value);
    }
    if (type === "departments") {
      handleDepartmentChange(value);
    }
  };

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="CHECK SIM VÀ SỐ ĐIỆN THOẠI"
      onSearch={handleSearchChange}
      sortOptions={SIM_SORT_OPTIONS}
      canCreate={
        <Can
          permissions={simActions.insertSim}
          fallback={"Bạn không có quyền thêm sim và số điện thoại"}
        >
          <UpdateForm create={true} />
        </Can>
      }
      extraSearch={<SimExtraSearch sims={data} onChange={handleChange} />}
      canSearch={false}
      canExport={simQueryKey}
      refreshButton={true}
      compact={true}
    />
  );
}

export default SimsTable;
