import { memo, useEffect, useMemo, useState } from "react";
import { getColumns, getRows } from "./departmentsData";
import TableLayout from "layouts/TableLayout";
import { useRequest } from "context/RequestContext";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import TableServerPaginateLayout from "../TableServerPaginateLayout";
import { departmentQueryKey, useDepartment } from "../../hooks/useDepartments";
import { departmentActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import { useParams, useSearchParams } from "react-router-dom";
import MembersTable from "../members/MembersTable";
import { DEFAULT_SORT } from "../../utils/constants";

export function DepartmentsTable() {
  const { departmentId, groupId } = useParams();
  const [searchParams] = useSearchParams();

  const { rows, isLoading, error, handleSearchChange } = useDepartment({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
    sort: searchParams.get("sort") || DEFAULT_SORT,
  });
  const columns = useMemo(() => {
    return getColumns();
  }, []);

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="Bộ Phận"
      onSearch={handleSearchChange}
      canCreate={
        <Can
          permissions={departmentActions.insertDepartment}
          fallback={"Bạn không có quyền thêm bộ phận"}
        >
          <UpdateForm create={true} />
        </Can>
      }
      canSearch={false}
      canExport={departmentQueryKey}
      refreshButton={true}
      compact={true}
    />
  );
}

const HOCDepartmentsTable = (WrapperComponent) => {
  return () => {
    const { departmentId, groupId } = useParams();
    const key = `${departmentQueryKey}-${departmentId}-${groupId}`;
    return <WrapperComponent key={key} />;
  };
};

export default HOCDepartmentsTable(MembersTable);
