import { API_URL, method } from "../../libs/fetchRequestUtils";
const ASSET_URL = process.env.REACT_APP_ASSET_URL;

var toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];

const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      image: imageHandler,
    },
  },
};
export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const authToken = localStorage.getItem("authToken");
  const requestOptions = {
    method: method.post,
    headers: {
      Authorization: "Bearer " + authToken,
    },
    body: formData,
  };
  const res = await fetch(API_URL + `/upload/uploadFile/`, requestOptions);

  if (String(res.status).startsWith(2)) {
    const data = await res.json();
    return data?.data;
  }

  return null;
};
function imageHandler(e) {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    if (file) {
      // Call a function to upload the file here (e.g., to your server)
      const url = await uploadFile(file);
      const embed = ASSET_URL + url;
      const range = this.quill.getSelection();
      if (range) {
        // If there is a current cursor position, insert there
        this.quill.insertEmbed(range.index, "image", embed);
      } else {
        // Otherwise, insert at the end of the content
        this.quill.insertEmbed(this.quill.getLength(), "image", embed);
      }
    }
  };
}

function QuillToolbar({ className, onFileUpload }) {
  return (
    <div id="toolbar" className={className}>
      <span className="ql-formats">
        <select className="ql-font" defaultValue="arial">
          <option value="arial">Arial</option>
          <option value="comic-sans">Comic Sans</option>
          <option value="courier-new">Courier New</option>
          <option value="georgia">Georgia</option>
          <option value="helvetica">Helvetica</option>
          <option value="lucida">Lucida</option>
        </select>
        <select className="ql-size" defaultValue="medium">
          <option value="extra-small">Size 1</option>
          <option value="small">Size 2</option>
          <option value="medium">Size 3</option>
          <option value="large">Size 4</option>
        </select>
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
      </span>
      <span className="ql-formats">
        <button className="ql-script" value="super" />
        <button className="ql-script" value="sub" />
        <button className="ql-blockquote" />
        <button className="ql-direction" />
      </span>
      <span className="ql-formats">
        <select className="ql-align" />
        <select className="ql-color" />
        <select className="ql-background" />
      </span>
      <span className="ql-formats">
        <button className="ql-link" />
        <button type="button" onClick={onFileUpload}>
          File
        </button>

        <button className="ql-image" />
        {/*<button className="ql-video" />*/}
      </span>
      <span className="ql-formats">
        <button className="ql-formula" />
        <button className="ql-code-block" />
        <button className="ql-clean" />
      </span>
    </div>
  );
}

export { QuillToolbar, modules };
