import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { API_URL, method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/roles/rolesData";
import useCanAccess from "./useCanAccess";
import { roleActions } from "../constants/actions";
export const roleQueryKey = "role";

export const useRole = () => {
  const { makeRequest } = useRequest();
  const [searchValue, setSearchValue] = useState("");
  const { can } = useCanAccess();

  const { data, isLoading, error } = useQuery({
    queryKey: [roleQueryKey],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${roleQueryKey}/getAll`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled: can(roleActions.getAllRoles),
    placeholderData: (previousData, previousQuery) => previousData || [],
  });
  const rows = useMemo(() => {
    try {
      const filters = {
        name: searchValue,
      };
      const role = data;

      return getRows({ role, filters });
    } catch (e) {
      return [];
    }
  }, [data, searchValue]);
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  return {
    data,
    rows,
    isLoading,
    error,
    handleSearchChange,
  };
};
