import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useState } from "react";

function SelectRenderProp({ title, render, onChange, data, defaultValue, ...rest }) {
  const [selected, setSelected] = useState(defaultValue);

  function handleChange(e) {
    setSelected(e.target.value);
    onChange?.(e.target.value);
  }

  return (
    <div>
      <FormControl sx={{ minWidth: 150 }} {...rest}>
        <InputLabel id="select-standard-label">{title}</InputLabel>
        <Select
          labelId="select-standard-label"
          id={`select-standard-${title}`}
          label={title}
          value={selected}
          onChange={handleChange}
          sx={{ padding: "12px" }}
          defaultValue={defaultValue}
        >
          {data.map(render)}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectRenderProp;
