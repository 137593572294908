import { useRequest } from "context/RequestContext";
import React, { useState } from "react";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MultipleSelectChip from "components/MultipleSelectChip";
import { useQueryClient } from "@tanstack/react-query";
import { adsAccountQueryKey } from "../../hooks/useAdsAccount";
import { useUser } from "../../hooks/useUser";
import Spinner from "../../components/Spinner/Spinner";
import { useCard } from "../../hooks/useCard";
import Autocomplete from "@mui/material/Autocomplete";
import { useGroup } from "../../hooks/useGroup";
import useAdsAccountCondition from "../../hooks/useAdsAccountCondition";
import { AdsAccountConditionSelect } from "./AdsAccountConditionSelect";
import { useCountry } from "../../hooks/useCountry";
import { convertGmtOffsetToTime } from "../../utils/helpers";
import { useMember } from "../../hooks/useMember";
import QuillInputFiled from "../../components/QuillInputField/QuillInputFiled";

const textAreaStyle = {
  width: "320px",
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: 1.5,
  padding: "12px",
  borderRadius: "5px 5px 0 5px",
  marginTop: "20px",
};

const inputStyle = {
  marginTop: "20px",
  "& .MuiOutlinedInput-root": {
    padding: 0,
    "& .MuiAutocomplete-input": {
      padding: "0.75rem",
    },
  },
};

const context = "adsAccount";

function UpdateForm({ adsAccount, create = false }) {
  const [accountID, setAccountID] = useState(adsAccount?.accountID || "");
  const [timeRegion, setTimeRegion] = useState(adsAccount?.country || null);
  const [condition, setCondition] = useState(adsAccount?.condition || null);
  const [via, setVia] = useState(adsAccount?.via || "");
  const [members, setMembers] = useState(adsAccount?.members || []);
  const [cards, setCards] = useState(adsAccount?.cards || []);
  const [group, setGroup] = useState(adsAccount?.group || null);
  const [accountNumber1, setAccountNumber1] = useState(adsAccount?.accountNumber1 || "");
  const [accountNumber2, setAccountNumber2] = useState(adsAccount?.accountNumber2 || "");
  const [note, setNote] = useState(adsAccount?.note || "");
  const [isLoading, setIsLoading] = useState(false);

  const { updateRequest, createRequest } = useRequest();
  const [controller] = useMaterialUIController();
  const queryClient = useQueryClient();
  const { data: memberData, isLoadingAction } = useMember();
  const { data: groupData, isLoading: isLoadingGroup } = useGroup();
  const { data: card, isLoadingCard } = useCard();
  const { data: countryData, isLoading: isLoadingCountry } = useCountry();
  const { sidenavColor } = controller;
  const handleChangeUser = (value) => {
    setMembers(value);
  };

  const handleChangeCard = (value) => {
    setCards(value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const body = {
        accountID,
        country: timeRegion?._id,
        condition,
        members,
        cards,
        group: group?._id,
        via,
        accountNumber1,
        accountNumber2,
        note,
      };
      body.members = body.members?.map((member) => member?.id || member);
      body.cards = body.cards?.map((card) => card?.id || card);
      body.group = body.group?._id || body.group;
      body.condition = body.condition?._id || body.condition;

      if (!create) {
        await updateRequest({ context, id: adsAccount._id, body });
        await queryClient.invalidateQueries({
          queryKey: [adsAccountQueryKey],
          type: "active",
        });
      } else {
        await createRequest({ context, body });
        await queryClient.invalidateQueries({
          queryKey: [adsAccountQueryKey],
          type: "active",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoadingAction || isLoadingCard) return <Spinner />;

  return (
    <MDBox component="form" width={600} onSubmit={handleSubmit}>
      <MDInput
        fullWidth
        id="accountId"
        label="ID Tài Khoản"
        variant="outlined"
        value={accountID}
        onChange={(e) => setAccountID(e.target.value)}
        sx={inputStyle}
        required
      />
      {/*<MDInput*/}
      {/*  fullWidth*/}
      {/*  label="Múi Giờ"*/}
      {/*  variant="outlined"*/}
      {/*  value={timeRegion}*/}
      {/*  onChange={(e) => setTimeRegion(e.target.value)}*/}
      {/*  sx={inputStyle}*/}
      {/*  required*/}
      {/*/>*/}
      <Autocomplete
        required
        disablePortal
        options={memberData || []}
        multiple
        defaultValue={members}
        isOptionEqualToValue={(option, value) => {
          return option?._id === value?._id;
        }}
        value={members}
        sx={{
          ...inputStyle,
          width: "100%",
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            label="Thành viên"
            inputProps={{
              ...params.inputProps,
              required: members.length === 0,
            }}
            size="medium"
            required
          />
        )}
        getOptionLabel={(option) => {
          return `${option?.name}`;
        }}
        onChange={(e, newValue) => {
          setMembers(newValue);
        }}
      />
      <Autocomplete
        required
        disablePortal
        options={countryData || []}
        sx={{
          ...inputStyle,
          width: "100%",
        }}
        autoHighlight
        value={timeRegion}
        renderOption={(props, option) => (
          <MDBox component="li" sx={{ "& > p": { mr: 2, flexShrink: 0 } }} {...props}>
            <p>{option.flag}</p>
            {`${option.name} - (${convertGmtOffsetToTime(option?.timeRegion)})`}
          </MDBox>
        )}
        renderInput={(params) => (
          <MDInput
            {...params}
            label="Múi giờ"
            inputProps={{
              ...params.inputProps,
              required: true,
            }}
            required
          />
        )}
        getOptionLabel={(option) => {
          return `${option?.name}`;
        }}
        onChange={(e, newValue) => {
          setTimeRegion(newValue);
        }}
      />

      <MDInput
        fullWidth
        id="via"
        label="VIA"
        variant="outlined"
        value={via}
        onChange={(e) => setVia(e.target.value)}
        sx={inputStyle}
      />
      <Autocomplete
        disablePortal
        options={card || []}
        multiple
        defaultValue={cards}
        sx={{
          ...inputStyle,
          width: "100%",
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            label="Thẻ"
            inputProps={{
              ...params.inputProps,
              required: cards.length === 0,
            }}
            required
          />
        )}
        getOptionLabel={(option) => {
          return `${option?.account} - ${option?.accountNumber || ""}`;
        }}
        onChange={(e, newValue) => {
          setCards(newValue);
        }}
      />
      <Autocomplete
        disablePortal
        options={groupData || []}
        defaultValue={group}
        sx={{
          ...inputStyle,
          width: "100%",
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            label="Tổ"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        getOptionLabel={(option) => {
          return `${option?.name}`;
        }}
        onChange={(e, newValue) => {
          setGroup(newValue);
        }}
      />
      <AdsAccountConditionSelect
        inputStyle={inputStyle}
        initialValue={condition}
        onChange={(value) => {
          setCondition(value);
        }}
      />
      {/* Account Number 1 */}
      <MDInput
        fullWidth
        id="accountNumber1"
        label="Số thẻ 1"
        variant="outlined"
        value={accountNumber1}
        onChange={(e) => setAccountNumber1(e.target.value)}
        sx={inputStyle}
      />
      {/* Account Number 2 */}
      <MDInput
        fullWidth
        id="accountNumber2"
        label="Số thẻ 2"
        variant="outlined"
        value={accountNumber2}
        onChange={(e) => setAccountNumber2(e.target.value)}
        sx={inputStyle}
      />
      <QuillInputFiled initialValue={note} onChange={(value) => setNote(value)} />

      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
