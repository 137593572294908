import { CardContent, CardHeader, CircularProgress, useTheme } from "@mui/material";
import React, { forwardRef, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Apexcharts from "react-apexcharts";
import { useMaterialUIController } from "../../context";
import { useBanking } from "../../hooks/useBanking";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import MDButton from "../../components/MDButton";
import MDBox from "../../components/MDBox";
import Calendar from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MUIToggleButton from "@mui/material/ToggleButton";
import MUIToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { separateThousands, separateThousandsAndRound } from "../../utils/helpers";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import UseBankingStatistics from "../../hooks/useBankingStastic";
import "./Banking.css";
import { useCard } from "../../hooks/useCard";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import RangeDatePicker from "../../components/RangeDatePicker";
const ToggleButton = styled(MUIToggleButton)(({ theme }) => {
  return {
    margin: theme.spacing(1),
    color: theme.palette?.light?.main,
    fontWeight: 500,
    "&.Mui-selected": {
      backgroundColor: theme.palette?.background?.default + " !important",
      color: theme.palette?.text?.main,
    },
  };
});

const ToggleButtonGroup = styled(MUIToggleButtonGroup)(({ theme }) => ({
  borderRadius: "10px",
}));
const TodayButton = ({ onClick }) => (
  <MDButton
    variant="outlined"
    onClick={onClick}
    sx={{
      color: "background.default",
      borderColor: "background.default",
    }}
  >
    Today
  </MDButton>
);
const ExampleCustomInput = forwardRef(({ sx, value, onClick }, ref) => (
  <MDButton variant="outlined" onClick={onClick} ref={ref} sx={sx}>
    <Calendar />
    {value}
  </MDButton>
));
const ChartDatePicker = ({ sx, initialDate, onChange }) => {
  const [startDate, setStartDate] = useState(initialDate || new Date());

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => {
        onChange && onChange(date);
        setStartDate(date);
      }}
      maxDate={new Date()} // disable future dates
      customInput={<ExampleCustomInput sx={sx} />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
          <MDButton
            variant="outlined"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            sx={{
              color: "background.default",
              borderColor: "background.default",
            }}
          >
            {"<"}
          </MDButton>
          <TodayButton
            onClick={() => {
              const date = new Date();
              onChange && onChange(date);

              setStartDate(date);
            }}
          />
          <MDButton
            variant="outlined"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            sx={{
              color: "background.default",
              borderColor: "background.default",
            }}
          >
            {">"}
          </MDButton>
        </div>
      )}
    />
  );
};

function StatsCard({ icon, percent, stats, title, iconColor = "info.main" }) {
  return (
    <Grid item xs={6} md={3}>
      <Card
        sx={{
          borderRadius: "10px",
          backgroundColor: "background.card",
          border: "1px solid",
          borderColor: "dark.focus",
        }}
      >
        <CardContent sx={{ p: (theme) => `${theme.spacing(2)} !important` }}>
          <Box
            sx={{
              gap: 2,
              mb: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Icon
                sx={{
                  color: iconColor,
                  fontSize: "2rem !important",
                }}
              >
                {icon}
              </Icon>
            </div>
            {/*<Typography*/}
            {/*  sx={{*/}
            {/*    fontWeight: 500,*/}
            {/*    color: `${percent?.toString().startsWith("-") ? "error.main" : "success.main"}`,*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {percent}*/}
            {/*</Typography>*/}
          </Box>
          <Box
            sx={{
              gap: 2,
              mb: 1,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="h4" sx={{ color: "text.main" }}>
                {separateThousandsAndRound(stats, 2)}
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              gap: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" sx={{ color: "text.focus" }}>
              {title}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

const BankingsStastics = () => {
  const theme = useTheme();
  const [active, setActive] = useState("all");

  const [card, setCard] = useState(null);

  const { data, loading, refetch, setDate, setRangeDate, rangeDate } = UseBankingStatistics({
    cards: active === "filter" ? card?._id : null,
  });
  const handleActive = (event, newActive) => {
    setActive(newActive);
  };
  const { data: cardData } = useCard();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card>
      <CardHeader
        title={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "text.main", marginRight: 2 }}>
              Thống kê
            </Typography>

            <RangeDatePicker
              className="select"
              style={{
                width: "200px",
              }}
              selected={rangeDate}
              onChange={(values) => {
                setDate(null);
                setRangeDate(values);
              }}
            />
          </Box>
        }
        // subheader="Tổng tiền nạp vào trong 15 ngày gần nhất"
        sx={{ "& .MuiCardHeader-action": { m: 0, alignSelf: "center" } }}
        action={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* Active selection */}
            {active === "filter" && (
              <Autocomplete
                disablePortal
                options={cardData || []}
                value={card}
                sx={{ width: 300, padding: "0px" }}
                size="small"
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    label="Thẻ"
                    helperText={card?.isDeleted ? "Thẻ đã bị xóa" : ""}
                  />
                )}
                getOptionLabel={(option) => {
                  return `${option?.account} - ${option?.accountNumber || ""}`;
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option?._id} className="MuiAutocomplete-option">
                      {option?.account} - {option?.accountNumber || ""}
                    </li>
                  );
                }}
                isOptionEqualToValue={(option, value) => {
                  return option._id === value._id;
                }}
                onChange={(e, newValue) => {
                  setCard(newValue);
                }}
              />
            )}
            <ToggleButtonGroup exclusive sx={{ mr: 5 }} value={active} onChange={handleActive}>
              <ToggleButton value="all">Tất cả</ToggleButton>
              <ToggleButton value="filter">Lọc</ToggleButton>
            </ToggleButtonGroup>
            <Icon
              sx={{
                color: "success.main",
                mr: 0.5,
                cursor: "pointer",
                animation: "rotate 1.5s infinite linear",
                animationPlayState: loading ? "running" : "paused",
              }}
              fontSize="large"
              onClick={() => {
                refetch();
              }}
            >
              cached
            </Icon>

            <Typography variant="body2" sx={{ color: "text.main" }}>
              Câp nhật lúc {data?.timeRequest}
            </Typography>
          </Box>
        }
      />
      <CardContent sx={{ pt: (theme) => `${theme.spacing(0)} !important` }}>
        <Grid container spacing={6}>
          <StatsCard
            title="Tổng tiền giao dịch"
            stats={data?.todayTotal}
            icon="account_balance"
            percent={data?.percentDiffTotal}
          />
          <StatsCard
            title="Tiền nhận"
            stats={data?.todayReceivedMoney}
            icon="attach_money"
            percent={data?.percentDiffReceivedMoney}
            iconColor="success.main"
          />
          <StatsCard
            title="Tiền chuyển"
            stats={data?.todaySentMoney}
            icon="currency_exchange"
            percent={data?.percentDiffSentMoney}
            iconColor="error.main"
          />
          <StatsCard
            title="Chênh lệch"
            stats={data?.todayDiffMoney}
            icon="call_split"
            percent={data?.percentDiffDiffMoney}
            iconColor="primary.main"
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BankingsStastics;
