import { useEffect, useState } from "react";
import { getColumns, getRows } from "./notificationData";
import TableLayout from "layouts/TableLayout";
import { useRequest } from "context/RequestContext";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";

function NotificationsTable() {
  const { notification, dispatchError, isLoading } = useRequest();
  const [rows, setRows] = useState([]);
  const columns = getColumns();

  useEffect(
    function () {
      try {
        const rows = getRows({ notification });
        setRows(rows);
      } catch (e) {
        dispatchError(e);
      }
    },
    [notification]
  );

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="Thông Báo"
      canCreate={<UpdateForm create={true} />}
    />
  );
}

export default NotificationsTable;
