import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/departments/departmentsData";
import useCanAccess from "./useCanAccess";
import { departmentActions, roleActions } from "../constants/actions";
import { DEFAULT_SORT } from "../utils/constants";

export const departmentQueryKey = "department";

export const useDepartment = ({ isDeleted = false, sort = DEFAULT_SORT } = {}) => {
  const { makeRequest } = useRequest();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [searchValue, setSearchValue] = useState("");
  // const [rows, setRows] = useState([]);
  const { can } = useCanAccess();

  const { data, isLoading, error } = useQuery({
    queryKey: [departmentQueryKey, { isDeleted, sort }],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${departmentQueryKey}/getAll?isDeleted=${isDeleted}&sort=${sort}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled: can(departmentActions.getAllDepartments),

    placeholderData: (previousData, previousQuery) => previousData,
  });
  const rows = useMemo(() => {
    try {
      const filters = {};
      const department = data;

      return getRows({ department, filters });
    } catch (e) {
      return [];
    }
  }, [data, searchValue]);
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handlePaginationChange = (pageIndex, pageSize) => {
    setPagination({ pageIndex, pageSize });
  };

  return {
    data,
    rows,
    isLoading,
    error,
    handleSearchChange,
  };
};
