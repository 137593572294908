import moment from "moment";
import MDTypography from "./MDTypography";

function Time({ time, color = "text", format = "HH:mm-DD/MM/YYYY" }) {
  const momentData = moment(time);

  return (
    <MDTypography component="p" variant="caption" color={color} fontWeight="medium">
      {momentData.format(format)}
    </MDTypography>
  );
}

export default Time;
