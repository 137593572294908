import NestedList from "components/NestedList";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import StyledNameComponent from "./StyledNameComponent";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const StyledListItemButton = styled(ListItemButton)({
  padding: 0,
});

const primaryTypographyProps = {
  fontSize: "size.sm",
};

const secondaryTypographyProps = {
  color: "inherit",
  fontSize: "size.sm",
};

const defaultListStyle = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.card",
  borderRadius: "10px",
  border: "0.5px solid",
};

export default function CardInfo({ card, isHighlighted = false, highlightSearch = [] }) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  if (!card) return null;
  const { bank, account, accountNumber, cardCategories, managers, isDeleted } = card;
  const name = `${account} ${accountNumber ?? ""} ${bank?.name ?? ""}`;

  return (
    <List
      sx={{
        border: "none",
        color: isHighlighted ? "warning.main" : "text.main",
        width: "250px",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <ListItemText
          primary={
            <StyledNameComponent
              danger={isDeleted}
              isHighlighted={isHighlighted}
              highlightSearch={highlightSearch}
              showHighlightWords={true}
              name={name}
            />
          }
          primaryTypographyProps={primaryTypographyProps}
          sx={{
            flex: 1,
          }}
        />
        <ListItemButton
          onClick={handleClick}
          sx={{
            flex: 0,
          }}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ p: 1 }}>
            <ListItemText
              primary={
                <List>
                  {isDeleted && (
                    <StyledListItemButton
                      sx={{
                        padding: 0,
                      }}
                    >
                      <ListItemText
                        primary="Thẻ đã bị xóa"
                        sx={{
                          color: "error.main",
                          textAlign: "center",
                          "& .MuiTypography-root": {
                            fontSize: "1rem",
                          },
                        }}
                      />
                    </StyledListItemButton>
                  )}
                  <StyledListItemButton
                    sx={{
                      padding: 0,
                    }}
                  >
                    <ListItemText
                      primary="Ngân Hàng"
                      secondary={
                        <StyledNameComponent
                          img={ASSET_URL + bank?.icon}
                          name={`${bank?.name || "Bank Name"}`}
                        />
                      }
                    />
                  </StyledListItemButton>
                  <ListItem>
                    <StyledListItemButton>
                      <ListItemText
                        primary="Loại Thẻ"
                        secondary={cardCategories?.map((category, index) => {
                          return (
                            <ListItemButton key={`${category._id}-${index}`}>
                              <ListItemText
                                primary={
                                  <StyledNameComponent
                                    img={ASSET_URL + category?.icon}
                                    name={`${category?.name}`}
                                  />
                                }
                              />
                            </ListItemButton>
                          );
                        })}
                      />
                    </StyledListItemButton>
                  </ListItem>
                  {/*<ListItem>*/}
                  {/*  <StyledListItemButton>*/}
                  {/*    <ListItemText*/}
                  {/*      primary="Người Quản Lý"*/}
                  {/*      secondary={managers.map((manager, index) => {*/}
                  {/*        return (*/}
                  {/*          <StyledListItemButton key={`${manager._id}-${index}`}>*/}
                  {/*            <ListItemText*/}
                  {/*              primary={*/}
                  {/*                <StyledNameComponent*/}
                  {/*                  img={ASSET_URL + manager?.avatar}*/}
                  {/*                  name={`${manager?.fullName}`}*/}
                  {/*                />*/}
                  {/*              }*/}
                  {/*            />*/}
                  {/*          </StyledListItemButton>*/}
                  {/*        );*/}
                  {/*      })}*/}
                  {/*    />*/}
                  {/*  </StyledListItemButton>*/}
                  {/*</ListItem>*/}
                </List>
              }
              primaryTypographyProps={secondaryTypographyProps}
            />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );

  // return (
  //   <NestedList
  //     title={
  //       <StyledNameComponent
  //         danger={isDeleted}
  //         isHighlighted={isHighlighted}
  //         highlightSearch={highlightSearch}
  //         showHighlightWords={true}
  //         name={name}
  //       />
  //     }
  //     sx={{ border: "none", color: isHighlighted ? "warning.main" : "text.main" }}
  //     buttonStyle={{
  //       padding: "0px",
  //     }}
  //   >
  //     <List>
  //       {isDeleted && (
  //         <StyledListItemButton
  //           sx={{
  //             padding: 0,
  //           }}
  //         >
  //           <ListItemText
  //             primary="Thẻ đã bị xóa"
  //             sx={{
  //               color: "error.main",
  //               textAlign: "center",
  //               "& .MuiTypography-root": {
  //                 fontSize: "1rem",
  //               },
  //             }}
  //           />
  //         </StyledListItemButton>
  //       )}
  //       <StyledListItemButton
  //         sx={{
  //           padding: 0,
  //         }}
  //       >
  //         <ListItemText
  //           primary="Ngân Hàng"
  //           secondary={
  //             <StyledNameComponent
  //               img={ASSET_URL + bank?.icon}
  //               name={`${bank?.name || "Bank Name"}`}
  //             />
  //           }
  //         />
  //       </StyledListItemButton>
  //       <ListItem>
  //         <StyledListItemButton>
  //           <ListItemText
  //             primary="Loại Thẻ"
  //             secondary={cardCategories.map((category, index) => {
  //               return (
  //                 <ListItemButton key={`${category._id}-${index}`}>
  //                   <ListItemText
  //                     primary={
  //                       <StyledNameComponent
  //                         img={ASSET_URL + category?.icon}
  //                         name={`${category?.name}`}
  //                       />
  //                     }
  //                   />
  //                 </ListItemButton>
  //               );
  //             })}
  //           />
  //         </StyledListItemButton>
  //       </ListItem>
  //       {/*<ListItem>*/}
  //       {/*  <StyledListItemButton>*/}
  //       {/*    <ListItemText*/}
  //       {/*      primary="Người Quản Lý"*/}
  //       {/*      secondary={managers.map((manager, index) => {*/}
  //       {/*        return (*/}
  //       {/*          <StyledListItemButton key={`${manager._id}-${index}`}>*/}
  //       {/*            <ListItemText*/}
  //       {/*              primary={*/}
  //       {/*                <StyledNameComponent*/}
  //       {/*                  img={ASSET_URL + manager?.avatar}*/}
  //       {/*                  name={`${manager?.fullName}`}*/}
  //       {/*                />*/}
  //       {/*              }*/}
  //       {/*            />*/}
  //       {/*          </StyledListItemButton>*/}
  //       {/*        );*/}
  //       {/*      })}*/}
  //       {/*    />*/}
  //       {/*  </StyledListItemButton>*/}
  //       {/*</ListItem>*/}
  //     </List>
  //   </NestedList>
  // );
}
