import { getColumns } from "./requestData";
import { useRequest } from "hooks/useRequest";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import TableServerPaginateLayout from "../TableServerPaginateLayout";
import { Can } from "../../hooks/useCanAccess";
import { requestActions, userActions } from "../../constants/actions";
import { useSearchParams } from "react-router-dom";

function Request() {
  const [searchParams] = useSearchParams();

  const { data, pagination, handlePaginationChange, handleSearchChange, isLoading } = useRequest({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
  });
  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableServerPaginateLayout
      columns={columns}
      rows={data?.rows || []}
      title="Đề Xuất"
      canCreate={
        <Can
          permissions={[requestActions.insertRequest, userActions.getPagingUsers]}
          fallback={"Bạn không có quyền thêm đề xuất hoặc xem danh sách nhân viên"}
        >
          <UpdateForm create={true} />
        </Can>
      }
      pageCount={data?.pagination?.totalPages}
      manualPagination={true}
      onPaginationChange={handlePaginationChange}
      pageIndex={pagination?.pageIndex}
      pageSize={pagination?.pageSize}
      onSearchChange={handleSearchChange}
      canSearch={true}
      refreshButton={true}
      compact={true}
    />
  );
}

export default Request;
