import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import DeleteForm from "components/DeleteForm";
import { cardActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import NestedList from "components/NestedList";
import { DisplayContent } from "libs/display-content";
import { displayShortContent } from "utils/helpers";
import parse from "html-react-parser";
import Icon from "@mui/material/Icon";
import ModalPreviewContent from "../../components/ModalPreviewContent";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;
function applyBankFilter(row, filterValue) {
  return row?.bank?._id === filterValue;
}

function applyCardFilter(row, filterValue) {
  return row?.account === filterValue || row?.cardName === filterValue;
}

function applyCardCategoryFilter(row, filterValue) {
  const cardCategory = row.cardCategories?.find((card) => card._id === filterValue);
  return cardCategory ? cardCategory._id === filterValue : false;
}

function applyDefaultFilter(row, key, filterValue) {
  return row[key]?.toLowerCase().includes(filterValue.toLowerCase());
}

function getRows({ card, filters = {} }) {
  let rows = card;

  try {
    Object.keys(filters).forEach((key) => {
      rows = rows.filter((row) => {
        if (!filters[key]) return true;

        switch (key) {
          case "bank":
            return applyBankFilter(row, filters[key]);
          case "cardCategory":
            return applyCardCategoryFilter(row, filters[key]);
          case "account":
            return applyCardFilter(row, filters[key]);

          default:
            return applyDefaultFilter(row, key, filters[key]);
        }
      });
    });

    rows = rows?.map((card) => {
      const {
        bank,
        account,
        accountNumber,
        cardCategories,
        members,
        isDeleted,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
        note,
      } = card;

      return {
        cardInfo: (
          <StyledNameComponent name={`${account} ${accountNumber || ""} ${bank?.name || ""}`} />
        ),
        cardCategories: cardCategories
          ? cardCategories?.map((category, index) => {
              return (
                <StyledNameComponent
                  key={index}
                  img={ASSET_URL + category?.icon}
                  name={category?.name}
                />
              );
            })
          : null,
        members: members
          ? members?.map((manager, index) => {
              return (
                <StyledNameComponent
                  key={index}
                  // img={ASSET_URL + manager?.avatar}
                  name={manager?.name}
                />
              );
            })
          : null,
        // managers: managers && (
        //   <NestedList
        //     title={
        //       <Icon
        //         sx={{
        //           display: "block",
        //           margin: "0 auto",
        //         }}
        //       >
        //         visibility
        //       </Icon>
        //     }
        //     sx={{
        //       width: "140px",
        //       bgcolor: "background.card",
        //       borderRadius: "10px",
        //       border: "0.5px solid",
        //     }}
        //   >
        //     {managers.map((manager, index) => {
        //       return (
        //         <StyledNameComponent
        //           key={index}
        //           img={ASSET_URL + manager?.avatar}
        //           name={manager?.fullName}
        //         />
        //       );
        //     })}
        //   </NestedList>
        // ),
        description: <ModalPreviewContent content={note} />,
        isDeleted: (
          <MDBadge
            badgeContent={String(isDeleted)}
            color={isDeleted ? "error" : "success"}
            variant="gradient"
            size="sm"
          />
        ),
        createdTime: createdTime ? <Time time={createdTime} /> : null,
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={createdBy.fullName} />
        ) : null,
        updatedTime: updatedTime ? <Time time={updatedTime} /> : null,
        updatedBy: updatedBy ? (
          <StyledNameComponent img={ASSET_URL + updatedBy.avatar} name={updatedBy.fullName} />
        ) : null,
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={cardActions.updateCard}>
              <Modal>
                <UpdateForm card={card} />
              </Modal>
            </Can>
            <Can permissions={cardActions.deleteCard}>
              <Modal action="delete">
                <DeleteForm context="card" id={card._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "tài khoản", accessor: "cardInfo", align: "left" },
    { Header: "thành viên", accessor: "members", align: "left" },
    { Header: "loại thẻ", accessor: "cardCategories", align: "left" },
    { Header: "ghi chú", accessor: "description", align: "center" },
    { Header: "trạng thái xoá", accessor: "isDeleted", align: "center" },
    { Header: "tạo lúc", accessor: "createdTime", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },
    { Header: "cập nhật bởi", accessor: "updatedBy", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
