import { useRequest } from "../context/RequestContext";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/card/cardsData";
import useCanAccess from "./useCanAccess";
import { cardActions } from "../constants/actions";
import { DEFAULT_SORT } from "../utils/constants";
export const cardQueryKey = "card";

export const useCard = ({ isDeleted = false, sort = DEFAULT_SORT } = {}) => {
  const { makeRequest } = useRequest();
  const [searchContext, setSearchContext] = useState({});
  const { can } = useCanAccess();

  const { data, isLoading, error } = useQuery({
    queryKey: [cardQueryKey, { isDeleted, sort }],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${cardQueryKey}/getAll?isDeleted=${isDeleted}&sort=${sort}`,
      };
      return await makeRequest(request);
    },
    enabled: can(cardActions.getAllCards),
  });

  const rows = useMemo(() => {
    try {
      const filters = {
        ...searchContext,
      };
      return getRows({ card: data, filters });
    } catch (err) {
      console.log(err);
      return [];
    }
  }, [data, searchContext]);

  const handleSearchChange = (value) => {
    setSearchContext(value);
  };

  return {
    data,
    rows,
    isLoading,
    error,
    handleSearchChange,
  };
};
