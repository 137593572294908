import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useRequest } from "context/RequestContext";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import { useQueryClient } from "@tanstack/react-query";

function DeleteForm({ context, id }) {
  const { deleteRequest } = useRequest();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const queryClient = useQueryClient();

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      await deleteRequest({ context, id });
      queryClient.invalidateQueries([context]);
    } catch (error) {
      dispatchError(error);
    }
  }

  return (
    <MDBox component="form" onSubmit={handleSubmit} sx={{ textAlign: "center" }}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        <span>Xác nhận hàng động xoá của dòng dữ liệu này !!!</span>
      </MDTypography>
      <br />
      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        onClick={(e) => e.stopPropagation()}
      >
        Xác Nhận
      </MDButton>
    </MDBox>
  );
}

export default DeleteForm;
