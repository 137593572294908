import { useMutation, useQueryClient } from "@tanstack/react-query";
import { readNotification } from "services/apiNotification";

export default function useReadNotification() {
  const queryClient = useQueryClient();
  const { mutate: readNoti } = useMutation({
    mutationFn: (id) => {
      return readNotification(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notification"],
      });
    },
    onError: (err) => {
      console.log(err.message);
      throw new Error(err);
    },
  });

  return { readNoti };
}
