import { getColumns } from "./userData";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "layouts/users/UpdateForm";

import { userQueryKey, useUser } from "../../hooks/useUser";
import TableServerPaginateLayout from "../TableServerPaginateLayout";
import StatusSelect from "../../components/StatusSelect";
import { memo } from "react";
import { departmentActions, groupActions, userActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";

function Users() {
  const {
    dataPagination,
    error,
    handlePaginationChange,
    isLoading,
    pagination,
    handleSearchChange,
    status,
    handleStatusChange,
  } = useUser();
  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;

  // if (error) return <div>Có lỗi xảy ra</div>;
  return (
    <TableServerPaginateLayout
      columns={columns}
      rows={dataPagination.rows}
      title="Người Dùng"
      canCreate={
        <Can
          permissions={[
            userActions.insertUser,
            departmentActions.getAllDepartments,
            groupActions.getAllGroups,
          ]}
          fallback={"Bạn không có quyền thêm người dùng"}
        >
          <UpdateForm create={true} />
        </Can>
      }
      pageCount={dataPagination.pagination.totalPages}
      manualPagination={true}
      onPaginationChange={handlePaginationChange}
      pageIndex={pagination.pageIndex}
      pageSize={pagination.pageSize}
      onSearchChange={handleSearchChange}
      canExport={userQueryKey}
      refreshButton={true}
      extraSearch={
        <StatusSelect
          title="Trạng Thái"
          context="userFilter"
          sx={{
            marginTop: 0,
          }}
          selectSx={{
            padding: "10px",
          }}
          onChange={handleStatusChange}
          status={status}
        />
      }
      compact={true}
    />
  );
}

export default memo(Users);
