import colors from "../base/colors";

const { background } = colors;

const paper = {
  styleOverrides: {
    root: {
      backgroundColor: background.default,
      padding: "24px",
    },
  },
};

export default paper;
