import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import DeleteForm from "components/DeleteForm";
import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
import { actionActions, bankActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

function getRows({ action, filters = {} }) {
  try {
    let rows = action;
    // Apply filters
    Object.keys(filters).forEach((key) => {
      rows = rows.filter((row) => {
        if (!filters[key]) return true;
        if (key === "actionGroups") {
          return row.actionGroups.map((item) => item._id).includes(filters[key]);
        }

        return row[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    rows = rows.map((item) => {
      const { name, viName, actionGroups } = item;

      return {
        name: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox ml={2} lineHeight={1}>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                sx={{ textTransform: "uppercase" }}
              >
                {name}
              </MDTypography>
            </MDBox>
          </MDBox>
        ),
        viName: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox ml={2} lineHeight={1}>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                sx={{ textTransform: "uppercase" }}
              >
                {viName}
              </MDTypography>
            </MDBox>
          </MDBox>
        ),
        actionGroups: actionGroups.map((item) => item.name).join(", "),
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={actionActions.updateAction}>
              <Modal>
                <UpdateForm bank={item} />
              </Modal>
            </Can>
            {/*<Modal action="delete">*/}
            {/*  <DeleteForm context="bank" id={item._id} />*/}
            {/*</Modal>*/}
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "Tên", accessor: "name", width: "25%", align: "left" },
    { Header: "Tên Tiếng Việt", accessor: "viName", align: "center" },
    { Header: "Nhóm Hành Động", accessor: "actionGroups", align: "center" },
    { Header: "Thao Tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
