import { useRequest } from "context/RequestContext";
import { useState } from "react";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import FormSelect from "components/FormSelect";
import StatusSelect from "components/StatusSelect";
import { useQueryClient } from "@tanstack/react-query";
import { requestQueryKey } from "../../hooks/useRequest";
import { useUser } from "../../hooks/useUser";
import TextAreaField from "../../components/TextAreaField";

const inputStyle = {
  marginTop: "20px",
};

const context = "request";

function UpdateForm({ request, create = false }) {
  const [title, setTitle] = useState(request?.title || "");
  const [user, setUser] = useState(request?.user?._id || "");
  const [status, setStatus] = useState(request?.status || 0);
  const [content, setContent] = useState(request?.content || "");
  const [isLoading, setIsLoading] = useState(false);

  const { updateRequest, createRequest } = useRequest();
  const { data: users, isLoading: isLoadingUser } = useUser({
    getAll: true,
  });
  const queryClient = useQueryClient();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  function handleChangeContent(e) {
    setContent(e.target.value);
  }

  function handleChangeStatus(e) {
    setStatus(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const body = { title, user, status, content };

      if (!create) {
        await updateRequest({ context, id: request._id, body });
        await queryClient.invalidateQueries({
          queryKey: [requestQueryKey],
          type: "active",
        });
      } else {
        await createRequest({ context, body });
        await queryClient.invalidateQueries({
          queryKey: [requestQueryKey],
          type: "active",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoadingUser) return <div>Loading...</div>;

  return (
    <MDBox component="form" onSubmit={handleSubmit}>
      <FormSelect
        title="Người Dùng"
        data={users?.data || []}
        dataSelected={user}
        onChange={(e) => setUser(e.target.value)}
        required
      />
      <MDInput
        fullWidth
        id="title"
        label="Tiêu Đề"
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        sx={inputStyle}
        required
      />
      <TextAreaField
        minRows={5}
        placeholder="Nội Dung"
        value={content}
        onChange={handleChangeContent}
        required
      />
      <StatusSelect
        title="Trạng Thái"
        context="request"
        status={status}
        onChange={handleChangeStatus}
      />
      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
