import { getRemovedHtmlTag } from "libs/display-content";
import moneyTypeUtils from "./moneyTypeUtils";
import moment from "moment";
import _, { omit } from "lodash";
export const formatCurrency = (value, currency) =>
  new Intl.NumberFormat("en", { style: "currency", currency: currency }).format(value);

export const getCurrency = (value) =>
  moneyTypeUtils.find((type) => Number(type._id) === Number(value))?.name || "VND";

/**
 * Generates a list of numbers greater than a given threshold.
 *
 * This function generates a list of numbers that are greater than a base number.
 * The base number is multiplied by a step value in a loop until it reaches a maximum limit.
 * If the calculated number is greater than a minimum threshold, it is added to the list.
 * The function returns the list of numbers.
 *
 * @param {number} baseNumber - The base number to start from.
 * @param {number} numberOfItems - The number of items to generate. Currently unused.
 * @returns {number[]} - Returns an array of numbers greater than the base number.
 */
export function generateNumberListGreaterThanThreshold(baseNumber, numberOfItems) {
  const numberList = [];
  const min = 100000; // 1 hundred thousand
  const max = 1000000000; // 1 billion
  const step = 10;

  // Convert baseNumber to a number to remove leading zeros
  baseNumber = Number(baseNumber);

  // If baseNumber or numberOfItems is not provided or baseNumber is 0, return an empty list
  if (!baseNumber || !numberOfItems || isNaN(baseNumber) || baseNumber === 0) return numberList;

  let number = baseNumber;
  // Loop until number reaches the maximum limit
  while (number < max) {
    // Multiply the number by the step value
    number = number * step;
    // If the number is greater than the minimum threshold, add it to the list
    if (number >= min) {
      numberList.push(number);
    }
  }

  // Return the list of numbers
  return numberList;
}

export const separateThousands = (value) => {
  if (!value) return value;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const separateThousandsAndRound = (value, decimal = 0) => {
  if (!value) return value;
  return separateThousands(Math.round(value).toFixed(decimal));
};

export function displayShortContent(content) {
  if (!content) return;
  const removedHtmlTag = getRemovedHtmlTag(content);
  const shortContent =
    removedHtmlTag?.length > 20 ? removedHtmlTag?.slice(0, 20) + "..." : removedHtmlTag;
  return shortContent;
}

export function convertGmtOffsetToTime(gmtOffset) {
  // Chuyển đổi giá trị offset từ giây sang giờ và phút
  const hours = Math.floor(gmtOffset / 3600);
  const minutes = Math.abs(Math.floor((gmtOffset % 3600) / 60));

  // Định dạng giờ và phút thành HH:mm với dấu thích hợp
  const sign = hours >= 0 ? "+" : "-";
  const formattedHours = Math.abs(hours);
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Kết hợp dấu, giờ và phút để tạo kết quả cuối cùng
  return `${sign}${
    formattedHours < 10 ? "0" + formattedHours : formattedHours
  }:${formattedMinutes}`;
}

export const getKeysFromObjArray = (arr, omitArr) => {
  const result = new Set();
  const allKeys = arr.map((item) => Object.keys(item)).flat();

  allKeys.forEach((key) => result.add(key));

  const list = Array.from(result);
  if (omitArr) {
    return list.filter((item) => !omitArr.includes(item));
  }
  return list;
};

export function isISODateString(value) {
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;
  return isoDatePattern.test(value) && moment(value, moment.ISO_8601).isValid();
}

export function removeVietnameseTones(str) {
  if (!str) return str;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  return str;
}

export const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
};
