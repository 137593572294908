// ** React Import
import { forwardRef } from "react";

// ** MUI Imports
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const TextAreaField = forwardRef((props, ref) => {
  // ** Props
  const {
    size = "small",
    error,
    success,
    disabled,
    InputLabelProps,
    fullWidth = true,
    rows = 4,
    ...rest
  } = props;

  return (
    <FormControl fullWidth margin="normal">
      <TextField multiline rows={rows} fullWidth={fullWidth} {...rest} />
    </FormControl>
  );
});

export default TextAreaField;
