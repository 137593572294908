export const listSimType = [
  {
    _id: 0,
    name: "Viettel",
  },
  {
    _id: 1,
    name: "Mobifone",
  },
  {
    _id: 2,
    name: "Vinaphone",
  },
  {
    _id: 3,
    name: "Vietnamobile",
  },
];
