import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import DeleteForm from "components/DeleteForm";
import MDTypography from "components/MDTypography";
import { List, ListItem } from "@mui/material";
import NestedList from "components/NestedList";
import { punishmentActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import { DisplayContent, getTextFromHtmlTag } from "../../libs/display-content";
import { displayShortContent } from "../banking/bankingsData";
import ModalPreviewContent from "../../components/ModalPreviewContent";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

function getRows({ punishment }) {
  try {
    const rows = punishment.map((punishment) => {
      const {
        member,
        isDeleted,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
        punishedTime,
        content,
      } = punishment || [];

      return {
        member: <StyledNameComponent img={ASSET_URL + member?.avatar} name={member?.name} />,
        content: <ModalPreviewContent content={content} width={300} />,

        isDeleted: (
          <MDBadge
            badgeContent={String(isDeleted)}
            color={isDeleted ? "error" : "success"}
            variant="gradient"
            size="sm"
          />
        ),
        punishedTime: punishedTime ? <Time time={punishedTime} format="DD/MM/YYYY" /> : null,
        createdTime: createdTime ? <Time time={createdTime} /> : null,
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={member?.group?.name} />
        ) : null,
        updatedTime: updatedTime ? <Time time={updatedTime} /> : null,
        updatedBy: updatedBy ? (
          <StyledNameComponent img={ASSET_URL + updatedBy.avatar} name={updatedBy?.fullName} />
        ) : null,
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={punishmentActions.updatePunishment}>
              <Modal>
                <UpdateForm punishment={punishment} />
              </Modal>
            </Can>
            <Can permissions={punishmentActions.deletePunishment}>
              <Modal action="delete">
                <DeleteForm context="punishment" id={punishment._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "Thời gian", accessor: "punishedTime", align: "center" },

    { Header: "thành viên", accessor: "member", width: "100px", align: "left" },
    { Header: "nội dung", accessor: "content", align: "left" },
    { Header: "trạng thái xoá", accessor: "isDeleted", align: "center" },
    { Header: "tổ", accessor: "createdBy", align: "left" },
    { Header: "tổ", accessor: "createdTime", align: "left" },
    // { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },
    { Header: "cập nhật bởi", accessor: "updatedBy", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
