/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { useAuth } from "context/AuthContext";
import { Badge } from "@mui/material";
import { useNotification } from "context/NotificationContext";
import Modal from "@mui/material/Modal";
import { modalWrapperStyle } from "../../../components/Modal";
import MDButton from "../../../components/MDButton";
import UpdatePasswordForm from "../../../components/UpdatePasswordForm";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [unreadCount, setUnreadCount] = useState(0);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isOpenModalChangePass, setIsOpenModalChangePass] = useState(false);

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const { logout, user } = useAuth();
  const { notifications } = useNotification();
  const navigate = useNavigate();

  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    setNavbarType("static");

    // if (fixedNavbar) {
    //   setNavbarType("sticky");
    // } else {
    //   setNavbarType("static");
    // }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    // window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    // handleTransparentNavbar();

    // Remove event listener on cleanup
    // return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    if (notifications && notifications?.unread) {
      setUnreadCount(notifications?.unread?.length || 0);
    }
  }, [notifications]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenUserMenu = (event) => setOpenUserMenu(event.currentTarget);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseUserMenu = () => setOpenUserMenu(false);
  const goDetail = (id) => {
    if (!id) {
      return navigate("/notification");
    }
    navigate(`/notification/${id}?unread=true`);
  };
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {notifications?.unread?.map((notification, index) => (
        <NotificationItem
          key={index}
          icon={<Icon>notifications</Icon>}
          title={notification.title}
          onClick={() => goDetail(notification?._id)}
        />
      ))}
      {/* link to notification page */}
      <NotificationItem title={"Xem tất cả"} onClick={() => goDetail()} />
    </Menu>
  );

  const showModalChangePass = () => {
    setIsOpenModalChangePass(true);
  };

  const renderUserMenu = () => (
    <Menu
      anchorEl={openUserMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openUserMenu)}
      onClose={handleCloseUserMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>logout</Icon>} title="Logout" onClick={logout} />
      {user?.role?.name !== "SUPERADMIN" && (
        <NotificationItem
          icon={<Icon>lock_reset</Icon>}
          title="Change Password"
          onClick={showModalChangePass}
        />
      )}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                sx={navbarIconButton}
                size="small"
                variant="contained"
                disableRipple
              >
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                {/*<Link to="/notification">*/}
                {/*</Link>*/}

                <Badge badgeContent={unreadCount} color="error">
                  <Icon sx={iconsStyle}>notifications</Icon>
                </Badge>
              </IconButton>

              {renderUserMenu()}
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <Modal open={isOpenModalChangePass} onClose={() => setIsOpenModalChangePass(false)}>
        <MDBox
          sx={{
            ...modalWrapperStyle,
            color: "text.main",
            minWidth: 400,
          }}
        >
          {isOpenModalChangePass && <UpdatePasswordForm />}
          <MDButton
            variant="contained"
            color="error"
            iconOnly
            onClick={() => setIsOpenModalChangePass(false)}
            size="small"
            sx={{
              position: "absolute",
              top: 10,
              right: 5,
            }}
          >
            <Icon>clear</Icon>
          </MDButton>
        </MDBox>
      </Modal>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
