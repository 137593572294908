import React, { useState } from "react";
import MDBox from "./MDBox";
import { DisplayContent, getTextFromHtmlTag, sanitizeImage } from "../libs/display-content";
import LinesEllipsis from "react-lines-ellipsis";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

import Icon from "@mui/material/Icon";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
const ModalPreviewContent = ({ content = null, width = 150 }) => {
  const [preview, setPreview] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const text = getTextFromHtmlTag(content, {
    FORBID_TAGS: ["img"],
    ALLOWED_ATTR: ["style", "src"],
  });

  if (!content) {
    return null;
  }
  const hasImage = content?.includes("<img") && !isClamped;

  const sanitizedImageContent = sanitizeImage(content);
  return (
    <>
      <MDBox
        width={width}
        sx={{
          color: "text.main",
          textOverflow: "ellipsis",
          overflow: "hidden",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "space-between",
          // flexWrap: "no-wrap",
          // //2 lines
          // display: "-webkit-box",
          // WebkitLineClamp: 1,
          // WebkitBoxOrient: "vertical",
        }}
      >
        {/*{DisplayContent({*/}
        {/*  content: content,*/}
        {/*  ignoreTags: ["img"],*/}
        {/*  ignoreAttr: ["src", "style"],*/}
        {/*})}*/}
        <HTMLEllipsis
          unsafeHTML={sanitizedImageContent}
          maxLine="1"
          ellipsis="......"
          trimRight
          onReflow={(ref) => {
            setIsClamped(ref?.clamped);
          }}
          basedOn="letters"
          onClick={() => {
            if (isClamped) {
              setPreview(!preview);
            }
          }}
        />
        {hasImage && (
          <Icon
            sx={{
              cursor: "pointer",
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
            onClick={() => {
              setPreview(!preview);
            }}
          >
            image
          </Icon>
        )}
      </MDBox>
      <Dialog open={preview} onClose={() => setPreview(false)}>
        <DialogContent>
          <div className="display-content">
            {DisplayContent({
              content: content,
            })}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalPreviewContent;
