import { useMemo } from "react";
import { getColumns, getRows } from "./cardsData";
import TableLayout from "layouts/TableLayout";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { cardQueryKey, useCard } from "../../hooks/useCard";
import { get } from "lodash";

import SearchCard from "./SearchCard";
import { Can } from "../../hooks/useCanAccess";
import {
  bankActions,
  cardActions,
  cardCategoryActions,
  userActions,
} from "../../constants/actions";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_SORT } from "../../utils/constants";

function CardsTable() {
  const [searchParams] = useSearchParams();

  const { rows, handleSearchChange, isLoading, data } = useCard({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
    sort: searchParams.get("sort") || DEFAULT_SORT,
  });
  const columns = getColumns();

  const cardCategoryList = useMemo(() => {
    let cardCategoryList = [];
    data?.forEach((card) => {
      const cardCategory = get(card, "cardCategories") || [];
      cardCategory.forEach((category) => {
        const isExist = cardCategoryList.find((item) => item._id === category._id);
        if (!isExist) {
          cardCategoryList.push(category);
        }
      });
    });

    return cardCategoryList;
  }, [data]);

  return (
    <TableLayout
      columns={columns}
      rows={rows || []}
      title="Thẻ Ngân Hàng"
      canCreate={
        <Can
          permissions={[
            cardActions.insertCard,
            cardCategoryActions.getAllCardCategories,
            bankActions.getAllBanks,
            userActions.getPagingUsers,
          ]}
          fallback={
            "Bạn không có quyền thêm thẻ ngân hàng hoặc xem danh sách người dùng , ngân hàng, loại thẻ"
          }
        >
          <UpdateForm create={true} />
        </Can>
      }
      canSearch={false}
      extraSearch={<SearchCard onChange={handleSearchChange} cardCategoryList={cardCategoryList} />}
      canExport={cardQueryKey}
      refreshButton={true}
      compact={true}
    />
  );
}

export default CardsTable;
