import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import Configurator from "examples/Configurator";
import Sidenav from "examples/Sidenav";
import logoOkvip from "assets/images/logo_okvip.png";
import { get, groupBy } from "lodash";

// Material Dashboard 2 React routes
import routes from "routes";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useRequest } from "context/RequestContext";
import useCanAccess from "../hooks/useCanAccess";
import { useGroup } from "../hooks/useGroup";
function groupByDepartments(items) {
  // Flatten the structure to associate each item with its department
  const flattenedItems = items.flatMap((item) =>
    item.departments.map((department) => ({
      departmentId: department._id,
      departmentName: department.name,
      item: item,
    }))
  );

  // Group the flattened items by department name and ID
  const groupedByDepartment = _.groupBy(
    flattenedItems,
    (item) => `${item.departmentId}-${item.departmentName}`
  );

  // Format the output to include department ID and name
  const result = _.map(groupedByDepartment, (items, key) => {
    const [departmentId, departmentName] = key.split("-");
    return {
      departmentId,
      departmentName,
      children: items.map((i) => i.item),
    };
  });

  return result;
}
function AppLayout() {
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const { canOneOf } = useCanAccess();

  // Auth context
  const { error, success } = useRequest();
  const { data } = useGroup();

  // MUI theme context
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const { pathname } = useLocation();

  const filteredRoutes = useMemo(() => {
    let listRoutes = routes.filter((route) => {
      if (route.permissions) {
        return canOneOf(route.permissions);
      }
      return true;
    });
    if (data) {
      const departments = groupByDepartments(data);
      listRoutes = listRoutes.map((route) => {
        if (route.key === "department") {
          return {
            ...route,
            children: departments.map((department) => {
              return {
                ...route,
                route: `/department/${department.departmentId}`,
                key: department.departmentId,
                name: `Bộ thẻ ${department.departmentName}`,
                icon: <Icon fontSize="small">credit_card</Icon>,
                children: department.children.map((child) => {
                  return {
                    ...route,
                    icon: <Icon fontSize="small">person</Icon>,

                    route: `/department/${department.departmentId}/${child._id}`,
                    name: `Tổ ${child.name}`,
                    key: child._id,
                  };
                }),
              };
            }),
          };
        }
        return route;
      });
    }
    return listRoutes;
  }, [routes, canOneOf, data]);
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Close Snackbar Alert
  function handleClose() {
    setSnackBarOpen(false);
  }

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Trigger Snackbar popup when error exists
  useEffect(() => {
    if (error || success) {
      setSnackBarOpen(true);

      // Close Snackbar after 3 seconds
      setTimeout(() => {
        setSnackBarOpen(false);
      }, 3000);
    }
  }, [error, success]);

  return (
    <>
      <CssBaseline />

      {/* Sidenav */}
      <>
        <Sidenav
          color={sidenavColor}
          brand={logoOkvip}
          brandName="OKVIP"
          routes={filteredRoutes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
        <Configurator />
        {configsButton}
      </>

      {/* Configurator */}
      {layout === "vr" && <Configurator />}

      {/* Snackbar alert */}
      <>
        {error && (
          <Snackbar
            open={snackBarOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleClose} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
        {success && (
          <Snackbar
            open={snackBarOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleClose} severity="success">
              {success}
            </Alert>
          </Snackbar>
        )}
        <Outlet />
      </>
    </>
  );
}

export default AppLayout;
