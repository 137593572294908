import { useRequest } from "../context/RequestContext";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/banking/bankingsData";
import { get, isArray, isEmpty, isEqual, isNull, isUndefined } from "lodash";
import useCanAccess from "./useCanAccess";
import { bankingActions } from "../constants/actions";

export const bankingQueryKey = "banking";

function getOptions(list, searchValue, key) {
  const cardNames = new Set(); // Use a Set for uniqueness

  // Convert searchValue to lowercase if it's not undefined or empty
  // and normalize to remove diacritics
  const searchValueLower = searchValue
    ? searchValue
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    : "";

  for (const transaction of list) {
    const cardName = get(transaction, key); // Use lodash's get function
    if (cardName) {
      const cardNameLower = cardName
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      // If searchValue is undefined or empty, add all names,
      // otherwise check if the card name includes the search value
      if (!searchValue || cardNameLower.includes(searchValueLower)) {
        cardNames.add(cardName);
        if (cardNames.size === 5) break; // Stop when we have 5 unique names
      }
    }
  }

  return Array.from(cardNames);
}
function getMixMaxAmountOfMoney(list) {
  let min = 0;
  let max = 0;
  for (const transaction of list) {
    if (transaction.amountOfMoney) {
      const amountOfMoney = transaction.amountOfMoney;
      if (amountOfMoney > max) {
        max = amountOfMoney;
      }
      if (amountOfMoney < min) {
        min = amountOfMoney;
      }
    }
  }
  return [min, max];
}

const generateOptions = (list, key) => {
  return [];
  // const options = new Set();
  // for (const transaction of list) {
  //   const value = get(transaction, key);
  //   if (value) {
  //     options.add(value);
  //   }
  // }
  // return Array.from(options);
};
const advancedSearchInitialState = {
  amountOfMoneyRange: [null, null],
  bankingTime: [null, null],
  bankingType: null,
  company: null,
  filterDuplicate: [],
  fromCard: [],
  members: [],
  status: [],
  toCard: [],
};
export const useBanking = ({ isDeleted = false } = {}) => {
  const { makeRequest, dispatchError } = useRequest();
  const [search, setSearch] = useState("");
  const [sortOption, setSortOption] = useState("-bankingTime"); // bankingTime, -bankingTime, createdTime, -createdTime
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const [advancedSearch, setAdvancedSearch] = useState(advancedSearchInitialState);
  const isHasAdvancedSearch = useMemo(() => {
    return !isEqual(advancedSearch, advancedSearchInitialState);
  }, [advancedSearch]);

  const { can } = useCanAccess();

  const { data, isLoading, error } = useQuery({
    queryKey: [bankingQueryKey, { isDeleted }, pagination, advancedSearch, search, sortOption],
    queryFn: async () => {
      const filteredAdvancedSearch = Object.keys(advancedSearch)
        ?.filter((key) => {
          const value = advancedSearch[key];
          return !(isUndefined(value) || isNull(value) || isEmpty(value));
        })
        .reduce((acc, cur) => {
          let value = advancedSearch[cur];
          if (Array.isArray(value)) {
            value = value?.join(",");
          }

          return {
            ...acc,
            [cur]: value,
          };
        }, {});
      const params = new URLSearchParams({
        ...filteredAdvancedSearch,
        search,
        sort: sortOption,
      }).toString();
      const paginationQuery = new URLSearchParams({
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      }).toString();
      const request = {
        method: method.get,
        endpoint: `/${bankingQueryKey}/getPaging?isDeleted=${isDeleted}&${paginationQuery}&${params}`,
      };
      return await makeRequest(request);
    },
    select: (result) => {
      const data = result?.data;
      const pagination = {
        pageIndex: result?.pageIndex - 1 <= 0 ? 0 : result?.pageIndex - 1,
        pageSize: result?.pageSize,
        totalPages: result?.totalPages,
        totalItems: result?.totalItems,
      };
      return {
        data,
        pagination,
      };
    },
    enabled: can(bankingActions.getAllBankings),
    placeholderData: (previousData, previousQuery) => previousData || [],
  });

  const rows = useMemo(() => {
    try {
      const [start, end] = advancedSearch?.bankingTime;
      const filters = {
        searchValue: search,
        // fromCardNumber: advancedSearch?.fromCardNumber,
        // toCardNumber: advancedSearch?.toCardNumber,
        // fromCardName: advancedSearch?.fromCardName,
        // toCardName: advancedSearch?.toCardName,
        // amountOfMoneyRange: advancedSearch?.amountOfMoneyRange,
        // bankingTime: start && end ? [start, end] : null,
        // members: advancedSearch?.members?.length ? advancedSearch?.members : null,
        // bankingType: advancedSearch?.bankingType,
        // company: advancedSearch?.company,
        // duplicateFilter: advancedSearch?.duplicateFilter,
        // status: advancedSearch?.status,
      };
      // const banking = (data?.data || []).sort((a, b) => {
      //   switch (sortOption) {
      //     case "BankingTime":
      //       return moment(a?.bankingTime).isBefore(b?.bankingTime) ? -1 : 1;
      //     case "-BankingTime":
      //       return moment(a?.bankingTime).isAfter(b?.bankingTime) ? -1 : 1;
      //     case "CreatedTime":
      //       return moment(a?.createdTime).isBefore(b?.createdTime) ? -1 : 1;
      //     case "-CreatedTime":
      //       return moment(a?.createdTime).isAfter(b?.createdTime) ? -1 : 1;
      //     default:
      //       return 0;
      //   }
      // });
      const banking = data?.data || [];

      return getRows({ banking, filters });
    } catch (e) {
      return [];
    }
  }, [data, search, advancedSearch]);

  const handleSearchChange = (value) => {
    if (isArray(value)) {
      value = value[0];
    }
    setSearch(value);
    setPagination((prev) => {
      return {
        ...prev,
        pageIndex: 0,
      };
    });
  };

  const handlePaginationChange = (pageIndex, pageSize) => {
    setPagination({ pageIndex, pageSize });
  };

  const handleAdvancedSearchChange = (value) => {
    setAdvancedSearch((prev) => {
      return { ...prev, ...value };
    });
    setPagination((prev) => {
      return {
        ...prev,
        pageIndex: 0,
      };
    });
  };

  return {
    data,
    rows,
    isLoading,
    error,
    handleSearchChange,
    handleAdvancedSearchChange,
    setSortOption,
    handlePaginationChange,
    pagination,
    isHasAdvancedSearch,
    advancedSearch,
  };
};
