import { getColumns } from "./adsAccountData";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { adsAccountQueryKey, useAdsAccount } from "../../hooks/useAdsAccount";
import TableServerPaginateLayout from "../TableServerPaginateLayout";
import { Can } from "../../hooks/useCanAccess";
import { adsAccountActions, cardActions, userActions } from "../../constants/actions";
import AdsAccountAdvancedSearch from "./AdsAccountAdvancedSearch";
import { useSearchParams } from "react-router-dom";

function AdsAccount() {
  const [searchParams] = useSearchParams();

  const {
    dataPagination,
    error,
    handlePaginationChange,
    isLoading,
    pagination,
    handleSearchChange,
  } = useAdsAccount({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
  });
  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableServerPaginateLayout
      columns={columns}
      rows={dataPagination.rows}
      title="Tài Khoản Quảng Cáo"
      canCreate={
        <Can
          permissions={[
            adsAccountActions.insertAdsAccount,
            userActions.getPagingUsers,
            cardActions.getAllCards,
          ]}
          fallback={
            "Bạn không có quyền thêm tài khoản quảng cáo hoặc xem danh sách người dùng, thẻ"
          }
        >
          <UpdateForm create={true} />
        </Can>
      }
      pageCount={dataPagination.pagination.totalPages}
      manualPagination={true}
      onPaginationChange={handlePaginationChange}
      pageIndex={pagination.pageIndex}
      pageSize={pagination.pageSize}
      refreshButton={true}
      canExport={adsAccountQueryKey}
      advancedSearch={<AdsAccountAdvancedSearch onSearch={handleSearchChange} />}
      canSearch={false}
      compact={true}
    />
  );
}

export default AdsAccount;
