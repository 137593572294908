import { useAuth } from "./AuthContext";
import { useMemo } from "react";
import { useModal } from "./ModalContext";

const { createContext, useContext, useReducer, useEffect } = require("react");

const RequestContext = createContext();

const method = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
};

const keyToExclude = ["status", "title", "moneyType", "isHighlight", "shift"];

const API_URL = process.env.REACT_APP_API_URL;

// const contexts = [
//   "user",
//   "department",
//   "role",
//   "group",
//   "member",
//   "punishment",
//   "bank",
//   "banking",
//   "bankingType",
//   "card",
//   "cardCategory",
// ];
const contexts = [
  // "user",
  // "department",
  // "role",
  // "group",
  // "member",
  // "punishment",
  // "bank",
  // "banking",
  // "bankingType",
  // "card",
  // "cardCategory",
  // "action",
  // "actionGroup",
  // "notification",
  // "adsAccount",
  // "notification",
  // "request",
];
const initialState = {
  isLoading: false,
  error: null,
  success: null,
  user: [],
  role: [],
  department: [],
  group: [],
  member: [],
  punishment: [],
  bank: [],
  banking: [],
  bankingType: [],
  card: [],
  cardCategory: [],
  action: [],
  actionGroup: [],
  notification: [],
  adsAccount: [],
  request: [],
};

function fetchData(action) {
  return action.payload.data || action.payload || [];
}

function createData({ key, state, action }) {
  return [action.payload.data, ...state[key]];
}

function updateData({ key, state, action }) {
  return state[key].map((record) => {
    if (record?._id == action?.payload?.data?._id) {
      return action.payload.data;
    }
    return record;
  });
}

function isDeleteData({ key, state, action }) {
  return state[key].map((record) => {
    if (record?._id === action?.payload?.id) {
      return { ...record, isDeleted: true };
    }
    return record;
  });
}

function deleteData({ key, state, action }) {
  return state[key].filter((record) => record._id !== action.payload.id);
}

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        isLoading: true,
        error: null,
        success: null,
      };

    /**
     * User Block
     */
    case "user/fetch":
      return {
        ...state,
        isLoading: false,
        user: fetchData(action),
      };
    case "user/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        user: createData({ key: "user", state, action }),
      };
    case "user/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        user: updateData({ key: "user", state, action }),
      };

    /**
     * Role Block
     */
    case "role/fetch":
      return {
        ...state,
        isLoading: false,
        role: fetchData(action),
      };
    case "role/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        role: createData({ key: "role", state, action }),
      };
    case "role/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        role: updateData({ key: "role", state, action }),
      };
    case "role/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        role: deleteData({ key: "role", state, action }),
      };

    /**
     * Department Block
     */
    case "department/fetch":
      return {
        ...state,
        isLoading: false,
        department: fetchData(action),
      };
    case "department/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        department: createData({ key: "department", state, action }),
      };
    case "department/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        department: updateData({ key: "department", state, action }),
      };
    case "department/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        department: isDeleteData({ key: "department", state, action }),
      };

    /**
     * Group Block
     */
    case "group/fetch":
      return {
        ...state,
        isLoading: false,
        group: fetchData(action),
      };
    case "group/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        group: createData({ key: "group", state, action }),
      };
    case "group/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        group: updateData({ key: "group", state, action }),
      };
    case "group/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        group: isDeleteData({ key: "group", state, action }),
      };

    /**
     * Member Block
     */
    case "member/fetch":
      return {
        ...state,
        isLoading: false,
        member: fetchData(action),
      };
    case "member/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        member: createData({ key: "member", state, action }),
      };
    case "member/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        member: updateData({ key: "member", state, action }),
      };
    case "member/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        member: isDeleteData({ key: "member", state, action }),
      };

    /**
     * Punishment Block
     */
    case "punishment/fetch":
      return {
        ...state,
        isLoading: false,
        punishment: fetchData(action),
      };
    case "punishment/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        punishment: createData({ key: "punishment", state, action }),
      };
    case "punishment/createMany":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        punishment: createData({ key: "punishment", state, action }),
      };
    case "punishment/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        punishment: updateData({ key: "punishment", state, action }),
      };
    case "punishment/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        punishment: isDeleteData({ key: "punishment", state, action }),
      };

    /**
     * Bank Block
     */
    case "bank/fetch":
      return {
        ...state,
        isLoading: false,
        bank: fetchData(action),
      };
    case "bank/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        bank: createData({ key: "bank", state, action }),
      };
    case "bank/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        bank: updateData({ key: "bank", state, action }),
      };
    case "bank/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        bank: isDeleteData({ key: "bank", state, action }),
      };

    /**
     * Banking Block
     */
    case "banking/fetch":
      return {
        ...state,
        isLoading: false,
        banking: fetchData(action),
      };
    case "banking/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        banking: createData({ key: "banking", state, action }),
      };
    case "banking/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        banking: updateData({ key: "banking", state, action }),
      };
    case "banking/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        banking: isDeleteData({ key: "banking", state, action }),
      };
    case "banking/createMany":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        banking: createData({ key: "banking", state, action }),
      };
    // update multiple status
    case "banking/updateMultipleStatuses":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        banking: updateData({ key: "banking", state, action }),
      };

    /**
     * Banking Type Block
     */
    case "bankingType/fetch":
      return {
        ...state,
        isLoading: false,
        bankingType: fetchData(action),
      };
    case "bankingType/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        bankingType: createData({ key: "bankingType", state, action }),
      };
    case "bankingType/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        bankingType: updateData({ key: "bankingType", state, action }),
      };
    case "bankingType/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        bankingType: isDeleteData({ key: "bankingType", state, action }),
      };

    /**
     * Card Block
     */
    case "card/fetch":
      return {
        ...state,
        isLoading: false,
        card: fetchData(action),
      };
    case "card/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        card: createData({ key: "card", state, action }),
      };
    case "card/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        card: updateData({ key: "card", state, action }),
      };
    case "card/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        card: isDeleteData({ key: "card", state, action }),
      };

    /**
     * Card Category Block
     */
    case "cardCategory/fetch":
      return {
        ...state,
        isLoading: false,
        cardCategory: fetchData(action),
      };
    case "cardCategory/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        cardCategory: createData({ key: "cardCategory", state, action }),
      };
    case "cardCategory/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        cardCategory: updateData({ key: "cardCategory", state, action }),
      };
    case "cardCategory/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        cardCategory: isDeleteData({ key: "cardCategory", state, action }),
      };

    /**
     * Action Block
     */
    case "action/fetch":
      return {
        ...state,
        isLoading: false,
        action: fetchData(action),
      };
    case "action/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        action: createData({ key: "action", state, action }),
      };

    // * Notification Block
    // */
    case "notification/fetch":
      return {
        ...state,
        isLoading: false,
        notification: fetchData(action),
      };
    case "notification/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        notification: createData({ key: "notification", state, action }),
      };
    case "action/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        action: updateData({ key: "action", state, action }),
      };
    case "notification/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        notification: updateData({ key: "notification", state, action }),
      };
    case "action/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    /**
     * Action Group Block
     */

    case "actionGroup/fetch":
      return {
        ...state,
        isLoading: false,
        actionGroup: fetchData(action),
      };
    case "actionGroup/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    case "actionGroup/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    case "actionGroup/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    case "notification/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        notification: isDeleteData({ key: "notification", state, action }),
      };

    /**
     * Ads Account Block
     */
    case "adsAccount/fetch":
      return {
        ...state,
        isLoading: false,
        adsAccount: fetchData(action),
      };
    case "adsAccount/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        adsAccount: createData({ key: "adsAccount", state, action }),
      };
    case "adsAccount/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        adsAccount: updateData({ key: "adsAccount", state, action }),
      };
    case "adsAccount/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        adsAccount: isDeleteData({ key: "adsAccount", state, action }),
      };

    /**
     * Request Block
     */
    case "request/fetch":
      return {
        ...state,
        isLoading: false,
        request: fetchData(action),
      };
    case "request/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        request: createData({ key: "request", state, action }),
      };
    case "request/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
        request: updateData({ key: "request", state, action }),
      };
    case "request/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.data.message,
        request: isDeleteData({ key: "request", state, action }),
      };

    /**
     * Sim Block
     */
    case "sim/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    case "sim/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    case "sim/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    /**
     * Card Check Block
     */
    case "cardCheck/create":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    case "cardCheck/update":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    case "cardCheck/delete":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    case "cardCheck/createMany":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };

    case "rejected":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        success: null,
      };

    case "download":
      return {
        ...state,
        isLoading: false,
        success: action.payload.message,
      };
    default:
      throw new Error("Invalid action type: " + action.type);
  }
}

function RequestProvider({ children }) {
  const [
    {
      isLoading,
      error,
      success,
      user,
      role,
      department,
      group,
      member,
      punishment,
      bank,
      banking,
      bankingType,
      card,
      cardCategory,
      action,
      actionGroup,
      notification,
      adsAccount,
      request,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  try {
    const { authToken: contextAuthToken, logout } = useAuth();
    const { emitClose } = useModal();

    async function makeRequest({ method, endpoint, body }) {
      try {
        const authToken = localStorage.getItem("authToken") || contextAuthToken;
        if (authToken) {
          const requestOptions = {
            method,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
            },
            body: JSON.stringify(body),
          };

          const res = await fetch(API_URL + endpoint, requestOptions);
          if (res && res?.status === 401) {
            throw new Error("Unauthorized to access " + endpoint);
          }
          if (res) {
            if (res?.status === 401) throw new Error("Unauthorized to access " + endpoint);

            if (res?.status === 403) logout();
          }

          const data = await res.json();
          if (data) {
            if (
              String(data?.status).startsWith(4) ||
              String(data?.status).startsWith(5) ||
              data?.status === 2
            )
              throw new Error(data.message);
          }

          return data;
        } else {
          throw new Error("Token is missing !!!");
        }
      } catch (err) {
        throw new Error(err.message);
      }
    }

    function dispatchError(err) {
      dispatch({ type: "rejected", payload: err.message });
    }

    async function uploadImg(body) {
      try {
        const img = body.avatar ? body.avatar : body.icon ? body.icon : "";
        if (img) {
          const formData = new FormData();
          formData.append("file", img);

          const authToken = localStorage.getItem("authToken");
          const requestOptions = {
            method: method.post,
            headers: {
              Authorization: "Bearer " + authToken,
            },
            body: formData,
          };
          const res = await fetch(API_URL + `/upload/uploadFile/`, requestOptions);

          if (String(res.status).startsWith(2)) {
            const data = await res.json();
            return data.data;
          }

          if (String(res.status).startsWith(4)) {
            throw new Error(res.message);
          }
        } else {
          return "";
        }
      } catch (err) {
        throw new Error(err.message);
      }
    }

    async function fetchRequest(context) {
      try {
        dispatch({ type: "loading" });
        let endpoint = `/${context}/getPaging`;

        const request = { method: method.get, endpoint };
        const data = await makeRequest(request);

        dispatch({ type: `${context}/fetch`, payload: data, context: context });
      } catch (err) {
        dispatch({ type: "rejected", payload: err.message });
      }
    }

    async function createRequest({ context, body, config = {} }) {
      const { autoClose = true } = config;
      try {
        dispatch({ type: "loading" });

        switch (context) {
          case "user":
          case "member":
            const avatar = await uploadImg(body);
            if (avatar) {
              body.avatar = avatar;
            }
            break;

          case "group":
          case "department":
          case "role":
          case "cardCategory":
          case "bankingType":
            const icon = await uploadImg(body);
            if (icon) {
              body.icon = icon;
            }

          default:
            break;
        }

        for (let key in body) {
          if (!body[key] && !keyToExclude.includes(key)) {
            delete body[key];
          }
        }

        const request = {
          method: method.post,
          endpoint: `/${context}/insert`,
          body,
        };
        const data = await makeRequest(request);
        emitClose && autoClose && emitClose();

        dispatch({ type: `${context}/create`, payload: data, context });
      } catch (err) {
        dispatch({ type: "rejected", payload: err.message });
      }
    }

    async function updateRequest({ context, id, body }) {
      try {
        dispatch({ type: "loading" });
        switch (context) {
          case "user":
          case "member":
            const avatar = await uploadImg(body);
            if (avatar) {
              body.avatar = avatar;
            }
            break;

          case "group":
          case "department":
          case "role":
          case "cardCategory":
          case "bankingType":
            const icon = await uploadImg(body);
            if (icon) {
              body.icon = icon;
            }
        }

        for (let key in body) {
          if (!body[key] && !keyToExclude.includes(key)) {
            delete body[key];
          }
        }

        const request = {
          method: method.put,
          endpoint: `/${context}/update/${id}`,
          body,
        };
        const data = await makeRequest(request);
        emitClose && emitClose();

        dispatch({ type: `${context}/update`, payload: data, context });
      } catch (err) {
        dispatch({ type: "rejected", payload: err.message });
      }
    }

    async function deleteRequest({ context, id }) {
      try {
        dispatch({ type: "loading" });

        const request = {
          method: method.delete,
          endpoint: `/${context}/delete/${id}`,
        };
        const data = await makeRequest(request);
        emitClose && emitClose();

        dispatch({ type: `${context}/delete`, payload: { id, data }, context });
      } catch (err) {
        dispatch({ type: "rejected", payload: err.message });
      }
    }

    // specialcase : baning update multiple status
    async function updateMultipleStatuses({ context, body }) {
      try {
        dispatch({ type: "loading" });

        const request = {
          method: method.put,
          endpoint: `/${context}/updateMultipleStatuses`,
          body,
        };
        const data = await makeRequest(request);
        emitClose && emitClose();

        dispatch({ type: `${context}/updateMultipleStatuses`, payload: data, context });
      } catch (err) {
        dispatch({ type: "rejected", payload: err.message });
      }
    }

    useEffect(() => {
      async function fetch() {
        for (let context of contexts) {
          await fetchRequest(context);
        }
      }
      fetch();
    }, [contextAuthToken]);

    const value = useMemo(() => {
      return {
        makeRequest,
        isLoading,
        error,
        success,
        dispatchError,
        user,
        role,
        department,
        group,
        member,
        punishment,
        bank,
        banking,
        bankingType,
        card,
        cardCategory,
        action,
        actionGroup,
        dispatch,
        createRequest,
        updateRequest,
        deleteRequest,
        updateMultipleStatuses,
        notification,
        adsAccount,
        request,
      };
    }, [
      makeRequest,
      isLoading,
      error,
      success,
      dispatchError,
      user,
      role,
      department,
      group,
      member,
      punishment,
      bank,
      banking,
      bankingType,
      card,
      cardCategory,
      action,
      actionGroup,
      dispatch,
      createRequest,
      updateRequest,
      deleteRequest,
      notification,
      adsAccount,
      request,
      updateMultipleStatuses,
    ]);

    return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
  } catch (err) {
    dispatch({ type: "rejected", payload: err.message });
  }
}

function useRequest() {
  const context = useContext(RequestContext);
  if (context === undefined) throw new Error("RequestContext is used outside of RequestContext");
  return context;
}

export { RequestProvider, useRequest };
