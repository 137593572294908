import React, { useMemo, useState } from "react";
import { useRequest } from "../context/RequestContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { isEmpty } from "lodash/lang";
import { getRows } from "../layouts/action/actionData";
import { actionQueryKey } from "./useAction";
import { adsAccountQueryKey } from "./useAdsAccount";

export const adsAccountConditionQueryKey = `${adsAccountQueryKey}/adsConditions`;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const UseAdsAccountCondition = () => {
  const { makeRequest } = useRequest();

  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: [adsAccountConditionQueryKey],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${adsAccountConditionQueryKey}?`,
      };

      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    placeholderData: [],
  });

  const { mutate, isPending, mutateAsync } = useMutation({
    mutationFn: async (values) => {
      const request = {
        method: method.post,
        endpoint: `/${adsAccountConditionQueryKey}/insert`,
        body: values,
      };
      return await makeRequest(request);
    },
    onSuccess: (data) => {
      refetch();
    },
  });
  const { isPending: isDeleting, mutateAsync: handleDelete } = useMutation({
    mutationFn: async (value) => {
      const request = {
        method: method.delete,
        endpoint: `/${adsAccountConditionQueryKey}/delete/${value}`,
      };
      return await makeRequest(request);
    },
    onSuccess: (data) => {
      // debugger;
      refetch();
    },
  });

  return {
    data,
    isLoading,
    error,
    refetch,
    isFetching,
    create: mutateAsync,
    isCreating: isPending,
    handleDelete,
    isDeleting,
  };
};

export default UseAdsAccountCondition;
