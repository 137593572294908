import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import DeleteForm from "components/DeleteForm";
import CardInfo from "components/CardInfo";
import NestedList from "components/NestedList";
import { formatCurrency, separateThousands } from "utils/helpers";
import { getCurrency } from "utils/helpers";
import { deepSearch } from "../../utils/deep-search";
import { DisplayContent, getRemovedHtmlTag } from "../../libs/display-content";
import { bankActions, bankingActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import UpdateStatusModal from "./UpdateStatusModal";
import moment from "moment";
import Chip from "@mui/material/Chip";
import MDTypography from "../../components/MDTypography";
import { isEmptyHtml } from "../../utils/parse";
import { isObject } from "lodash";
import Highlighter from "components/Highlighter";
import ModalPreviewContent from "../../components/ModalPreviewContent";
import QuickUpdateModal from "./QuickUpdateModal";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

function filterList(list, searchTerm) {
  return list.filter((item) => deepSearch(item, searchTerm));
}

export const displayShortContent = (content) => {
  if (!content) return;
  const removedHtmlTag = getRemovedHtmlTag(content);
  const shortContent =
    removedHtmlTag?.length > 20 ? removedHtmlTag?.slice(0, 20) + "..." : removedHtmlTag;
  return shortContent;
};

function getRows({ banking, filters }) {
  const {
    searchValue,
    fromCardName,
    toCardName,
    amountOfMoneyRange,
    toCardNumber,
    fromCardNumber,
    bankingTime,
    members,
    bankingType,
    company,
    duplicateFilter,
    status,
  } = filters;
  try {
    const [start, end] = bankingTime || [null, null];
    const [min, max] = amountOfMoneyRange || [null, null];
    // let rows = banking.filter((item) => {
    //   const { fromCard, toCard, amountOfMoney } = item;
    //
    //   // Check for search value
    //   if (!deepSearch(item, searchValue)) return false;
    //   // check for status , status may be 0,1,2,null,undefined
    //   if (status !== null && status !== undefined && item.status !== status) return false;
    //   // Check for fromCardName
    //   if (
    //     fromCardName &&
    //     (!fromCard || !fromCard.account.toLowerCase().includes(fromCardName.toLowerCase()))
    //   ) {
    //     return false;
    //   }
    //
    //   // Check for toCardName
    //   if (
    //     toCardName &&
    //     (!toCard || !toCard.account.toLowerCase().includes(toCardName.toLowerCase()))
    //   )
    //     return false;
    //
    //   // Check for amountOfMoneyRange
    //   if (min !== null && max !== null && (amountOfMoney < min || amountOfMoney > max))
    //     return false;
    //
    //   // Check for fromCardNumber
    //   if (
    //     fromCardNumber &&
    //     (!fromCard ||
    //       !fromCard?.accountNumber?.toLowerCase().includes(fromCardNumber?.toLowerCase()))
    //   ) {
    //     return false;
    //   }
    //
    //   // Check for toCardNumber
    //   if (
    //     toCardNumber &&
    //     (!toCard || !toCard?.accountNumber?.toLowerCase().includes(toCardNumber.toLowerCase()))
    //   )
    //     return false;
    //
    //   // Check for bankingTime
    //   if (bankingTime && !moment(item.bankingTime).isBetween(start, end, "day", "[]")) return false;
    //
    //   // Check for members
    //   if (members && !item.members.some((member) => members.includes(member._id))) return false;
    //
    //   // Check for bankingType
    //   if (bankingType && item.bankingType._id !== bankingType) return false;
    //
    //   // Check for company
    //   if (company && (!item.company || !item.company.toLowerCase().includes(company.toLowerCase())))
    //     return false;
    //
    //   return true;
    // });

    // // Check for duplicateFilter
    // if (duplicateFilter?.length > 0) {
    //   const seen = new Map();
    //   const duplicates = new Set();
    //
    //   rows.forEach((item) => {
    //     let key = "";
    //     duplicateFilter.forEach((field) => {
    //       if (field === "bankingTime") {
    //         key += moment(item.bankingTime).format("YYYY-MM-DD HH:mm");
    //       } else {
    //         key += `${
    //           isObject(item[field]) ? item[field]._id : item[field]?.toString()?.replace(/\s/g, "")
    //         }`;
    //       }
    //     });
    //     if (seen.has(key)) {
    //       duplicates.add(key);
    //     }
    //     seen.set(key, true);
    //   });
    //
    //   rows = rows.filter((item) => {
    //     let key = "";
    //     duplicateFilter.forEach((field) => {
    //       if (field === "bankingTime") {
    //         key += moment(item.bankingTime).format("YYYY-MM-DD HH:mm");
    //       } else {
    //         key += `${
    //           isObject(item[field]) ? item[field]?._id : item[field]?.toString()?.replace(/\s/g, "")
    //         }`;
    //       }
    //     });
    //     return duplicates.has(key);
    //   });
    // }
    // .filter((item, index, self) => {
    //   // Check for duplicateFilter
    //   if (duplicateFilter) {
    //     // return same date like 2021-09-01
    //
    //     if (duplicateFilter === "bankingTime") {
    //       return (
    //         self.findIndex(
    //           (t) =>
    //             moment(t.bankingTime).format("YYYY-MM-DD") ===
    //             moment(item.bankingTime).format("YYYY-MM-DD")
    //         ) === index
    //       );
    //     }
    //     // filter by fromCard
    //     if (duplicateFilter === "fromCard") {
    //       debugger;
    //       console.log(self.findIndex((t) => t.fromCard._id === item.fromCard._id));
    //       return self.findIndex((t) => t.fromCard._id === item.fromCard._id) === index;
    //     }
    //     // filter by toCard
    //     if (duplicateFilter === "toCard") {
    //       return self.findIndex((t) => t.toCard._id === item.toCard._id) === index;
    //     }
    //     // filter by amountOfMoney
    //     if (duplicateFilter === "amountOfMoney") {
    //       return self.findIndex((t) => t.amountOfMoney === item.amountOfMoney) === index;
    //     }
    //   } else {
    //     return true;
    //   }
    // })
    let rows = banking.map((banking) => {
      // Component rendering logic remains the same
      const {
        fromCard,
        toCard,
        bankingType,
        bankingTime,
        content,
        amountOfMoney,
        moneyType,
        isDeleted,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
        status,
        isHighlight,
      } = banking;

      const currency = getCurrency(moneyType);

      if (isHighlight) {
        return {
          fromCard: (
            <QuickUpdateModal data={banking} field="fromCard">
              <CardInfo isHighlighted card={fromCard} highlightSearch={[searchValue]} />
            </QuickUpdateModal>
          ),
          toCard: (
            <QuickUpdateModal data={banking} field="toCard">
              <CardInfo isHighlighted card={toCard} highlightSearch={[searchValue]} />
            </QuickUpdateModal>
          ),
          amountOfMoney: (
            <QuickUpdateModal data={banking} field="amountOfMoney">
              <MDTypography component="span" variant="caption" color="warning" fontWeight="medium">
                <Highlighter
                  searchWords={[searchValue]}
                  autoEscape={true}
                  textToHighlight={amountOfMoney ? separateThousands(amountOfMoney) : ""}
                />
              </MDTypography>
            </QuickUpdateModal>
          ),
          moneyType: (
            <MDTypography component="span" variant="caption" color="warning" fontWeight="medium">
              {currency}
            </MDTypography>
          ),
          bankingType: bankingType ? (
            <QuickUpdateModal data={banking} field="bankingType">
              <StyledNameComponent
                isHighlighted
                img={ASSET_URL + bankingType?.icon}
                name={bankingType?.name}
                highlightSearch={[searchValue]}
                showHighlightWords
              />
            </QuickUpdateModal>
          ) : null,
          bankingTime: bankingTime ? (
            <QuickUpdateModal data={banking} field="bankingTime">
              <Time time={bankingTime} color="warning" />
            </QuickUpdateModal>
          ) : null,
          content: <ModalPreviewContent content={content} />,

          isDeleted: (
            <MDBadge
              badgeContent={String(isDeleted)}
              color={isDeleted ? "error" : "success"}
              variant="gradient"
              size="sm"
            />
          ),
          members: (
            <QuickUpdateModal data={banking} field="members">
              <MDBox>
                {banking?.members?.map((member) => (
                  <Chip
                    key={member._id}
                    label={
                      <Highlighter
                        searchWords={[searchValue]}
                        autoEscape={true}
                        textToHighlight={member?.name || ""}
                      />
                    }
                    sx={{
                      color: "warning.main",
                      m: "5px",
                      textTransform: "uppercase",
                    }}
                  />
                ))}
              </MDBox>
            </QuickUpdateModal>
          ),
          company: (
            <QuickUpdateModal data={banking} field="company">
              <MDTypography
                component="span"
                variant="caption"
                color="warning"
                fontWeight="medium"
                sx={{
                  maxWidth: "120px",
                  display: "block",
                }}
              >
                <Highlighter
                  searchWords={[searchValue]}
                  autoEscape={true}
                  textToHighlight={banking?.company || ""}
                />
              </MDTypography>
            </QuickUpdateModal>
          ),
          createdTime: createdTime ? <Time time={createdTime} color="warning" /> : null,
          createdBy: createdBy ? (
            <StyledNameComponent
              isHighlighted
              img={ASSET_URL + createdBy.avatar}
              name={createdBy.fullName}
            />
          ) : null,
          updatedTime: updatedTime ? <Time color="warning" time={updatedTime} /> : null,
          updatedBy: updatedBy ? (
            <StyledNameComponent
              isHighlighted
              img={ASSET_URL + updatedBy.avatar}
              name={updatedBy.fullName}
            />
          ) : null,
          action: (
            <MDBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}
            >
              <Can permissions={bankingActions.updateBanking}>
                <Modal>
                  <UpdateForm banking={banking} />
                </Modal>
              </Can>
              <Can permissions={bankingActions.deleteBanking}>
                <Modal action="delete">
                  <DeleteForm context="banking" id={banking._id} />
                </Modal>
              </Can>
            </MDBox>
          ),
          status: <UpdateStatusModal item={banking} />,
          originalData: banking,
          id: banking._id,
        };
      }

      return {
        fromCard: (
          <QuickUpdateModal data={banking} field="fromCard">
            <CardInfo card={fromCard} highlightSearch={[searchValue]} />{" "}
          </QuickUpdateModal>
        ),

        toCard: (
          <QuickUpdateModal data={banking} field="toCard">
            <CardInfo card={toCard} highlightSearch={[searchValue]} />
          </QuickUpdateModal>
        ),
        amountOfMoney: (
          <QuickUpdateModal data={banking} field="amountOfMoney">
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              <Highlighter
                searchWords={[searchValue]}
                autoEscape={true}
                textToHighlight={amountOfMoney ? separateThousands(amountOfMoney) : ""}
              />
            </MDTypography>
          </QuickUpdateModal>
        ),
        moneyType: <span>{currency}</span>,
        bankingType: bankingType ? (
          <QuickUpdateModal data={banking} field="bankingType">
            <StyledNameComponent
              img={ASSET_URL + bankingType?.icon}
              name={bankingType?.name}
              highlightSearch={[searchValue]}
              showHighlightWords
            />
          </QuickUpdateModal>
        ) : null,
        bankingTime: bankingTime ? (
          <QuickUpdateModal data={banking} field="bankingTime">
            <Time time={bankingTime} />
          </QuickUpdateModal>
        ) : null,
        // content: !isEmptyHtml(content) && (
        //   <NestedList
        //     sx={{
        //       width: 230,
        //       bgcolor: "background.card",
        //       borderRadius: "10px",
        //       border: "0.5px solid",
        //     }}
        //     title={content ? displayShortContent(content) : "Nội Dung"}
        //   >
        //     <div className="display-content">
        //       {DisplayContent({
        //         content,
        //       })}
        //     </div>
        //   </NestedList>
        // ),
        content: <ModalPreviewContent content={content} />,
        isDeleted: (
          <MDBadge
            badgeContent={String(isDeleted)}
            color={isDeleted ? "error" : "success"}
            variant="gradient"
            size="sm"
          />
        ),
        members: (
          <QuickUpdateModal data={banking} field="members">
            <MDBox>
              {banking?.members?.map((member) => {
                return (
                  <Chip
                    key={member._id}
                    label={
                      <Highlighter
                        searchWords={[searchValue]}
                        autoEscape={true}
                        textToHighlight={member?.name || ""}
                      />
                    }
                    sx={{
                      color: "text.main",
                      m: "5px",
                      textTransform: "uppercase",
                    }}
                  />
                );
              })}
            </MDBox>
          </QuickUpdateModal>
        ),
        company: (
          <QuickUpdateModal data={banking} field="company">
            <MDTypography
              component="span"
              variant="caption"
              fontWeight="medium"
              sx={{
                maxWidth: "120px",
                display: "block",
              }}
            >
              <Highlighter
                searchWords={[searchValue]}
                autoEscape={true}
                textToHighlight={banking?.company || ""}
              />
            </MDTypography>
          </QuickUpdateModal>
        ),
        createdTime: createdTime ? <Time time={createdTime} /> : null,
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={createdBy.fullName} />
        ) : null,
        updatedTime: updatedTime ? <Time time={updatedTime} /> : null,
        updatedBy: updatedBy ? (
          <StyledNameComponent img={ASSET_URL + updatedBy.avatar} name={updatedBy.fullName} />
        ) : null,
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={bankingActions.updateBanking}>
              <Modal>
                <UpdateForm banking={banking} />
              </Modal>
            </Can>
            <Can permissions={bankingActions.deleteBanking}>
              <Modal action="delete">
                <DeleteForm context="banking" id={banking._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
        status: <UpdateStatusModal item={banking} />,
        originalData: banking,
        id: banking._id,
      };
    });

    return rows;
  } catch (err) {
    console.log("err", err);
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "thời gian giao dịch", accessor: "bankingTime", align: "center", fixed: "left" },
    { Header: "hậu đài", accessor: "company", align: "center" },
    { Header: "trạng thái nhận", accessor: "status", align: "center" },
    { Header: "thẻ chuyển", accessor: "fromCard", align: "left" },
    { Header: "thẻ nhận", accessor: "toCard", align: "left" },
    { Header: "khoản chi tiêu", accessor: "amountOfMoney", align: "center" },
    { Header: "đơn vị tiền tệ", accessor: "moneyType", align: "center" },
    { Header: "kiểu giao dịch", accessor: "bankingType", align: "left" },
    { Header: "thành viên", accessor: "members", align: "center" },
    { Header: "nội dung", accessor: "content", align: "left" },
    { Header: "trạng thái xoá", accessor: "isDeleted", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "thời gian tạo", accessor: "createdTime", align: "center" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },
    { Header: "cập nhật bởi", accessor: "updatedBy", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
