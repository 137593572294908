import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/card-category/cardCategoriesData";
import useCanAccess from "./useCanAccess";
import { cardCategoryActions } from "../constants/actions";
export const cardCategoryQueryKey = "cardCategory";

export const useCardCategory = ({ isDeleted = false } = {}) => {
  const { makeRequest } = useRequest();
  const [searchValue, setSearchValue] = useState("");
  const { can } = useCanAccess();

  const { data, isLoading, error } = useQuery({
    queryKey: [cardCategoryQueryKey, { isDeleted }],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${cardCategoryQueryKey}/getAll?isDeleted=${isDeleted}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled: can(cardCategoryActions.getAllCardCategories),
    placeholderData: (previousData, previousQuery) => previousData,
  });
  const rows = useMemo(() => {
    try {
      const filters = {
        name: searchValue,
      };
      const cardCategory = data;

      return getRows({ cardCategory, filters });
    } catch (e) {
      return [];
    }
  }, [data, searchValue]);
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  return {
    data,
    rows,
    isLoading,
    error,
    handleSearchChange,
  };
};
