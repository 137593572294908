import React from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../../libs/fetchRequestUtils";
import { departmentQueryKey } from "../../hooks/useDepartments";
import { useRequest } from "../../context/RequestContext";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import useFuse from "../../hooks/useFuse";

const GroupSearch = ({ onChange, groups }) => {
  const { makeRequest } = useRequest();
  const { data, isLoading, error } = useQuery({
    queryKey: [departmentQueryKey],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${departmentQueryKey}/getAll`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    placeholderData: (previousData, previousQuery) => previousData || [],
  });

  const options = {
    keys: ["name", "departments.name"],
    includeMatches: true,
  };
  const { result, search, reset } = useFuse({
    data: groups || [],
    options,
  });
  const handleChange = (value, type) => {
    value = value?._id || value;
    if (value) {
      onChange && onChange([value], type);
    } else {
      onChange && onChange([], type);
    }
  };

  function getName(value) {
    const result = data.find((record) => record._id === value);
    return result?.name || result?.username || "Data";
  }

  if (isLoading) return "Loading...";

  if (error) return null;

  return (
    <>
      <Autocomplete
        disablePortal
        options={data}
        sx={{ width: 200, flexShrink: 0, transform: "translateY(2px)" }}
        getOptionLabel={(option) => option?.name}
        onChange={(e, newValue) => {
          handleChange(newValue, "department");
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder="Bộ Phận"
            size="small"
            fullWidth
            sx={{ paddingTop: "8px", paddingBottom: "6px", transform: "translateY(-2px)" }}
          />
        )}
      />
      <Autocomplete
        disablePortal
        options={groups || []}
        sx={{ width: 200, flexShrink: 0, transform: "translateY(2px)" }}
        getOptionLabel={(option) => `${option?.name}`}
        filterOptions={(options) => {
          return result;
        }}
        onChange={(e, newValue) => {
          handleChange(newValue, "group");
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder="Tìm kiếm(Tên tổ, Tên Bộ phận)"
            size="small"
            fullWidth
            sx={{ paddingTop: "8px", paddingBottom: "6px", transform: "translateY(-2px)" }}
            onBlur={() => {
              reset();
            }}
            onChange={(e) => {
              search(e.target.value);
            }}
          />
        )}
      />
    </>
  );
};

export default GroupSearch;
