import ReactHighlighter from "react-highlight-words";
import { removeVietnameseTones } from "../utils/helpers";

const Highlighter = ({ ...props }) => {
  return (
    <ReactHighlighter
      {...props}
      searchWords={props.searchWords}
      autoEscape={true}
      textToHighlight={props.textToHighlight}
      sanitize={(text) => {
        // replace , character
        if (!text) return "";
        text = text?.replace(/,/g, "");
        // remove vietnamese character
        text = removeVietnameseTones(text);
        return text;
      }}
    />
  );
};

export default Highlighter;
