import { useRequest } from "context/RequestContext";
import React, { useState } from "react";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import FormSelect from "components/FormSelect";
import { useQueryClient } from "@tanstack/react-query";
import TextAreaField from "../../components/TextAreaField";
import { useGroup } from "../../hooks/useGroup";
import { useDepartment } from "../../hooks/useDepartments";
import DatePicker from "../../components/DatePicker";
import { listSimType } from "../../constants/listSimType";
import { simQueryKey } from "../../hooks/useSim";
import { NumericFormat } from "react-number-format";
import Autocomplete from "@mui/material/Autocomplete";
import QuillInputFiled from "../../components/QuillInputField/QuillInputFiled";
import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";

const inputStyle = {
  marginTop: "20px",
};

const context = "sim";

function UpdateForm({ sim, create = false }) {
  const [name, setName] = useState(sim?.name || "");
  const [simType, setSimType] = useState(sim?.simType || 0);
  const [phoneNumber, setPhoneNumber] = useState(sim?.phoneNumber || "");
  const [isHighlight, setIsHighlight] = useState(sim?.isHighlight || false);
  const [isLoading, setIsLoading] = useState(false);

  const [departments, setDepartments] = useState(() => {
    return sim?.departments || [];
  });
  const [groups, setGroups] = useState(() => {
    return sim?.groups || [];
  });
  const [expiryDate, setExpiryDate] = useState(() => {
    return sim?.expiryDate ? new Date(sim?.expiryDate) : new Date();
  });
  const [note, setNote] = useState(sim?.note || "");
  const [balance, setBalance] = useState(sim?.balance || "");

  const { updateRequest, createRequest } = useRequest();

  const { data: groupsData, isLoading: isLoadingGroups } = useGroup();
  const { data: departmentsData, isLoading: isLoadingDepartments } = useDepartment();
  const queryClient = useQueryClient();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  function handleChangeContent(e) {
    setNote(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const body = {
        name,
        groups,
        departments,
        note,
        simType,
        phoneNumber,
        expiryDate,
        balance,
        isHighlight,
      };
      // flat map
      body.departments = body.departments.map((item) => item._id);
      body.groups = body.groups.map((item) => item._id);
      if (!create) {
        await updateRequest({ context, id: sim._id, body });
        await queryClient.invalidateQueries({
          queryKey: [simQueryKey],
          type: "active",
        });
      } else {
        await createRequest({ context, body });
        await queryClient.invalidateQueries({
          queryKey: [simQueryKey],
          type: "active",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoadingGroups || isLoadingDepartments) return <div>Loading...</div>;

  return (
    <MDBox
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxHeight: "90vh",
        overflowY: "auto",
      }}
    >
      <MDInput
        fullWidth
        id="name"
        label="Tên"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={inputStyle}
        required
      />
      <FormSelect
        title="Loại Sim"
        data={listSimType || []}
        dataSelected={simType}
        onChange={(e) => {
          setSimType(e.target.value);
        }}
        required
      />
      <MDInput
        fullWidth
        id="phoneNumber"
        label="Số điện thoại"
        variant="outlined"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        sx={inputStyle}
        required
      />
      <DatePicker
        label="Ngày Hết Hạn"
        selected={expiryDate}
        onChange={(date) => {
          setExpiryDate(date?.toISOString());
        }}
      />
      <NumericFormat
        fullWidth
        id="balance"
        label="Số Dư"
        variant="outlined"
        value={balance}
        thousandSeparator
        customInput={MDInput}
        sx={inputStyle}
        onChange={(e) => {
          const value = e.target.value.replaceAll(",", "");

          setBalance(value);
        }}
        required={true}
      />
      <Autocomplete
        multiple
        disablePortal
        options={departmentsData || []}
        defaultValue={departments}
        getOptionLabel={(option) => option?.name}
        onChange={(e, newValue) => {
          setDepartments(newValue);
        }}
        renderInput={(params) => (
          <MDInput {...params} placeholder="Bộ Phận" fullWidth sx={inputStyle} />
        )}
      />
      <Autocomplete
        multiple
        disablePortal
        options={groupsData || []}
        defaultValue={groups}
        getOptionLabel={(option) => option?.name}
        onChange={(e, newValue) => {
          setGroups(newValue);
        }}
        renderInput={(params) => <MDInput {...params} placeholder="Tổ" fullWidth sx={inputStyle} />}
      />
      <FormControlLabel
        sx={{
          ...inputStyle,
          marginLeft: 2,
        }}
        control={
          <Switch
            checked={isHighlight}
            value={isHighlight}
            // onChange={(e) => {
            //   debugger;
            //   setIsHighlight(!isHighlight);
            // }}
            onClick={(e) => {
              setIsHighlight(!isHighlight);
            }}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label="Giao dịch nổi bật"
      />
      {/*<MultipleSelectChip*/}
      {/*  title="Bộ Phận"*/}
      {/*  data={departmentsData || []}*/}
      {/*  dataSelected={departments}*/}
      {/*  onChange={(value) => {*/}
      {/*    setDepartments(value);*/}
      {/*  }}*/}
      {/*  required*/}
      {/*/>*/}
      {/*<MultipleSelectChip*/}
      {/*  title="Tổ"*/}
      {/*  data={groupsData || []}*/}
      {/*  dataSelected={groups}*/}
      {/*  onChange={(value) => {*/}
      {/*    setGroups(value);*/}
      {/*  }}*/}
      {/*  required*/}
      {/*/>*/}

      {/*<TextAreaField*/}
      {/*  minRows={5}*/}
      {/*  placeholder="Ghi chú"*/}
      {/*  value={note}*/}
      {/*  onChange={handleChangeContent}*/}
      {/*  required*/}
      {/*/>*/}
      <QuillInputFiled label="Ghi chú" initialValue={note} onChange={(value) => setNote(value)} />

      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
