import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import DeleteForm from "components/DeleteForm";
import MDTypography from "components/MDTypography";
import NestedList from "components/NestedList";
import { bankActions, requestActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import ModalPreviewContent from "../../components/ModalPreviewContent";

const ASSET_URL = process.env.REACT_APP_ASSET_URL;

const STATUS = [
  {
    content: "pending",
    color: "warning",
  },
  {
    content: "approved",
    color: "success",
  },
  {
    content: "rejected",
    color: "error",
  },
];

function getRows({ request }) {
  try {
    const rows = request.map((request) => {
      const {
        user,
        title,
        content,
        status,
        isDeleted,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
      } = request || [];

      return {
        user: <StyledNameComponent img={ASSET_URL + user.avatar} name={user.fullName} />,
        title: <MDTypography variant="span">{title}</MDTypography>,
        content: <ModalPreviewContent content={content} />,
        status: (
          <MDBadge
            badgeContent={String(STATUS[status].content)}
            color={STATUS[status].color}
            variant="gradient"
            size="sm"
          />
        ),
        isDeleted: (
          <MDBadge
            badgeContent={String(isDeleted)}
            color={isDeleted ? "error" : "success"}
            variant="gradient"
            size="sm"
          />
        ),
        createdTime: createdTime ? <Time time={createdTime} /> : null,
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={createdBy.fullName} />
        ) : null,
        updatedTime: updatedTime ? <Time time={updatedTime} /> : null,
        updatedBy: updatedBy ? (
          <StyledNameComponent img={ASSET_URL + updatedBy.avatar} name={updatedBy.fullName} />
        ) : null,
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={requestActions.updateRequest}>
              <Modal>
                <UpdateForm request={request} />
              </Modal>
            </Can>
            <Can permissions={requestActions.deleteRequest}>
              <Modal action="delete">
                <DeleteForm context="request" id={request._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "người dùng", accessor: "user", align: "left" },
    { Header: "tiêu đề", accessor: "title", align: "left" },
    { Header: "nội dung", accessor: "content", align: "center" },
    { Header: "trạng thái", accessor: "status", align: "center" },
    { Header: "trạng thái xoá", accessor: "isDeleted", align: "center" },
    { Header: "tạo lúc", accessor: "createdTime", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },
    { Header: "cập nhật bởi", accessor: "updatedBy", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
