import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/action/actionData";
import { isEmpty } from "lodash/lang";

export const actionQueryKey = "action";

export const useAction = ({ enabled = true } = {}) => {
  const { makeRequest } = useRequest();
  const [searchValue, setSearchValue] = useState("");

  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: [actionQueryKey],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${actionQueryKey}/getAll?`,
      };

      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled,
    placeholderData: [],
  });

  const rows = useMemo(() => {
    if (!data || isEmpty(data)) return [];
    const rows = data?.filter((row) => {
      const { name, viName } = row;
      const lowerSearchValue = searchValue.toLowerCase();

      if (!searchValue) return true;
      return (
        name.toLowerCase().includes(lowerSearchValue) ||
        viName.toLowerCase().includes(lowerSearchValue)
      );
    });
    return getRows({ action: rows });
  }, [data, searchValue]);
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  return {
    rows,
    data,
    isLoading,
    error,
    handleSearchChange,
    refetch,
    isFetching,
  };
};
