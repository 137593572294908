import React, { useState } from "react";
import { useRequest } from "../../context/RequestContext";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";
import { useMember } from "../../hooks/useMember";
import RangeDatePicker from "../../components/RangeDatePicker";
import { MenuItem, Select } from "@mui/material";

const UserSelect = ({ onChange }) => {
  const { makeRequest } = useRequest();
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, error } = useMember();
  const [dateRange, setDateRange] = useState([null, null]);
  const [sortOption, setSortOption] = useState("createdTime");

  // const { data, isLoading, error } = useQuery({
  //   queryKey: [userQueryKey],
  //   queryFn: async () => {
  //     const query = {
  //       pageIndex: 1,
  //       pageSize: 9999,
  //     };
  //     const request = {
  //       method: method.get,
  //       endpoint: `/${userQueryKey}/getPaging?${new URLSearchParams(query).toString()}`,
  //     };
  //     return await makeRequest(request);
  //   },
  //   select: (data) => {
  //     const userList = data?.data;
  //     // change username to label
  //     userList.forEach((user) => {
  //       user.label = user.username;
  //     });
  //
  //     return userList;
  //   },
  //   enabled: open,
  //   placeholderData: () => {
  //     return {
  //       data: [],
  //     };
  //   },
  // });
  const handleChange = (event, newValue) => {
    const value = newValue?._id || "";

    onChange && onChange(value, "member");
  };

  // if (isLoading) return "Loading...";

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <>
      <Select
        sx={{ padding: "7px 0" }}
        value={sortOption}
        renderValue={() => "Sắp xếp"}
        onChange={(event) => {
          const value = event?.target?.value;
          setSortOption(value);
          onChange && onChange(value, "sortBy");
        }}
      >
        <MenuItem value="count">Bị phạt nhiều nhất</MenuItem>
        <MenuItem value="-count">Bị phạt ít nhất</MenuItem>
        <MenuItem value="-createdTime">Thời gian tạo tăng dần </MenuItem>
        <MenuItem value="createdTime">Thời gian tạo giảm dần </MenuItem>{" "}
        <MenuItem value="-punishedTime">Thời gian phạt tăng dần </MenuItem>
        <MenuItem value="punishedTime">Thời gian phạt giảm dần </MenuItem>
      </Select>
      <Autocomplete
        disablePortal
        options={data}
        sx={{ width: 500, padding: "0px" }}
        onOpen={() => {
          setOpen(true);
        }}
        onChange={handleChange}
        getOptionLabel={(option) => option?.name}
        loading={isLoading}
        renderInput={(params) => {
          return (
            <MDInput
              {...params}
              placeholder="Thành viên"
              size="small"
              fullWidth
              sx={{ padding: "1px" }}
            />
          );
        }}
      />
      <RangeDatePicker
        selected={dateRange}
        label="Ngày"
        onChange={(dates) => {
          setDateRange(dates);
          onChange && onChange(dates, "date");
        }}
      />
    </>
  );
};

export default UserSelect;
