import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Highlighter from "./Highlighter";

function StyledNameComponent({
  img,
  name,
  isHighlighted = false,
  highlightSearch = [],
  showHighlightWords = false,
  danger = false,
}) {
  return (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {img && <MDAvatar src={img} name={name} size="sm" />}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography
          display="block"
          variant="button"
          fontWeight="medium"
          sx={{ textTransform: "uppercase" }}
          color={danger ? "error" : isHighlighted ? "warning" : "text"}
        >
          {showHighlightWords ? (
            <Highlighter searchWords={highlightSearch} autoEscape={true} textToHighlight={name} />
          ) : (
            name
          )}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default StyledNameComponent;
