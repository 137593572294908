import { useState } from "react";
import { useRequest } from "../../../context/RequestContext";
import { useQuery } from "@tanstack/react-query";
import { method } from "../../../libs/fetchRequestUtils";
import { isString, omit } from "lodash";
import { exportCSVFile } from "../../../libs/export-csv";
import Spinner from "../../../components/Spinner/Spinner";
import MDButton from "../../../components/MDButton";
import Icon from "@mui/material/Icon";
import { isEmpty } from "lodash/lang";
import {
  convertGmtOffsetToTime,
  getKeysFromObjArray,
  separateThousands,
} from "../../../utils/helpers";
import { USER_STATUS } from "../../../layouts/users/userData";
import { listSimType } from "../../../constants/listSimType";
import moment from "moment";
import { CardCheckShift } from "../../../constants/cardCheckShift";
import { getTextFromHtmlTag } from "../../../libs/display-content";

export function ExportModal({ context }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const { makeRequest, dispatch } = useRequest();

  const {
    data: { data, fileTitle, headers } = {},
    isLoading: isLoadingQuery,
    isFetching: isFetchingQuery,
    error,
  } = useQuery({
    queryKey: [context, "export"],
    queryFn: async () => {
      // if (context === "user" || context === "punishment") {
      //   let request = {
      //     method: method.get,
      //     endpoint: `/${context}/getAll`,
      //   };
      //
      //   return await makeRequest(request);
      // }
      // special case for user
      let request = {
        method: method.get,
        endpoint: `/${context}/getAll`,
      };
      if (context === "user") {
        request = {
          ...request,
          endpoint: `/user/getPaging?pageIndex=1&pageSize=1000&sortBy=createdTime:desc`,
        };
      }
      return await makeRequest(request);
    },
    select: (data) => {
      data = data?.data ? data.data : data;
      if (isEmpty(data))
        return {
          data: [],
          headers: {},
          fileTitle: "",
        };
      // omit some fields
      data = data.map((item) => {
        const rest = omit(item, ["_id", "createdTime", "createdBy", "isDeleted"]);
        // format date with moment
        Object.keys(rest).forEach((key) => {
          if (key.includes("Time")) {
            rest[key] = moment(rest[key]).format("DD/MM/YYYY HH:mm");
          }
        });
        if (item?.content) {
          const content = getTextFromHtmlTag(item?.content, {
            ALLOWED_TAGS: [],
            KEEP_TEXT_AND_BREAK: true,
          });
          return {
            ...rest,
            content,
          };
        }
        if (item?.note) {
          const note = getTextFromHtmlTag(item?.note, {
            ALLOWED_TAGS: [],
            KEEP_TEXT_AND_BREAK: true,
          });
          return {
            ...rest,
            note,
          };
        }

        return rest;
      });
      // make headers
      switch (context) {
        case "user":
          data = data?.map((item) => {
            const departments = item.departments.map((department) => department.name).join(", ");
            const role = item?.role?.name;
            const status = USER_STATUS[item?.status]?.content || "Unknown";
            return {
              ...item,
              status,
              departments,
              role,
            };
          });
          break;
        case "member":
          data = data?.map((item) => {
            const group = item?.group?.name;
            const title = item?.title ? "Manager" : "Member";
            return {
              name: item?.name,
              title,
              group,
            };
          });
          break;
        case "punishment":
          data = data?.map((item) => {
            const member = item?.member?.name;
            return {
              ...item,
              member,
            };
          });
          break;
        case "card":
          data = data?.map((item) => {
            const bank = item?.bank?.name;
            const cardCategories = item?.cardCategories
              ?.map((category) => category?.name)
              .join(", ");
            const members = item?.members?.map((member) => member?.name).join(", ");

            return {
              ...item,
              bank,
              members,
              cardCategories,
            };
          });
          break;
        case "group":
          data = data?.map((item) => {
            const departments = item?.departments?.map((department) => department?.name).join(", ");
            return {
              ...item,
              departments,
            };
          });
          break;
        case "bankingType":
          data = data?.map((item) => {
            const departments = item?.departments?.map((department) => department?.name).join(", ");
            const groups = item?.groups?.map((group) => group.name).join(", ");
            return {
              ...item,
              groups,
              departments,
            };
          });
          break;
        case "adsAccount":
          data = data?.map((item) => {
            // cards
            const cards = item?.cards
              ?.map((card) => {
                const list = [];
                if (card.account) {
                  list.push(card.account);
                }
                if (card.accountNumber) {
                  list.push(card.accountNumber);
                }
                if (list.bank) {
                  list.push(card.bank.name);
                }
                return list.join("-");
              })
              .join(", ");
            const condition = item?.condition?.title;
            const country = convertGmtOffsetToTime(item?.country?.timeRegion || 0);
            const members = item?.members?.map((member) => member?.name).join(", ");
            const group = item?.group?.name;
            const { status, ...rest } = item;
            return {
              ...rest,
              cards,
              condition,
              country,
              members,
              group,
            };
          });
          break;
        case "sim":
          data = data?.map((item) => {
            const groups = item?.groups?.map((group) => group?.name).join(", ");
            const departments = item?.departments?.map((department) => department?.name).join(", ");
            const simType = listSimType.find((sim) => sim._id === item?.simType);
            const expiryDate = item?.expiryDate
              ? moment(item?.expiryDate).format("DD/MM/YYYY HH:mm")
              : "";
            const balance = separateThousands(item?.balance);
            return {
              ...item,
              groups,
              departments,
              simType: simType?.name,
              expiryDate,
              balance,
            };
          });
          break;
        case "cardCheck":
          data = data?.map((item) => {
            const groups = item?.groups?.map((group) => group?.name).join(", ");
            const departments = item?.departments?.map((department) => department?.name).join(", ");
            const checkingTime = item?.checkingTime
              ? moment(item?.checkingTime).format("DD/MM/YYYY HH:mm")
              : "";
            const card = [];
            if (item?.card) {
              if (item?.card.account) {
                card.push(item?.card.account);
              }
              if (item?.card.accountNumber) {
                card.push(item?.card.accountNumber);
              }
              if (item?.card.bank) {
                card.push(item?.card.bank.name);
              }
            }
            const availableBalance = separateThousands(item?.availableBalance);
            const actualBalance = separateThousands(item?.actualBalance);
            const shift = item?.shift === CardCheckShift.startShift ? "Đầu ca" : "Cuối ca";
            return {
              ...item,
              groups,
              departments,
              card: card?.join("-"),
              checkingTime,
              availableBalance,
              actualBalance,
              shift,
            };
          });
          break;
        default:
          break;
      }

      const listKeys = getKeysFromObjArray(data, ["updatedBy", "updatedTime"]);

      const headers = listKeys.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: cur,
        };
      }, {});

      const exportData = data.map((item) => {
        return listKeys.reduce((acc, cur) => {
          const value = item[cur] ? item[cur] : "";
          return {
            ...acc,
            [cur]: value,
          };
        }, {});
      });

      // make title with timestamp
      const fileTitle = `${context}-${new Date().getTime()}`;
      return {
        data: exportData,
        headers,
        fileTitle,
      };
    },
    enabled: context && isString(context) && enabled,
    placeholderData: () => {
      return {
        data: [],
        headers: {},
        fileTitle: "",
      };
    },
  });

  const handleDownload = () => {
    setIsDownloading(true);
    exportCSVFile(headers, data, fileTitle, () => {
      dispatch({
        type: "download",
        payload: {
          message: `Download ${context} successfully!`,
        },
      });
      setIsDownloading(false);
    });
  };

  if (!context || error) return null;

  if (isLoadingQuery) {
    return <Spinner />;
  }

  return (
    <MDButton
      onClick={handleDownload}
      sx={{
        flexShrink: 0,
      }}
      disabled={isFetchingQuery}
      onMouseOver={() => {
        setEnabled(true);
      }}
      size="small"
    >
      {isDownloading ? <Spinner /> : <Icon>import_export</Icon>}
      Export {context}
    </MDButton>
  );
}
