import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import Modal from "components/Modal";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import Chip from "@mui/material/Chip";
import { Can } from "../../hooks/useCanAccess";
import { userActions } from "../../constants/actions";

export const USER_STATUS = [
  {
    content: "deleted",
    color: "error",
  },
  {
    content: "active",
    color: "success",
  },
  {
    content: "locked",
    color: "warning",
  },
];

const FullName = ({ fullName }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {fullName}
    </MDTypography>
  </MDBox>
);

function getRows({ user }) {
  try {
    let rows = [];
    if (Array.isArray(user) && user.length > 0) {
      rows = user.map((user) => {
        const { avatar, username, fullName, status, role, departments } = user;
        return {
          user: (
            <StyledNameComponent img={process.env.REACT_APP_ASSET_URL + avatar} name={username} />
          ),
          fullName: <FullName fullName={fullName || "Name"} />,
          status: (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent={USER_STATUS[status].content}
                color={USER_STATUS[status].color}
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          role: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {role?.name ? role?.name.toUpperCase() : ""}
            </MDTypography>
          ),
          departments: (
            <MDBox sx={{ width: 300, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {departments.map((department, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={department.name}
                  sx={{ color: "text.main", m: "5px" }}
                />
              ))}
            </MDBox>
          ),
          action: (
            <Can permissions={userActions.updateUser}>
              <Modal>
                <UpdateForm user={user} />
              </Modal>
            </Can>
          ),
        };
      });
    }
    return rows;
  } catch (err) {
    console.log("err", err);
  }
}

function getColumns() {
  return [
    { Header: "người dùng", accessor: "user", width: "20%", align: "left" },
    { Header: "tên đầy đủ", accessor: "fullName", align: "left" },
    { Header: "trạng thái", accessor: "status", align: "center" },
    { Header: "vai trò", accessor: "role", align: "center" },
    { Header: "bộ phận", accessor: "departments", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
