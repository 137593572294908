import React from "react";
import { useRequest } from "../../context/RequestContext";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import useFuse from "../../hooks/useFuse";

const BankSearch = ({ onChange, banks }) => {
  const { makeRequest } = useRequest();

  const options = {
    keys: ["name"],
    includeMatches: true,
  };
  const { result, search, reset } = useFuse({
    data: banks || [],
    options,
  });
  const handleChange = (value) => {
    value = value?._id || value;
    if (value) {
      onChange && onChange(value);
    } else {
      onChange && onChange("");
    }
  };

  function getName(value) {
    const result = data.find((record) => record._id === value);
    return result?.name || result?.username || "Data";
  }

  return (
    <Autocomplete
      disablePortal
      options={banks || []}
      sx={{ width: 200, flexShrink: 0, transform: "translateY(2px)" }}
      getOptionLabel={(option) => `${option?.name}`}
      filterOptions={(options) => {
        return result;
      }}
      onChange={(e, newValue) => {
        handleChange(newValue);
      }}
      renderInput={(params) => (
        <MDInput
          {...params}
          placeholder="Tìm kiếm(Tên tổ, Tên Bộ phận)"
          size="small"
          fullWidth
          sx={{ paddingTop: "8px", paddingBottom: "6px", transform: "translateY(-2px)" }}
          onBlur={() => {
            reset();
          }}
          onChange={(e) => {
            search(e.target.value);
          }}
        />
      )}
    />
  );
};

export default BankSearch;
