import { useRequest } from "../context/RequestContext";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { getRows } from "../layouts/banking-type/bankingTypesData";
import useCanAccess from "./useCanAccess";
import { bankingTypeActions } from "../constants/actions";
import { DEFAULT_SORT } from "../utils/constants";
export const bankingTypeQueryKey = "bankingType";

export const useBakingType = ({ isDeleted = false, sort = DEFAULT_SORT } = {}) => {
  const { makeRequest } = useRequest();
  const [searchValue, setSearchValue] = useState("");
  const { can } = useCanAccess();

  const { data, isLoading, error } = useQuery({
    queryKey: [bankingTypeQueryKey, { isDeleted, sort }],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${bankingTypeQueryKey}/getAll?isDeleted=${isDeleted}&sort=${sort}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },
    enabled: can(bankingTypeActions.getAllBankingTypes),
    placeholderData: (previousData, previousQuery) => previousData,
  });
  const rows = useMemo(() => {
    try {
      const filters = {
        name: searchValue,
      };
      const bankingType = data;

      return getRows({ bankingType, filters });
    } catch (e) {
      return [];
    }
  }, [data, searchValue]);
  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  return {
    data,
    rows,
    isLoading,
    error,
    handleSearchChange,
  };
};
