import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { useRequest } from "context/RequestContext";
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import { useQueryClient } from "@tanstack/react-query";

import { useAction } from "../../hooks/useAction";
import { actionGroupQueryKey } from "../../hooks/useActionGroup";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

const context = "actionGroup";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  const list = b.filter((item) => {
    const id = item?._id;
    return a.indexOf(id) !== -1;
  });
  return list;
}

function UpdateForm({ actionGroup, create = false }) {
  const [name, setName] = useState(actionGroup?.name || "");
  const [viName, setViName] = useState(actionGroup?.viName || "");
  const { data: listAction } = useAction();

  const { createRequest, updateRequest } = useRequest();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const queryClient = useQueryClient();

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(listAction || []);
  const [right, setRight] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userActions = actionGroup?.actions || [];
    const actions = listAction?.filter((item) => {
      const id = item?._id;
      return userActions.indexOf(id) !== -1;
    });

    setRight(actions);
    setLeft(not(listAction, actions));
  }, [listAction, actionGroup]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const actions = right.map((item) => item?._id);
      const body = { name, viName, actions };
      if (!create) {
        await updateRequest({ context, id: actionGroup._id, body });
        queryClient.invalidateQueries({
          queryKey: [actionGroupQueryKey],
        });
      } else {
        await createRequest({ context, body });
        queryClient.invalidateQueries({
          queryKey: [actionGroupQueryKey],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const customList = (items) => (
    <Card sx={{ width: 240, height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((item) => {
          const labelId = `transfer-list-item-${item?._id}-label`;

          return (
            <ListItem key={item?._id} role="listitem" button onClick={handleToggle(item?._id)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(item?._id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
                id={labelId}
                primary={
                  <Typography variant="body2" style={{ color: "#FFFFFF" }}>
                    {item?.name}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" style={{ color: "#FFFFFF", fontSize: 12 }}>
                    {item?.viName}
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <MDBox
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: 600,
      }}
    >
      <MDInput
        fullWidth
        id="name"
        label="Tên"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <MDInput
        fullWidth
        id="viName"
        label="Tên Tiếng Việt"
        variant="outlined"
        value={viName}
        onChange={(e) => setViName(e.target.value)}
        sx={{
          marginTop: "20px",
        }}
        required
      />
      {!isLoading && (
        <FormControlLabel
          label="Danh Sách Hành Động"
          control={
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{
                marginTop: "20px",
              }}
            >
              <Grid item>{customList(left)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <MDButton
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </MDButton>
                  <MDButton
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </MDButton>
                  <MDButton
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </MDButton>
                  <MDButton
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </MDButton>
                </Grid>
              </Grid>
              <Grid item>{customList(right)}</Grid>
            </Grid>
          }
        />
      )}

      {/*<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>*/}
      {/*    {Array.from(Array(6)).map((_, index) => (*/}
      {/*        <Grid item xs={2} sm={4} md={4} key={index}>*/}
      {/*            <Item>xs=2</Item>*/}
      {/*        </Grid>*/}
      {/*    ))}*/}
      {/*</Grid>*/}

      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
