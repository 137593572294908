import MDBox from "components/MDBox";
import Modal from "components/Modal";
import Time from "components/Time";
import StyledNameComponent from "components/StyledNameComponent";
import UpdateForm from "./UpdateForm";
import MDBadge from "components/MDBadge";
import DeleteForm from "components/DeleteForm";
import { cardCheckActions } from "../../constants/actions";
import { Can } from "../../hooks/useCanAccess";
import { deepSearch } from "../../utils/deep-search";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import CardInfo from "../../components/CardInfo";
import { separateThousands } from "../../utils/helpers";

import { CardCheckShift } from "../../constants/cardCheckShift";
import moment from "moment";
import ModalPreviewContent from "../../components/ModalPreviewContent";
const ASSET_URL = process.env.REACT_APP_ASSET_URL;

function getRows({ cards, filters = {} }) {
  try {
    let rows = cards.filter((card) => {
      // Initialize a flag indicating whether the card should be included
      let includeCard = true;

      // Filter by name
      if (filters.searchValue) {
        includeCard = includeCard && deepSearch(card, filters.searchValue);
      }

      // Filter by shift, but only if the shift filter is not set to '2' (which I assume means 'all shifts')
      if (includeCard && filters.shift !== undefined && filters.shift !== 2) {
        includeCard = includeCard && card.shift === filters.shift;
      }

      // Filter by date range
      if (includeCard && filters.dateRange && filters.dateRange[0] && filters.dateRange[1]) {
        includeCard =
          includeCard &&
          moment(card.checkingTime).isBetween(
            filters.dateRange[0],
            filters.dateRange[1],
            "day",
            "[]"
          );
      }

      // Filter by group
      if (includeCard && filters?.groups?.length > 0) {
        filters?.groups?.map((group) => {
          includeCard = includeCard && card?.groups?.map((g) => g?._id).includes(group);
        });
      }
      // Filter by department
      if (includeCard && filters?.departments?.length > 0) {
        filters?.departments?.map((department) => {
          includeCard = includeCard && card?.departments?.map((d) => d?._id).includes(department);
        });
      }

      return includeCard;
    });
    rows = rows.map((cardCheck) => {
      const {
        name,
        card,
        departments,
        isDeleted,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
        availableBalance,
        actualBalance,
        checkingTime,
        groups,
        note,
        shift,
      } = cardCheck;

      return {
        departments: (
          <MDBox sx={{ width: 130, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {departments.map((department, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={department.name}
                sx={{ color: "text.main", m: "5px" }}
              />
            ))}
          </MDBox>
        ),
        groups: (
          <MDBox sx={{ width: 100, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {groups.map((group, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={group.name}
                sx={{ color: "text.main", m: "5px" }}
              />
            ))}
          </MDBox>
        ),
        availableBalance: (
          <Typography variant="caption" color="text" fontWeight="medium">
            {separateThousands(availableBalance)}
          </Typography>
        ),
        actualBalance: (
          <Typography variant="caption" color="text" fontWeight="medium">
            {separateThousands(actualBalance)}
          </Typography>
        ),
        checkingTime: checkingTime ? <Time time={checkingTime} /> : null,
        note: <ModalPreviewContent content={note} />,

        name: (
          <Typography variant="caption" color="text" fontWeight="medium">
            {name}
          </Typography>
        ),
        card: <CardInfo card={card} />,
        shift: (
          <MDBadge
            badgeContent={shift === CardCheckShift.startShift ? "Đầu ca" : "Cuối ca"}
            color={shift ? "success" : "secondary"}
            variant="gradient"
            size="sm"
          />
        ),
        isDeleted: (
          <MDBadge
            badgeContent={String(isDeleted)}
            color={isDeleted ? "error" : "success"}
            variant="gradient"
            size="sm"
          />
        ),
        createdTime: createdTime ? <Time time={createdTime} /> : null,
        createdBy: createdBy ? (
          <StyledNameComponent img={ASSET_URL + createdBy.avatar} name={createdBy.fullName} />
        ) : null,
        updatedTime: updatedTime ? <Time time={updatedTime} /> : null,
        updatedBy: updatedBy ? (
          <StyledNameComponent img={ASSET_URL + updatedBy.avatar} name={updatedBy.fullName} />
        ) : null,
        action: (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <Can permissions={cardCheckActions.updateCardCheck}>
              <Modal>
                <UpdateForm cardCheck={cardCheck} />
              </Modal>
            </Can>
            <Can permissions={cardCheckActions.deleteCardCheck}>
              <Modal action="delete">
                <DeleteForm context="cardCheck" id={cardCheck._id} />
              </Modal>
            </Can>
          </MDBox>
        ),
      };
    });

    return rows;
  } catch (err) {
    throw new Error(err);
  }
}

function getColumns() {
  return [
    { Header: "thời gian", accessor: "checkingTime", align: "center" },
    { Header: "ca", accessor: "shift", align: "center" },

    { Header: "Tên thẻ", accessor: "name", align: "left" },
    { Header: "Thẻ", accessor: "card", align: "left" },
    { Header: "số dư khả dụng trong thẻ", accessor: "availableBalance", align: "left" },
    { Header: "số dư thực tế trong thẻ", accessor: "actualBalance", align: "center" },
    { Header: "ghi chú", accessor: "note", align: "center" },
    { Header: "tổ", accessor: "groups", align: "left" },
    { Header: "bộ phận", accessor: "departments", align: "left" },
    // { Header: "tạo lúc", accessor: "createdTime", align: "center" },
    { Header: "tạo bởi", accessor: "createdBy", align: "center" },
    { Header: "cập nhật lúc", accessor: "updatedTime", align: "center" },
    { Header: "cập nhật bởi", accessor: "updatedBy", align: "center" },
    { Header: "thao tác", accessor: "action", align: "center" },
  ];
}

export { getColumns, getRows };
