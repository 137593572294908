import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const primaryTypographyProps = {
  fontSize: "size.sm",
};

const secondaryTypographyProps = {
  color: "inherit",
  fontSize: "size.sm",
};

const defaultListStyle = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.card",
  borderRadius: "10px",
  border: "0.5px solid",
};

export default function NestedList({
  title,
  children,
  onClick = false,
  sx = { ...defaultListStyle },
  buttonStyle = {
    p: 1,
  },
  listItemButtonSx = {},
  copyText = null,
}) {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(copyText);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Hide tooltip after 2 seconds
  };
  const handleClick = () => {
    setOpen(!open);
    onClick && onClick();
  };

  return (
    <List sx={sx} component="nav" aria-labelledby="nested-list-subheader">
      <div
        style={{
          display: "flex",
        }}
      >
        <ListItemButton onClick={handleClick} sx={buttonStyle}>
          <ListItemText primary={title} primaryTypographyProps={primaryTypographyProps} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {copyText && (
          <Tooltip title={copied ? "Đã sao chép" : "Sao chép"} placement="top-start">
            <IconButton sx={{ color: "text.main" }} onClick={handleCopy}>
              <Icon sx={{ alignSelf: "center" }}>content_copy</Icon>
            </IconButton>
          </Tooltip>
        )}
      </div>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ p: 1, ...listItemButtonSx }}>
            <ListItemText primary={children} primaryTypographyProps={secondaryTypographyProps} />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}
