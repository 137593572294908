import { getColumns } from "./cardCheckData";
import SpinnerFullPage from "components/SpinnerFullPage/SpinnerFullPage";
import UpdateForm from "./UpdateForm";
import { Can } from "../../hooks/useCanAccess";
import { cardCheckActions } from "../../constants/actions";
import TableLayout from "../TableLayout";
import { cardCheckQueryKey, useCardCheck } from "../../hooks/useCardCheck";
import ImportModal from "../../components/ImportModal";
import exampleFile from "./card-check-example.csv";
import CardCheckAdvanceSearch from "./CardCheckAdvanceSearch";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_SORT } from "../../utils/constants";

const CardCheckTranslate = {
  name: "Tên thẻ",
  accountNumber: "Số tài khoản",
  availableBalance: "Số dư khả dụng",
  actualBalance: "Số dư thực tế",
  checkingTime: "Thời gian kiểm",
  note: "Ghi chú",
};

function CardCheckTable() {
  const [searchParams] = useSearchParams();

  const { rows, handleSearchChange, isLoading, handleAdvancedSearchChange } = useCardCheck({
    isDeleted: searchParams.get("isDeletedRecords") === "true",
    sort: searchParams.get("sort") || DEFAULT_SORT,
  });
  const columns = getColumns();

  if (isLoading) return <SpinnerFullPage />;

  return (
    <TableLayout
      columns={columns}
      rows={rows}
      title="KIỂM TIỀN ĐẦU CA, CUỐI CA"
      onSearch={handleSearchChange}
      importModal={
        <ImportModal
          exampleFile={exampleFile}
          queryKey={cardCheckQueryKey}
          bodyPayload="cardChecks"
          translate={CardCheckTranslate}
        />
      }
      advancedSearch={<CardCheckAdvanceSearch onSearch={handleAdvancedSearchChange} />}
      canCreate={
        <Can
          permissions={cardCheckActions.insertCardCheck}
          fallback={"Bạn không có quyền thêm kiểm tiền đầu ca, cuối ca"}
        >
          <UpdateForm create={true} />
        </Can>
      }
      canSearch={true}
      canExport={cardCheckQueryKey}
      refreshButton={true}
      compact={true}
    />
  );
}

export default CardCheckTable;
